import React, { useEffect } from "react";
import styled from "styled-components";
import { bahasa, english } from "./text";
import Spinner from "../../../components/ui-components/spinner";
// import { useKeyTrendsData } from "../../../api/user-api/dashboard-api";
import { useSocialList, useFetchPosts } from "../../../api/user-api";
import { AuthButton } from "../../user-login/components/login";
import Toaster from "../../../components/ui-components/toaster";

const Wrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  /* background-color: var(--white_900); */
`;

const Line = styled.div`
  width: 100%;
  border-top: 1px solid var(--gray_100);
`;
const CardWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  .child-card {
  }

  .key-trend {
    width: 100%;
  }
  .key-stats {
    width: 100%;
  }
`;
const FlexCol = styled.div`
  width: 48%;
  display: flex;
  // justify-content: space-between;
  flex-direction: column;
  gap: 0.7rem;
  .keyTrends-subheading {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: -0.02em;
    margin: 1rem 0;
  }
  .the-text-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.8rem;
    border-radius: 8px;
    padding: 1rem;
    background-color: var(--white_900);
    box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.11);
    .the-circle {
      min-width: 8px;
      min-height: 8px;
      border-radius: 5rem;
      background-color: var(--primary-500);
    }
    .orange {
      background-color: var(--orange-600);
    }
    .the-text {
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 15px;
      letter-spacing: -0.02em;
    }
  }
  .more-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .more-btn {
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 0.8rem;
    border: 1px solid var(--blue_800);
    background-color: var(--white_900);
    font-size: 1.4rem;
  }
`;
const Cards = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 1.2rem;
  box-shadow: 0px 4px 9px 0px rgba(221, 224, 227, 1);
  padding: 1.5rem;
  background-color: var(--white_900);
  margin-bottom: 20px;
  .card-heading {
    color: var(--blue_800);
    font-size: 1.8rem;
    font-weight: 600;
    letter-spacing: -0.0175rem;
    margin: 0 0 1.5rem 0;
  }
  .refreshImg {
    width: 17px;
    display: inline-block;
    margin-left: 10px;
    vertical-align: top;
    margin-top: 1px;
    transform: rotate(0deg);
    cursor: pointer;
    transition-duration: 400ms;
    &:hover {
      transform: rotate(180deg);
    }
  }
  .sub-title {
    font-size: 1.2rem;
    line-height: 1.5rem;
    letter-spacing: 0em;
    color: var(--blue_800);
    margin: 0 0 0.8rem 0;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 698px) {
    flex-direction: column;
  }
  .card-subs {
    padding: 2rem 0 0 0;
  }
  .v-line {
    width: 1px;
    height: 100%;
    background-color: var(--gray_100);
  }
`;

const Content = styled.div`
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: -0.02em;
  margin: 1rem 0;
`;
interface Iprops {
  isEnglish: boolean;
}
const TopHeader = styled.div`
  width: 100%;
  display: flex;
  gap: 2rem;
  justify-content: flex-end;
  align-items: center;
`;
const UploadButton = styled(AuthButton)`
  width: max(12.7rem, 170px);
  height: max(4.7rem, 47px);
  margin: 20px 0px;
`;
function SocialDetailsComponent({ isEnglish }: Iprops) {
  const [splitChallenges, setSplitChallenges] = React.useState(4000);
  const [splitOpportunities, setSplitOpportunities] = React.useState(4000);
  const [showToast, setShowToast] = React.useState({
    status: false,
    type: "",
    message: "",
    reason: "",
  });
  const [btnLoading, setBtnLoading] = React.useState(false);
  const [fetchTrigger, setFetchTrigger] = React.useState(false);
  React.useEffect(() => {
    setFetchTrigger(true);
    setTimeout(() => {
      setFetchTrigger(false);
    }, 500);
  }, []);

  const { heading, challengesText, fetchBtnText, fetching, opportunityText } =
    isEnglish ? english : bahasa;
  const { data, isLoading } = useSocialList(isEnglish, fetchTrigger);

  const onSuccess = (res: any) => {
    setBtnLoading(false);
    setFetchTrigger(true);
    setTimeout(() => {
      setFetchTrigger(false);
    }, 500);
  };
  const onError = (res: any) => {
    setShowToast({
      status: true,
      type: "error",
      message: "",
      reason: res?.response?.data?.message,
    });
    setBtnLoading(false);
  };
  const handleFetch = async () => {
    setBtnLoading(true);
    await fetchpostsCall();
  };
  const { mutate: fetchpostsCall } = useFetchPosts(onSuccess, onError);
  return (
    <Wrapper>
      <Toaster
        showToast={showToast.status}
        severity={showToast.type}
        summary={showToast.message}
        detail={showToast.reason}
      />
      <TopHeader>
        <UploadButton onClick={handleFetch}>
          {btnLoading ? fetching : fetchBtnText}
        </UploadButton>
      </TopHeader>
      {isLoading && <Spinner />}
      {data &&
        data.length &&
        data.map((item: any) => (
          <Cards key={item.id} className="key-trend">
            <h1 className="card-heading">Post ID - {item.post_id}</h1>
            <Content>{item.content}</Content>
            <Line />
            <FlexWrapper>
              <FlexCol>
                {data && (
                  <h2 className="keyTrends-subheading">{challengesText}</h2>
                )}
                {!isLoading && item ? (
                  item?.challengesArr?.length ? (
                    item?.challengesArr
                      ?.slice(0, splitChallenges)
                      .map((challenge: any) => (
                        <div className="the-text-box" key={challenge}>
                          <span className="the-circle"></span>
                          <p className="the-text">{challenge}</p>
                        </div>
                      ))
                  ) : (
                    "No data to show"
                  )
                ) : isLoading ? (
                  <Spinner />
                ) : null}
                {/* {item?.challengesArr?.length >= 5 && splitChallenges <= 5 ? (
                  <div className="more-wrapper" key={"more-btn"}>
                    {" "}
                    <span
                      className="more-btn"
                      onClick={() => setSplitChallenges(8)}
                    >
                      {moreText}
                    </span>
                  </div>
                ) : null} */}
              </FlexCol>
              <div className="v-line"></div>
              <FlexCol>
                {data && (
                  <h2 className="keyTrends-subheading">{opportunityText}</h2>
                )}
                {!isLoading && item ? (
                  item?.opportunitiesArr?.length ? (
                    item?.opportunitiesArr
                      ?.slice(0, splitOpportunities)
                      .map((opportunity: any) => (
                        <div className="the-text-box" key={opportunity}>
                          <span className="the-circle orange"></span>
                          <p className="the-text">{opportunity}</p>
                        </div>
                      ))
                  ) : (
                    "No data to show"
                  )
                ) : isLoading ? (
                  <Spinner />
                ) : null}
                {/* {item?.opportunitiesArr?.length >= 5 && splitOpportunities <= 5 ? (
                  <div className="more-wrapper" key={"more-btn"}>
                    {" "}
                    <span
                      className="more-btn"
                      onClick={() => setSplitOpportunities(8)}
                    >
                      {moreText}
                    </span>
                  </div>
                ) : null} */}
              </FlexCol>
            </FlexWrapper>
          </Cards>
        ))}
    </Wrapper>
  );
}

export default SocialDetailsComponent;
