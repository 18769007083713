import React from "react";
import { ProgressSpinner } from "primereact/progressspinner";

interface Iprops {
  width?: string;
  height?: string;
  animationSecs?: string;
  fill?: string;
  strokeWidth?: string;
}
function Spinner({ width, height, animationSecs, fill, strokeWidth }: Iprops) {
  return (
    <ProgressSpinner
      style={{ width: width || "5rem", height: height || "5rem" }}
      strokeWidth={strokeWidth ? strokeWidth : "4"}
      fill={fill ? fill : "none"}
      animationDuration={animationSecs ? animationSecs : "1s"}
    />
  );
}

export default Spinner;
