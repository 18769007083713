import styled from "styled-components";
import { Route, Routes } from "react-router-dom";
import EditProfile from "./components/edit-profile";
import SettingsHome from "./components/settings-home";
import ChangePassword from "./components/change-password";
import { SETTINGS_SUB_PATHS } from "../../all-routes/paths";

const SettingsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--white_900);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.04);
  border-radius: 1.5rem;
  padding: 3.5rem;
`;

function Settings() {
  return (
    <SettingsWrapper>
      <Routes>
        <Route path={SETTINGS_SUB_PATHS.SETTINGS_HOME} element={<SettingsHome />} />
        <Route path={SETTINGS_SUB_PATHS.EDIT_PROFILE} element={<EditProfile />} />
        <Route path={SETTINGS_SUB_PATHS.CHANGE_PASSWORD} element={<ChangePassword />} />
      </Routes>
    </SettingsWrapper>
  );
}

export default Settings;
