const myStorage = () => {
  const store = localStorage;
  const getValue = (value: any) => {
    let val = "";
    try {
      val = typeof value === "string" ? value : JSON.stringify(value);
    } catch (error) {
      val = "not able to stringify";
    }
    return val;
  };
  const set = (key: any, value: any) => {
    store.setItem(key, getValue(value));
  };
  const get = (key: any) => {
    return store.getItem(key);
  };
  const remove = (key: any) => {
    store.removeItem(key);
  };
  const clearAll = () => {
    store.clearAll();
  };
  return {
    set,
    get,
    remove,
    clearAll,
  };
};
export default myStorage;
