import styled from "styled-components";
import {
  AuthInput,
  InputWrapper,
} from "../../../pages/user-login/components/login";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import { ButtonOutlined } from "../../common-styles";
import IconsBox from "../iconbox";

const Wrapper = styled(InputWrapper)`
  max-width: 67.8rem;
  margin: 2.4rem 0 0 0;
  label {
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 15px;
    margin: 0 0 0.8rem 0;
  }
  .parent-div {
    position: relative;
    width: 100%;
    .error-msg {
      position: absolute;
      font-size: 1rem !important;
      bottom: -20px !important;
      left: 0;
    }
  }
`;

const InputBox = styled(AuthInput)`
  width: 100%;
  margin: 0 0 0 0;
`;
const AddedItems = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin: 2rem 0;
`;
const AddedItemBtn = styled(ButtonOutlined)`
  width: fit-content;
  height: fit-content;
  border: none;
  box-shadow: none;
  background-color: var(--gray_050);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.4rem;
  gap: 0.4rem;
  svg {
    width: 1.8rem;
  }
`;

interface IProps {
  isLabel: string;
  handleSetKeyword: (arg0: string) => void;
  isError: null | string;
  isPlaceholder?: string;
}
const ChipInput = ({
  isLabel,
  handleSetKeyword,
  isError,
  isPlaceholder,
}: IProps) => {
  const [words, setWords] = React.useState<any[]>([]);
  const [inputValue, setInputValue] = React.useState("");

  React.useEffect(() => {
    handleTransferData(words);
  }, [words]);

  // Add keyword to array
  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (inputValue.trim() !== "") {
        const newWord = {
          id: uuidv4(),
          value: inputValue.trim(),
        };
        setWords((prevWords) => [...prevWords, newWord]);
        setInputValue("");
      }
    }
  };

  // Remove keyword from array
  const handleRemoveCheck = (id: any) => {
    setWords((prevState) => {
      const filteredWords = prevState.filter((option) => option.id != id);
      return filteredWords;
    });
  };

  // pass value array to parent component
  function handleTransferData(keywordsArray: any) {
    const filterByName = keywordsArray.map((words: any) => words?.value);
    handleSetKeyword(filterByName);
  }

  return (
    <Wrapper>
      <label htmlFor="my-chip-input">{isLabel}</label>
      <div className="parent-div">
        <InputBox
          type="text"
          value={inputValue}
          onChange={(event) => setInputValue(event.target.value)}
          onKeyDown={handleKeyDown}
          placeholder={isPlaceholder}
        />
        {isError && <span className="error-text-css error-msg">{isError}</span>}
      </div>
      <AddedItems>
        {words.map((option) => (
          <AddedItemBtn
            key={option.id}
            onClick={() => handleRemoveCheck(option.id)}
          >
            {option.value}
            <IconsBox name="close-icon" fill="var(--blue_600)" />
          </AddedItemBtn>
        ))}
      </AddedItems>
    </Wrapper>
  );
};

export default ChipInput;
