import React from "react";
import styled from "styled-components";
import IconsBox from "../../../../../components/ui-components/iconbox";
import { bahasa, english } from "../../text";
import { AuthButton, AuthInput } from "../../../../user-login/components/login";
import { UserContext } from "../../../../../store/user-context";
import LoadingDots from "../../../../../components/ui-components/spinner/three-dots";
import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 1.5rem;
  background-color: var(--white_900);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin: 0 0;
  box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.04);
  padding: 2rem 1.5rem;
  .heading {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.7rem;
    letter-spacing: -0.02em;
    text-align: left;
    color: var(--blue_800);
  }
  .close-chat {
    cursor: pointer;
    transition: all 0.3s;
    :hover {
      transform: scale(1.2);
    }
  }
`;
const ContentWrapper = styled.div`
  width: 100%;
  min-height: 40rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 0 0.5rem;
  margin: 0.5rem 0;
  @keyframes bounce {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
`;
const InlineBox = styled.span`
  display: flex;
  height: 40rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  .bot-image {
    max-width: 25px;
    max-height: 25px;
  }
  .start-conv {
    opacity: 0.6;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.7rem;
    letter-spacing: -0.02em;
  }
`;

const MessagesWrapper = styled.div`
  width: 100%;
  max-height: 40rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  .the-scroll {
    padding: 4rem 0;
  }
`;
const MessageBox = styled.div<{ sender: any }>`
  max-width: 80%;
  display: flex;
  flex-direction: ${({ sender }) =>
    sender === "user" ? "row" : "row-reverse"};
  align-items: flex-start;
  justify-content: center;
  align-self: ${({ sender }) =>
    sender === "user" ? "flex-end" : "flex-start"};
  gap: 1rem;
  .the-image {
    max-width: 20px;
    max-height: 20px;
    border-radius: 5rem;
  }
`;
const Message = styled.div<{ sender: any }>`
  padding: 0.5rem 1rem;
  border-radius: 0.4rem;
  background-color: ${({ sender }) =>
    sender === "user"
      ? "var(--blue-500)"
      : sender === "bot"
      ? "var(--green-300)"
      : "transparent"};
  color: ${({ sender }) => (sender === "user" ? "#fff" : "#333")};
  .chat-text {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.7rem;
    letter-spacing: -0.02em;
  }
`;

const SearchWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: 0 0 1.5rem 0;
  box-shadow: 0px -4px 7px 0px rgba(0, 0, 0, 0.04);
  padding: 1.5rem;
`;
const Input = styled(AuthInput)`
  width: 100%;
  margin: 0 0 0 0;
  padding: 0 6rem 0 1rem;
  outline: none;
`;
const Button = styled(AuthButton)`
  position: absolute;
  right: 2rem;
  width: 40px;
  height: 40px;
`;

// none style
interface IProps {
  isEnglish: boolean;
  isChatLoading: boolean;
  onChat: any;
  apiData: any;
}

const ChatRoom = ({ isEnglish, isChatLoading, onChat, apiData }: IProps) => {
  const { chatBotHeading, inputPhText, startConvText } = isEnglish
    ? english
    : bahasa;
  const { userData } = React.useContext(UserContext);

  const bottomScrollRef = React.useRef<any>();
  const [chatArray, setChatArray] = React.useState<any>([]);
  const [isChatText, setIsChatText] = React.useState("");

  React.useEffect(() => {
    if (apiData) {
      setChatArray((prevState: any) => [...prevState, apiData]);
      bottomScrollRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
    return () => {};
  }, [apiData]);

  const handleChatValue = () => {
    if (!isChatText.trim()) return;
    setChatArray((prevState: any) => [
      ...prevState,
      { message: isChatText, user: "user" },
    ]);
    onChat(isChatText);
    setIsChatText("");
    bottomScrollRef?.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleClearChat = () => {
    setChatArray(() => []);
  };

  return (
    <Wrapper className="child-components">
      <Header>
        <h1 className="heading">{chatBotHeading}</h1>
        {chatArray?.length ? (
          <h1 className="close-chat" onClick={handleClearChat}>
            <IconsBox name="close-icon" />
          </h1>
        ) : null}
      </Header>

      <ContentWrapper>
        {chatArray?.length ? (
          <MessagesWrapper>
            {chatArray?.map((msg: any, index: any) => (
              <MessageBox key={index} sender={msg.user}>
                <Message sender={msg.user}>
                  <h2 className="chat-text">
                    <ReactMarkdown
                      className="words"
                      remarkPlugins={[remarkBreaks]}
                    >
                      {msg?.message?.replace(/\n/gi, "\n &nbsp;")}
                    </ReactMarkdown>
                  </h2>
                </Message>
                <img
                  className="the-image"
                  src={
                    msg.user === "bot"
                      ? "/images/chat-bot.png"
                      : userData?.user_image || "/images/test-user.jpg"
                  }
                  alt="user"
                />
              </MessageBox>
            ))}
            {isChatLoading ? (
              <MessageBox key={"loading"} sender={"bot"}>
                <Message sender={"bot"}>
                  <LoadingDots />
                </Message>
                <img
                  className="the-image"
                  src={"/images/chat-bot.png"}
                  alt="user"
                />
              </MessageBox>
            ) : null}
            <div className="the-scroll" ref={bottomScrollRef}></div>
          </MessagesWrapper>
        ) : (
          <InlineBox>
            <img src="/images/chat-bot.png" alt="bot" />
            <h2 className="start-conv">{startConvText}</h2>
          </InlineBox>
        )}
      </ContentWrapper>

      <SearchWrapper>
        <Input
          type="text"
          value={isChatText}
          placeholder={inputPhText}
          onKeyUp={(event: any) => {
            if (event.key === "Enter") {
              handleChatValue();
            }
          }}
          onChange={(event: any) => {
            setIsChatText(event.target.value);
          }}
        />
        <Button onClick={handleChatValue}>
          <IconsBox name="sent" />
        </Button>
      </SearchWrapper>
    </Wrapper>
  );
};

export default ChatRoom;
