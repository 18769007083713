import React from "react";
import Select from "react-select";
import countryCodes from "country-codes-list";
import styled from "styled-components";
interface CountryCodeOption {
  label: string;
  value: any;
}

const Wrapper = styled.div<{isError?: any}>`
  width: min(86px, 100%);
  height: min(46px, 100%);
  .r-select {
    box-shadow: ${(props) =>
    props?.isError != null
      ? `0 0 0 1px var(--red_600)`
      : `none`};
    border-radius: 1rem;
    min-width: 100%;
    > div {
      min-height: 46px;
      border-radius: 1rem;
      padding: 0;
    }
    div {
      font-family: var(--font_family);
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: -0.02em;
      ::-webkit-scrollbar {
        -ms-overflow-style: none;
        scrollbar-width: none;
        display: none;
      }
    }
  }
`;

// none styles
interface IProps {
  onChange: any;
  isCode?: any;
  isError?: any;
}

function CountrySelector({ onChange, isCode, isError }: IProps) {
  const [isValue, setIsValue] = React.useState<CountryCodeOption>({
    label: "Code",
    value: "",
  });

  React.useEffect(() => {
    if (isCode) {
      setIsValue({
        label: isCode,
        value: isCode,
      });
    }
    return () => {};
  }, [isCode]);

  const countryCodesOptions = React.useMemo(() => {
    return countryCodes.all().map((country) => ({
      label: `+${country.countryCallingCode}`,
      value: country.countryCallingCode,
    }));
  }, []);

  const changeHandler = (value: any) => {
    setIsValue(value);
    onChange(value);
  };

  return (
    <Wrapper isError={isError}>
      <Select
        options={countryCodesOptions}
        value={isValue}
        onChange={changeHandler}
        className="r-select"
        components={{
          IndicatorSeparator: () => null,
        }}
        styles={{
          dropdownIndicator: (provided) => ({
            ...provided,
            svg: {
              fill: "var(--blue_600)",
            },
            padding: "0 0.8rem 0 0",
          })
        }}
      />
    </Wrapper>
  );
}

export default CountrySelector;
