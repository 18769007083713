const english = {
  itemsPPText: "Items per page",
  ofText: "of",
};
const bahasa = {
  itemsPPText: "item per halaman",
  ofText: "dari",
};

export { english, bahasa };
