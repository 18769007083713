import { Outlet } from "react-router-dom";
import CommonWrapper from "../components/layout/auth/common-wrapper";

function UserAuthRoutes() {
  return (
    <CommonWrapper>
      <Outlet />
    </CommonWrapper>
  );
}

export default UserAuthRoutes;
