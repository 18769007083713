import React, { useState } from "react";
import myStorage from "./my-storage";
import apiCall from "../api";
import { API_END_POINTS } from "../api/api-end-points";
import { useNavigate } from "react-router-dom";
import { AUTH_PATHS } from "../all-routes/paths";
interface Iinitial {
  name?: any;
  email?: any;
  role_name?: any;
  user_image?: any;
  profile_image?: any;
  um_user_id?: any;
}

const initialUserData: Iinitial = {
  name: "",
  email: "",
  role_name: "",
  um_user_id: "",
  user_image: "",
  profile_image: "",
};

export const UserContext = React.createContext({
  userData: initialUserData,
  setUserFn: (userData: any) => {},
  logoutFn: () => {},
  formData: {
    s1: null,
    s2: null,
    s3: null,
  },
  setFormData: (args: any) => {},
  prevState: {
    s1: null,
    s2: null,
  },
  setPrevState: (args: any) => {},
  villageData: "",
});

function UserContextProvider(props: any) {
  const storage = myStorage();
  const navigate = useNavigate();
  const isUserIn = !!storage.get("accessToken") && !!storage.get("userDetails");
  const newAt = !!storage.get("newAT");
  const userObject: any = isUserIn
    ? JSON.parse(storage.get("userDetails") || "")
    : "";
  const [userData, setUserData] = React.useState<Iinitial>(initialUserData);
  const [formData, setFormData] = React.useState({
    s1: null,
    s2: null,
    s3: null,
  });
  const [prevState, setPrevState] = React.useState({
    s1: null,
    s2: null,
    s3: null,
  });
  const [village, setVillage] = useState("");

  React.useEffect(() => {
    if (isUserIn) {
      handleSetVillage();
      setUserData(JSON.parse(storage.get("userDetails") || ""));
    }
  }, [isUserIn]);

  React.useEffect(() => {
    if (isUserIn && !newAt) {
      setUserHandler(userObject?.um_user_id);
    }
  }, [newAt]);

  async function setUserHandler(userId: any) {
    if (!userId) return;
    try {
      const { data } = await apiCall.get(
        `${API_END_POINTS.GET_USER_DETAILS}${userId}`
      );
      let userObject = data.data;
      storage.set("villageDetails", userObject?.village_details);
      delete userObject["uploaded_files"];
      delete userObject["survey_count"];
      delete userObject["file_upload_count"];
      storage.set("userDetails", JSON.stringify(userObject));
      setUserData(userObject);
      storage.set("newAT", "newAccessToken");
      return;
    } catch (error: any) {
      alert(error?.message || "Something went wrong!!");
      navigate(AUTH_PATHS.LOGIN);
      return;
    }
  }

  const handleSetVillage = () => {
    let villageData: any = storage.get("villageDetails");
    if (villageData && userData) {
      villageData = JSON.parse(villageData);
      setVillage(villageData);
      setFormData((prevState: any) => ({
        ...prevState,
        s3: villageData?.name,
      }));
    }
  };

  function logoutHandler() {
    storage.remove("accessToken");
    storage.remove("userDetails");
    storage.remove("refreshToken");
    storage.remove("villageDetails");
    navigate(AUTH_PATHS.LOGIN);
  }

  const context: any = {
    userData: userData,
    setUserFn: setUserHandler,
    logoutFn: logoutHandler,
    formData: formData,
    setFormData: setFormData,
    prevState: prevState,
    setPrevState: setPrevState,
    villageData: village,
  };

  return (
    <UserContext.Provider value={context}>
      {props.children}
    </UserContext.Provider>
  );
}

export default UserContextProvider;
