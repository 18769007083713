import React from "react";
import GridView from "./view-types/grid-view";
import ListView from "./view-types/list-view";
import PaginationManger from "../../../components/ui-components/pagination";
import styled from "styled-components";
import { PaginatorPageChangeEvent } from "primereact/paginator";
import FileView from "./sub-components/file-viewer";
import IconsBox from "../../../components/ui-components/iconbox";
import Toaster from "../../../components/ui-components/toaster";
import { useDeleteFile } from "../../../api/user-api";

const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 2.4rem 0 0 0;
  .dropdown {
    .p-dropdown-label {
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 15px;
      letter-spacing: 0em;
    }
    .p-dropdown-items {
      font-size: 1.2rem !important;
      font-weight: 500;
      line-height: 15px;
      letter-spacing: 0em;
    }
  }
  .pagination {
    background: transparent;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    button {
      svg {
        transform: scale(1.5);
        path {
          fill: var(--blue_600);
        }
      }
    }
  }
`;
const NoFilesWrapper = styled.div`
  width: 100%;
  height: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--white_900);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.04);
  border-radius: 1.5rem;
  gap: 1.8rem;
  .no-files-text {
    color: var(--gray_500);
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
  }
`;

type DataType = {
  type: string;
  url: string;
  title: string;
  description: string;
};

type IProps = {
  data?: DataType[];
  text1?: string;
  text2?: string;
  text3?: string;
  viewType?: string;
  files: any;
  onRefetch: any;
  onHandlePagination: any;
  row: any;
  first: any;
  totalItems: any;
};

const DataList: React.FC<IProps> = ({
  text1,
  text2,
  text3,
  viewType,
  files,
  onRefetch,
  onHandlePagination,
  row,
  first,
  totalItems,
}) => {
  const onSuccess = (res: any) => {  
    onRefetch();
    setShowToast({
      status: true,
      type: "success",
      message: "File Deleted!",
      reason: "",
    });
  
  };
  const onError = (res: any) => {
    setShowToast({
      status: true,
      type: "error",
      message: "File delete Failed!",
      reason: res?.response?.data?.message,
    });
  };

  const [showSuccessModal, setShowSuccessModal] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState<any>(null);
  const { mutate: deleteFile } = useDeleteFile(onSuccess, onError);
  const [showToast, setShowToast] = React.useState({
    status: false,
    type: "",
    message: "",
    reason: "",
  });

  const handleShowFile = (file_id: string) => {
    const file = files.find((fileObj: any) => fileObj.file_id === file_id);
    setSelectedFile(file);
    setShowSuccessModal(true);
  };

  const handleDeleteFile = async (fileId: string) => {
    setShowToast({
      status: false,
      type: "",
      message: "",
      reason: "",
    });
    await deleteFile(fileId);
  };

  return (
    <>
      <Toaster
        showToast={showToast.status}
        severity={showToast.type}
        summary={showToast.message}
        detail={showToast.reason}
      />
      {files && files.length ? (
        <>
          {viewType == "grid" ? (
            <GridView
              data={files}
              text1={text1}
              text2={text2}
              onShowFile={handleShowFile}
              onDeleteFile={handleDeleteFile}
            />
          ) : (
            <ListView
              data={files}
              text1={text1}
              text2={text2}
              onShowFile={handleShowFile}
              onDeleteFile={handleDeleteFile}
            />
          )}
          <PaginationWrapper>
            <PaginationManger
              onPageChange={onHandlePagination}
              row={row}
              page={first}
              totalItems={totalItems}
            />
          </PaginationWrapper>
          <FileView
            showModal={showSuccessModal}
            onHideModal={() => setShowSuccessModal(false)}
            type={selectedFile?.file_format}
            data={selectedFile}
          />
        </>
      ) : (
        <NoFilesWrapper>
          <IconsBox name="no-files" />
          <p className="no-files-text">{text3}</p>
        </NoFilesWrapper>
      )}
    </>
  );
};

export default DataList;
