import React from "react";
import styled from "styled-components";
import IconsBox from "../iconbox";
import Spinner from "../spinner";
import { Knob } from "primereact/knob";
import convertBytesToFileSize from "../../../pages/manage-files/components/sub-components/file-size-units";
import { validateFileType } from "../../../pages/manage-files/components/sub-components/validate-file";

const DropWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const InlineWrapper = styled.div`
  width: min(67.6rem, 100%);
  height: max(18.1rem, 181px);
  background: rgba(4, 104, 177, 0.02);
  border: 1px dashed rgba(139, 154, 170, 0.5);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .icon {
    opacity: 0.4;
  }
  .desc {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: -0.02em;
    color: var(--bluish_gray);
    margin: 2rem 0 0.8rem 0;
    .browse {
      color: var(--blue_500);
      :hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
  }
  .file-types {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: -0.02em;
    color: var(--bluish_gray);
    margin: 0.5rem 0 0 0;
  }
  .uploading-details {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: -0.02em;
    color: var(--bluish_gray);
    margin: 2.4rem 0 1rem 0;
  }
  .cancel-btn {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: -0.02em;
    text-align: center;
    color: #ff5d4e;
    :hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
  .icon-text {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.6rem;
    p {
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: -0.02em;
      color: var(--green_100);
    }
  }
  .file-size {
    margin: 0 0 1rem 0;
  }
`;

interface Iprops {
  text1: string;
  text2: string;
  text3: string;
  text4: string;
  text5: string;
  text6: string;
  text7: string;
  text8: string;
  text9: string;
  onFileUpload: any;
  onCancel: any;
  status: string;
}
function DropZone({
  text1,
  text2,
  text3,
  text4,
  text5,
  text6,
  text7,
  text8,
  text9,
  status,
  onFileUpload,
  onCancel,
}: Iprops) {
  const fileInputRef = React.useRef<any>(null);
  const [file, setFile] = React.useState<any>({});
  const [fileError, setFileError] = React.useState<any>(false);

  const transferFileToMainComponent = async (uploadedFile: any) => {
    setFileError(false);
    const isValid = await validateFileType(uploadedFile);
    if (isValid) {
      const fileSize = await convertBytesToFileSize(uploadedFile.size);
      setFile(() => ({ name: uploadedFile.name, size: fileSize }));
      onFileUpload(uploadedFile);
    } else {
      setFileError(true);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    transferFileToMainComponent(event.dataTransfer.files[0]);
  };

  const handleFileChange = (event: any) => {
    event.preventDefault();
    transferFileToMainComponent(event.target.files[0]);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleTextClick = () => {
    fileInputRef.current.click();
  };

  return (
    <DropWrapper>
      {status == "no-file" ? (
        <InlineWrapper onDrop={handleDrop} onDragOver={handleDragOver}>
          <span className="icon">
            <IconsBox name="files-combo" />
          </span>
          <div className="desc">
            {text1}
            <span onClick={handleTextClick} className="browse">
              {" "}
              {text2}
            </span>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </div>
          <p className="error-text-css">{fileError ? text9 : null}</p>
          <p className="file-types">{text3}</p>
        </InlineWrapper>
      ) : status == "uploading" ? (
        <InlineWrapper>
          <span className="icon">
            <Spinner />
          </span>

          {/* <Knob value={30} size={100} valueColor="var(--blue_600)" rangeColor="none" /> */}
          <div className="uploading-details">
            <p className="uploading-text">{text4}</p>
            <span>{""}</span>
            <span>{""}</span>
          </div>
          <span className="cancel-btn" onClick={onCancel}>
            {text5}
          </span>
        </InlineWrapper>
      ) : (
        <InlineWrapper>
          <span className="icon-text">
            <IconsBox name="check-circle" />
            <p>{text6}</p>
          </span>
          <div className="desc file-title">{file ? file.name : ""}</div>
          <p className="file-types file-size">
            {text8}: {file ? file.size : ""}
          </p>
          <span className="cancel-btn" onClick={onCancel}>
            {text7}
          </span>
        </InlineWrapper>
      )}
    </DropWrapper>
  );
}

export default DropZone;
