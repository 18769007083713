const english = {
  heading: "Reset Password",
  subHeading:
    "Please enter your registered email Id, we will send required instructions to reset password",
  inputLabel: "Email Address",
  inputPlaceHolder: "Enter your email address",
  buttonText: "Send Me Reset Link",
  backTo: "Back to Login",
  errorText1: "Entered Email ID or Password is incorrect",
  errorText2: "Email is required",
  errorText3: "Email is invalid",
};
const bahasa = {
  heading: "Atur Ulang Kata Sandi",
  subHeading:
    "Silakan masukkan ID email Anda yang terdaftar, kami akan mengirimkan instruksi yang diperlukan untuk mengatur ulang kata sandi",
  inputLabel: "Alamat email",
  inputPlaceHolder: "Masukkan alamat email Anda",
  buttonText: "Kirimi Saya Tautan Reset",
  backTo: "Kembali ke Login",
  errorText1: "ID Email atau Kata Sandi yang dimasukkan salah",
  errorText2: "Email diperlukan",
  errorText3: "email tidak valid",
};

export { english, bahasa };
