import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import IconsBox from "../iconbox";
import { LanguageContext } from "../../../store/language-context";
import { myPaths, mySubPaths } from "./path-names";
import useBreadcrumbs from "use-react-router-breadcrumbs";

const BreadCrumbsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 2rem 0 0 0;
  z-index: 2;
  .active,
  .not-active {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* gap: 1.5rem; */
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: -0.03em;
    margin: 0 1.5rem 0 0;
  }
  .active {
    color: var(--blue_500);
  }
  .not-active {
    color: var(--gray_600) !important;
  }
`;

const Breadcrumbs = () => {
  const languageCtx = React.useContext(LanguageContext);

  const breadcrumbs: any = useBreadcrumbs();
  const length = breadcrumbs.length - 1;

  return (
    <BreadCrumbsWrapper>
      {languageCtx?.isEnglish ? (
        <>
          {breadcrumbs.map(({ match, breadcrumb }: any, index: number) => {
            if (index == 0 || index == 1) {
              return <span key={index}></span>;
            } else if (length == index) {
              return (
                <span className="not-active" key={index}>
                  {breadcrumb}
                </span>
              );
            }
            return (
              <span key={index} className="active">
                <NavLink to={match.pathname}>{breadcrumb}</NavLink>
                <IconsBox name="right-arrow" />
              </span>
            );
          })}
        </>
      ) : (
        <>
          {breadcrumbs.map(({ match, breadcrumb }: any, index: number) => {
            if (index == 0 || index == 1) {
              return <span key={index}></span>;
            } else if (length == index) {
              return (
                <span className="not-active" key={index}>
                  {mySubPaths[breadcrumb?.props?.children]}
                </span>
              );
            }
            return (
              <span key={index} className="active">
                <NavLink to={match.pathname}>{myPaths[breadcrumb?.props?.children]}</NavLink>
                <IconsBox name="right-arrow" />
              </span>
            );
          })}
        </>
      )}
    </BreadCrumbsWrapper>
  );
};

export default Breadcrumbs;
