import React from "react";
import styled from "styled-components";

const VideoPlayerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.04);
  video {
    width: 100%;
    max-height: 50vh;
  }
`;

const VideoPlayer = (src: any) => {
  return (
    <VideoPlayerWrapper>
      <video src={src.src} autoPlay={false} controls></video>
    </VideoPlayerWrapper>
  );
};

export default VideoPlayer;
