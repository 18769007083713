import { Dialog } from "primereact/dialog";
import React from "react";
import { ButtonOutlined } from "../../../components/common-styles";
import styled from "styled-components";
import Avatar from "react-avatar-edit";

const UserImageBox = styled.div`
  width: max(22.7rem, 227px);
  height: max(22.7rem, 227px);
  border: 1px solid var(--gray_100);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  .image-cntrl {
    .user-image {
      object-fit: cover;
      width: max(115px);
      height: max(115px);
      border-radius: 50%;
      :hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
    .cntrols {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h3 {
        color: var(--blue_600);
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: -0.02em;
        margin: 1.7rem 0 0.5rem 0;
        :hover {
          cursor: pointer;
          opacity: 0.8;
        }
      }
      p {
        color: var(--gray_600);
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: -0.02em;
      }
    }
  }
`;

const SaveButton = styled(ButtonOutlined)`
  width: 98%;
  margin: 1rem 1%;
`;

interface Iprops {
  isShow?: boolean;
  title: string;
  subTitle: string;
  btnText: string;
  currentImg: string;
  fileTypeText: string;
  onUpdateImage: any;
}

const UploadProfile = ({
  isShow,
  title,
  subTitle,
  btnText,
  currentImg,
  onUpdateImage,
  fileTypeText,
}: Iprops) => {
  const [show, setShow] = React.useState(false);
  const [clickableBtn, setClickableBtn] = React.useState(true);
  const [imageCrop, setImageCrop] = React.useState<string | null>(null);
  const [image, setImage] = React.useState<any[]>([]);
  const [isValidFormat, setIsValidFormat] = React.useState<boolean>(true);

  function handleCrop(view: any) {
    if (!isValidFormat) return;
    setImageCrop(view);
  }

  function handleClose() {
    setImageCrop(null);
    setClickableBtn(true);
  }

  async function handleSave() {
    setImage([{ imageCrop }, ...image]);
    const file: any = imageCrop;
    // const formData = new FormData();
    // formData.append("file", file);
    onUpdateImage(file);
    setClickableBtn(true);
    setShow(false);
  }

  const handleFile = (file: any) => {
    if (
      file?.target?.files[0]?.type === "image/jpeg" ||
      file?.target?.files[0]?.type === "image/png"
    ) {
      setIsValidFormat(true);
      return;
    }
    setIsValidFormat(false);
  };

  const uploadedProfile = image.map((item) => item.imageCrop);

  return (
    <UserImageBox>
      <div className="image-cntrl">
        <img
          className="user-image"
          src={uploadedProfile?.length > 0 ? uploadedProfile[0] : currentImg}
          alt="user"
          onClick={() => setShow(true)}
        />
        <span className="cntrols">
          <h3 onClick={() => setShow(true)}>{title}</h3>
          <p>{subTitle}</p>
        </span>
      </div>

      <Dialog
        header={
          <h1
            style={{
              backgroundColor: "var(--white_900)",
              padding: "1rem 0",
            }}
          >
            Upload profile
          </h1>
        }
        visible={show}
        onHide={() => setShow(false)}
        // onMaskClick={() => setShow(false)}
        closeOnEscape={true}
        style={{
          backgroundColor: "var(--white_900)",
          padding: "1rem",
          borderRadius: "1rem",
          border: "1px solid var(--gray_100)",
        }}
        maskStyle={{
          backgroundColor: "var(--black_050)",
        }}
        draggable={false}
      >
        <Avatar
          width={400}
          height={400}
          onCrop={handleCrop}
          onClose={handleClose}
          onFileLoad={() => setClickableBtn(false)}
          onBeforeFileLoad={handleFile}
        />
        {!isValidFormat ? <span className="error-text-css">{fileTypeText}</span> : null}
        <SaveButton onClick={handleSave} disabled={clickableBtn}>
          {btnText}
        </SaveButton>
      </Dialog>
    </UserImageBox>
  );
};

export default UploadProfile;
