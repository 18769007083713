import React from "react";
import styled from "styled-components";
import {
  AuthFormWrapper,
  AuthWrapper,
  ErrorWrapper,
  Input,
} from "../../../components/common-styles";
import { bahasa, english } from "./text";
import IconsBox from "../../../components/ui-components/iconbox";
import {
  AuthButton,
  AuthInput,
  InputWrapper,
} from "../../user-login/components/login";
import { Link, useNavigate } from "react-router-dom";
import Toaster from "../../../components/ui-components/toaster";
import { LanguageContext } from "../../../store/language-context";
import { AUTH_PATHS } from "../../../all-routes/paths";
import { useForgotPassword } from "../../../api/user-api";

const OtherControls = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5.6rem 0 0rem 0;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    .p-1 {
      color: var(--primary-900);
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0em;
      margin: 0;
    }
  }
  .p-2 {
    color: var(--blue_600);
    cursor: pointer;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    margin: 0;
  }
`;

// none style
interface FormData {
  email: string;
}

interface FormErrors {
  email: string | null;
  isError: boolean;
}

const initialFormData: FormData = {
  email: "",
};

const initialFormErrors: FormErrors = {
  email: null,
  isError: false,
};

function ResetPasswordComponent() {
  const {isEnglish} = React.useContext(LanguageContext)
  const navigate = useNavigate();

  const [formData, setFormData] = React.useState<FormData>(initialFormData);
  const [formErrors, setFormErrors] =
    React.useState<FormErrors>(initialFormErrors);
  const [showToast, setShowToast] = React.useState({
    status: false,
    type: "",
    message: "",
    reason: "",
  });

  const onSuccess = () => {
    setShowToast({
      status: true,
      type: "success",
      message: `Reset password link sent to`,
      reason: formData.email,
    });
    setFormErrors(initialFormErrors);
    setTimeout(() => setFormData(initialFormData), 1000);
    setTimeout(() => navigate(AUTH_PATHS.LOGIN), 1500);
  };
  const onError = (res: any) => {
    setShowToast({
      status: true,
      type: "error",
      message: res?.response?.data?.message || "Something went wrong!!",
      reason: "",
    });
  };
  const { mutate: forgotPassword } = useForgotPassword(onSuccess, onError);

  const {
    heading,
    subHeading,
    errorText1,
    errorText2,
    errorText3,
    backTo,
    inputLabel,
    inputPlaceHolder,
    buttonText,
  } = isEnglish ? english : bahasa;

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    setFormErrors((prevState) => ({ ...prevState, [name]: null }));
  }

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const errors: FormErrors = {
      email: null,
      isError: false,
    };
    let isValid = true;

    // Validate email
    if (!formData.email) {
      errors.email = errorText2;
      isValid = false;
    } else if (
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email.toLowerCase())
    ) {
      errors.email = errorText3;
      isValid = false;
    }

    if (!isValid) {
      setFormErrors(errors);
    } else {
      // Submit the form data here
      await forgotPassword(formData);
    }
    setShowToast({
      status: false,
      type: "",
      message: "",
      reason: "",
    });
  }

  return (
    <AuthWrapper>
      <Toaster
        showToast={showToast.status}
        severity={showToast.type}
        summary={showToast.message}
        detail={showToast.reason}
      />
      <h1 className="heading">{heading}</h1>
      <p className="sub-heading">{subHeading}</p>
      {formErrors.isError ? (
        <ErrorWrapper>
          <IconsBox name="doubt" />
          <p className="error-text error-text-css">{errorText1}</p>
        </ErrorWrapper>
      ) : (
        <></>
      )}

      <AuthFormWrapper onSubmit={handleSubmit}>
        <InputWrapper>
          <label htmlFor="email">{inputLabel}</label>
          <AuthInput
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            placeholder={inputPlaceHolder}
            isError={formErrors.email}
          />
          {formErrors.email && (
            <span className="error-msg">{formErrors.email}</span>
          )}
        </InputWrapper>
        <AuthButton type="submit">{buttonText}</AuthButton>{" "}
        <OtherControls>
          <Link to={AUTH_PATHS.LOGIN}>
            <p className="p-2">{backTo}</p>
          </Link>
        </OtherControls>
      </AuthFormWrapper>
    </AuthWrapper>
  );
}

export default ResetPasswordComponent;
