export const validateFileType = (file: File): boolean => {
  const allowedExtensions = ['png', 'jpeg', 'jpg', 'wav', 'mp3', 'mp4', 'xls','xlsx'];
  const fileExtension = getFileExtension(file.name);

  if (allowedExtensions.includes(fileExtension)) {
    return true;
  }

  return false;
};

const getFileExtension = (fileName: string): string => {
  const parts = fileName.split('.');
  return parts[parts.length - 1].toLowerCase();
};

