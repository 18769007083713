import React from "react";
import styled from "styled-components";
import { LanguageContext } from "../../../store/language-context";
import { bahasa, english } from "./text";
import { ButtonOutlined, Input } from "../../../components/common-styles";
import { useNavigate } from "react-router-dom";
import { SETTINGS_SUB_PATHS } from "../../../all-routes/paths";
import { UserContext } from "../../../store/user-context";

interface IStyles {
  isError?: string | null;
}

const SettingsHomeWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  .line {
    height: 1px;
    background-color: var(--gray_450);
    margin: 2.2rem 0 3rem 0;
  }
`;

export const Heading = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  h2 {
    color: var(--blue_800);
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: -0.02em;
  }
  p {
    color: var(--gray_600);
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    margin: 0.8rem 0 1.6rem 0;
  }
`;

const ProfileWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
  .user-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .user-image {
      object-fit: cover;
      width: max(63px);
      height: max(63px);
      border-radius: 5rem;
      margin: 0 1rem 0 0rem;
    }
    .user-details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      .user-name {
        font-size: 1.8rem;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: -0.02em;
      }
      .user-email {
        color: var(--gray_800);
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: -0.02em;
        margin: 0.8rem 0 0 0;
      }
    }
  }
`;

const EditButton = styled(ButtonOutlined)`
  width: max(12.5rem, 125px);
  height: max(4.7rem, 47px);
`;

const PasswordSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 2rem;
  flex-wrap: wrap;
  span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    h2 {
      color: var(--blue_800);
      font-size: 1.4rem;
      font-weight: 600;
      line-height: 17px;
      letter-spacing: -0.02em;
    }
    p {
      color: var(--gray_600);
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 15px;
      letter-spacing: 0em;
      text-align: left;
      margin: 0.8rem 0 1.9rem 0;
    }
    input {
      background-color: var(--gray_100);
      color: var(--gray_600);
    }
  }
`;
const ChangePassButton = styled(ButtonOutlined)`
  width: max(17.4rem, 174px);
  height: max(4.7rem, 47px);
`;

function SettingsHome() {
  const languageCtx = React.useContext(LanguageContext);
  const { userData } = React.useContext(UserContext);

  const navigate = useNavigate();

  const {
    heading,
    subHeading,
    editBtnText,
    changeBtnText,
    password,
    villageDetails,
    villageDetailsSub,
    passwordSub,
  } = languageCtx.isEnglish ? english : bahasa;
  const [userDetails, setUserDetails] = React.useState<any>({});

  React.useEffect(() => {
    try {
      const userDetails = localStorage.getItem("userDetails");
      if (userDetails) {
        console.log(JSON.parse(userDetails));
        setUserDetails(JSON.parse(userDetails));
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  return (
    <SettingsHomeWrapper>
      <Heading>
        <h2>{heading}</h2>
        <p>{subHeading}</p>
      </Heading>

      <ProfileWrapper>
        <div className="user-box">
          <img
            className="user-image"
            src={userData?.user_image || "/images/test-user.jpg"}
            alt="user"
          />
          <span className="user-details">
            <p className="user-name">{userData?.name || ""}</p>
            <p className="user-email">{userData?.email || ""}</p>
          </span>
        </div>
        <EditButton
          onClick={() =>
            navigate(
              `${SETTINGS_SUB_PATHS.SETTINGS_BASE}${SETTINGS_SUB_PATHS.EDIT_PROFILE}`
            )
          }
        >
          {editBtnText}
        </EditButton>
      </ProfileWrapper>

      <div className="line"></div>

      <PasswordSection>
        <span>
          <h2>{password}</h2>
          <p>{passwordSub}</p>
          <Input value={"********"} readOnly={true} />
        </span>
        <ChangePassButton
          onClick={() =>
            navigate(
              `${SETTINGS_SUB_PATHS.SETTINGS_BASE}${SETTINGS_SUB_PATHS.CHANGE_PASSWORD}`
            )
          }
        >
          {changeBtnText}
        </ChangePassButton>
      </PasswordSection>
      <div className="line"></div>
      {userDetails && userDetails.village_details && (
        <>
          <Heading>
            <h2>{villageDetails}</h2>
            <p>{villageDetailsSub}</p>
          </Heading>
          <ProfileWrapper>
            <span>
              <div className="user-box">
                <span className="user-details">
                  <p className="user-name">
                    {userDetails.village_details.name}
                  </p>
                  <p className="user-email">
                    Latitude: {userDetails.village_details.latitude}
                  </p>
                  <p className="user-email">
                    Longitude: {userDetails.village_details.longitude}
                  </p>
                </span>
              </div>
            </span>
          </ProfileWrapper>
        </>
      )}
    </SettingsHomeWrapper>
  );
}

export default SettingsHome;
