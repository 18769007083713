import { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import styled from "styled-components";

const ChartWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* flex-wrap: wrap-reverse; */
  gap: 5rem;
  .chart-box {
    max-width: 500px;
    width: 200px;
    min-width: 180px;
  }
`;


export default function PieChart({ keyStatsData }: any) {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const data = {
      labels: keyStatsData ? ["12-17","18-30", "31-45", "46-65", "66-100"] : [],
      datasets: [
        {
          data: [
            keyStatsData?.age_distribution["12-17"] || 0,
            keyStatsData?.age_distribution["18-30"] || 0,
            keyStatsData?.age_distribution["31-45"] || 0,
            keyStatsData?.age_distribution["46-65"] || 0,
            keyStatsData?.age_distribution["66-100"] || 0,
          ],
          backgroundColor: [
            documentStyle.getPropertyValue("--blue-900"),
            documentStyle.getPropertyValue("--pink-400"),
            documentStyle.getPropertyValue("--blue-500"),
            documentStyle.getPropertyValue("--yellow-500"),
            documentStyle.getPropertyValue("--green-500"),
          ],
          hoverBackgroundColor: [
            documentStyle.getPropertyValue("--blue-200"),
            documentStyle.getPropertyValue("--pink-500"),
            documentStyle.getPropertyValue("--blue-600"),
            documentStyle.getPropertyValue("--yellow-600"),
            documentStyle.getPropertyValue("--green-600"),
          ],
        },
      ],
    };
    const options = {
      cutout: "60%",
    };

    setChartData(data);
    setChartOptions(options);
  }, []);

  return (
    <ChartWrapper>
      <div className="chart-box">
        <Chart
          type="doughnut"
          data={chartData}
          options={chartOptions}
          className="pie-chart"
        ></Chart>
      </div>
    </ChartWrapper>
  );
}
