const english = {
  heading: "Profile",
  subHeading: "User can view the profile and edit details",
  editBtnText: "Edit profile",
  password: "Password",
  villageDetails: 'Village details',
  passwordSub: "User can change the profile password",
  villageDetailsSub: 'User village details',
  changeBtnText: "Change Password",
  // Edit profile
  upload: 'Upload Image',
  uploadTypes: 'PNG, JPEG Files type',
  input0Label: "Name",
  input1Label: "Country code & Phone Number*",
  input2Label: "Address*",
  input0PlaceHolder: "Enter name",
  input1PlaceHolder: "Add phone number",
  input2PlaceHolder: "Add your address",
  cancelBtn: "Cancel",
  saveBtn: "Save Changes",
  saveBtn2: "Upload",
  setPasswordBtn: 'Set Password',

  // Change password
  input3Label: "Old Password",
  input4Label: "New Password",
  input5Label: "Confirm Password",
  inputPlaceHolder: "Enter your password",
  buttonText: "Reset Password",
  errorText: "Entered passwords do not match, please re-enter.",
  errorPasswordText1: "Password is required",
  errorPasswordText2: "Password is required",
  errorPasswordText3: "Password didn't match",
  errorPasswordText4: "Follow password rules",
  passwordRules: 'The entered password should have -',
  passwordHint1: 'At least one uppercase',
  passwordHint2: 'At least one Special character',
  passwordHint3: 'At least one Number',
  passwordHint4: 'Minimum 8 characters',
  passwordUpdatedText: "Password Updated!",
  passwordUpdateFailedText: "Password update failed!",
  pleaseLoginText: "Please login",

  // Modal messages
  modalHeader: 'Profile Updated',
  modalBtnText: 'Okay, Go To Profile',
  modalContent: 'Your profile has been updated successfully',
  modalHeader2: 'Password Updated',
  modalContent2: 'Your password has been updated successfully'
};
const bahasa = {
  heading: "Profil",
  subHeading: "Pengguna dapat melihat profil dan mengubah rincian",
  editBtnText: "Ubah profil",
  password: "Kata sandi",
  villageDetails: 'Detail Desa',
  passwordSub: "Pengguna dapat mengubah kata sandi profil",
  villageDetailsSub: "Pengguna detail desa",
  changeBtnText: "Ganti kata sandi",
  // Edit profile
  upload: 'Unggah Gambar',
  uploadTypes: 'Jenis file: PNG, JPEG',
  input0Label: "Nama",
  input1Label: "Kode negara & Nomor telepon*",
  input2Label: "Alamat*",
  input0PlaceHolder: "Masukkan nama",
  input1PlaceHolder: "Tambahkan nomor telepon",
  input2PlaceHolder: "Tambahkan alamat Anda",
  cancelBtn: "Batal",
  saveBtn: "Simpan perubahan",
  saveBtn2: "Mengunggah",
  setPasswordBtn: 'Tetapkan Kata Sandi',

  // Change password
  input3Label: "Kata Sandi Lama",
  input4Label: "Kata Sandi Baru",
  input5Label: "Konfirmasi Kata Sandi",
  inputPlaceHolder: "Masukkan kata sandi Anda",
  buttonText: "Atur Ulang Kata Sandi",
  errorText: "Kata sandi yang dimasukkan tidak cocok, harap masukkan kembali.",
  errorPasswordText1: "katakunci dibutuhkan",
  errorPasswordText2: "katakunci dibutuhkan",
  errorPasswordText3: "Kata sandi tidak cocok",
  errorPasswordText4: "Ikuti aturan kata sandi",
  passwordRules: 'Kata sandi yang dimasukkan harus memiliki -',
  passwordHint1: 'Setidaknya satu huruf kapital',
  passwordHint2: 'Setidaknya satu karakter khusus',
  passwordHint3: 'Setidaknya satu nomor',
  passwordHint4: 'Minimal 8 karakter',
  passwordUpdatedText: "Kata Sandi Diperbarui!",
  passwordUpdateFailedText: "Pembaruan kata sandi gagal!",
  pleaseLoginText: "Silahkan masuk",

  // Modal messages
  modalHeader: 'Profil diperbarui',
  modalBtnText: 'Oke, Buka Profil',
  modalContent: 'Profil Anda telah berhasil diperbarui',
  modalHeader2: 'Kata sandi Diperbarui',
  modalContent2: 'Kata sandi Anda telah berhasil diperbarui'
};

export { english, bahasa };
