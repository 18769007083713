import React from "react";
import styled from "styled-components";
import { bahasa, english } from "./text";
import { SelectWrapper } from "./sub-components/Perosna";
import RSelect from "../../../components/ui-components/select/r-select";
import IconsBox from "../../../components/ui-components/iconbox";
import {
  useFetchDistrict,
  useFetchPlaces,
  useFetchVillage,
} from "../../../api/user-api/dashboard-api";
import { Select } from "antd";
import NoData from "../../../components/ui-components/other/NoData";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 0 0 2rem 0;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  .heading {
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: -0.02em;
    /* color: var(--blue_800); */
  }
`;
const SelectContainer = styled(SelectWrapper)`
  width: fit-content;
  gap: 1.5rem;
  z-index: 3;
  margin: 0 0;
`;
const SelectTagBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  width: 15.4rem;
  label {
    font-size: 1.2rem;
    font-weight: 500;
  }
  .error-msg {
    font-size: 1rem !important;
    position: absolute;
    bottom: -1.8rem;
    left: 0;
  }
`;

// none style
interface IProps {
  isEnglish: boolean;
  formData: any;
  onSelect: any;
  prevState: any;
  onPrevState: any;
}
const TopCard = ({
  isEnglish,
  formData,
  onSelect,
  prevState,
  onPrevState,
}: IProps) => {
  const { provinceText, districtText, villageText, defOptText } = isEnglish
    ? english
    : bahasa;

  const [isError, setIsError] = React.useState<any>({
    s1: false,
    s2: false,
    s3: false,
  });

  // get area list from server
  // PROVINCE
  // const { data: provinceList, isLoading: provinceListLoading } =
  //   useFetchPlaces("");
  // DISTRICT
  // const { data: districtList, isFetching: districtListLoading } =
  //   useFetchDistrict(formData.s1);
  // VILLAGE
  // const { data: villageList, isFetching: villageListLoading } = useFetchVillage(
  //   formData.s2
  // );

  const handleSelection = (value: any, name: any) => {
    onSelect((prevState: any) => ({ ...prevState, [name]: value }));
    if (name === "s3") {
      onPrevState(formData);
    }
    setIsError((prevState: any) => ({ ...prevState, [name]: false }));
  };

  // for clearing the district and village when user change the province
  React.useEffect(() => {
    if (formData?.s1 !== prevState?.s1) {
      onSelect((prevState: any) => ({ ...prevState, s2: null }));
      onSelect((prevState: any) => ({ ...prevState, s3: null }));
    }
  }, [formData.s1]);

  // for clearing the village when user change the district only
  React.useEffect(() => {
    if (formData?.s2 !== prevState?.s2) {
      onSelect((prevState: any) => ({ ...prevState, s3: null }));
    }
  }, [formData.s2]);

  return (
    <Wrapper className="child-components">
      {formData.s3 != null ? (
        <Header>
          <IconsBox name={"pin"} />
          <h1 className="heading">{`${formData.s3}` || ""}</h1>
        </Header>
      ) : (
        <span></span>
      )}
      {/* Village select option is hiding, new change. This option only available in admin portal */}
      {/* <SelectContainer>
        <SelectTagBox>
          <label>{provinceText + "*"}</label>
          <Select
            options={provinceList}
            suffixIcon={<IconsBox name="dropdown" />}
            onSelect={(value: any) => handleSelection(value, "s1")}
            placeholder={defOptText}
            value={formData.s1}
            notFoundContent={<NoData/>}
          />
          {isError.s1 && (
            <p className="error-text-css error-msg">
              {defOptText + " " + provinceText}
            </p>
          )}
        </SelectTagBox>
        <SelectTagBox>
          <label>{districtText + "*"}</label>
          <Select
            options={districtList}
            suffixIcon={<IconsBox name="dropdown" />}
            onSelect={(value: any) => handleSelection(value, "s2")}
            placeholder={defOptText}
            value={formData.s2}
            notFoundContent={<NoData/>}
          />
          {isError.s2 && (
            <p className="error-text-css error-msg">
              {defOptText + " " + districtText}
            </p>
          )}
        </SelectTagBox>
        <SelectTagBox>
          <label>{villageText + "*"}</label>
          <Select
            options={villageList}
            suffixIcon={<IconsBox name="dropdown" />}
            onSelect={(value: any) => handleSelection(value, "s3")}
            placeholder={defOptText}
            value={formData.s3}
            notFoundContent={<NoData/>}
          />
          {isError.s3 && (
            <p className="error-text-css error-msg">
              {defOptText + " " + villageText}
            </p>
          )}
        </SelectTagBox>
      </SelectContainer> */}
    </Wrapper>
  );
};

export default React.memo(TopCard);
