import { Dialog } from "primereact/dialog";
import styled from "styled-components";
import { AuthButton } from "../../../user-login/components/login";
import React from "react";
import IconsBox from "../../../../components/ui-components/iconbox";
import { bahasa, english } from "../text";
import { ButtonOutlined } from "../../../../components/common-styles";

const Heading = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2.6rem 0;
  .heading-text {
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
  }
  .close-icon {
    cursor: pointer;
    opacity: 0.8;
  }
`;
const Content = styled.div`
  min-width: 43.4rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  .confirm-text {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0 0 1.4rem 0;
  }
`;
const FileBox = styled.div`
  width: 100%;
  max-height: 8.7rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--blue_100);
  border-radius: 0.6rem;
  gap: 1.8rem;
  padding: 1.5rem 2.2rem;
  .file-image {
    width: 5.4rem;
    height: 4.2rem;
    border-radius: 0.4rem;
  }
  .file-details {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.9rem;
    h3 {
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: -0.02em;
      text-align: left;
    }
    p {
      color: var(--gray_600);
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: -0.02em;
      text-align: left;
    }
  }
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.6rem;
  margin: 3.6rem 0 0 0;
`;

const NoButton = styled(ButtonOutlined)`
  width: max(7.1rem, 71px);
  height: max(4.7rem, 47px);
`;
const DeleteButton = styled(AuthButton)`
  width: max(9.6rem, 96px);
  height: max(4.7rem, 47px);
  color: var(--white_900);
  background: var(--red_600);
`;

interface Iprops {
  showModal: boolean;
  onHideModal: any;
  isEnglish: boolean;
  onDeleteFile: (arg0: string) => void;
  fileId: string;
  fileType: any;
  fileName?: string;
  fileURL?: string;
  fileSize?: string;
  isLoading?: boolean;
}

const DeleteFile = ({
  showModal,
  onHideModal,
  isEnglish,
  onDeleteFile,
  fileId,
  fileType,
  fileSize,
  fileName,
  fileURL,
  isLoading,
}: Iprops) => {
  const {
    deleteModalHeading,
    deleteConfirmText,
    deleteTxt,
    noText,
    fileSizeText,
  } = isEnglish ? english : bahasa;

  const headerTemplate = (
    <Heading>
      <h2 className="heading-text">{deleteModalHeading}</h2>
      <span className="close-icon" onClick={onHideModal}>
        <IconsBox name="close-icon" />
      </span>
    </Heading>
  );

  return (
    <Dialog
      header={headerTemplate}
      visible={showModal}
      onHide={onHideModal}
      style={{
        backgroundColor: "var(--white_900)",
        padding: "0rem 4rem 6rem 4rem",
        borderRadius: "1rem",
        border: "1px solid var(--gray_100)",
      }}
      maskStyle={{
        backgroundColor: "var(--black_050)",
      }}
      closable={false}
      draggable={false}
    >
      <Content>
        <p className="confirm-text">{deleteConfirmText}</p>
        <FileBox>
          <img
            className="file-image"
            src={
              fileType === "image"
                ? fileURL
                : fileType === "audio"
                ? "/images/audio-img.png"
                : fileType === "excel"
                ? "/images/excel.png"
                : fileType === "video"
                ? "/images/video-1.png"
                : ""
            }
            alt="file"
          />
          <div className="file-details">
            <h3>{fileName}</h3>
            <p>
              {fileSizeText}
              {": "}
              {fileSize}
            </p>
          </div>
        </FileBox>
        <Footer>
          <NoButton onClick={onHideModal}>{noText}</NoButton>
          <DeleteButton
            disabled={isLoading || false}
            onClick={() => onDeleteFile(fileId)}
          >
            {deleteTxt}
          </DeleteButton>
        </Footer>
      </Content>{" "}
    </Dialog>
  );
};

export default DeleteFile;
