import React from "react";
import styled from "styled-components";
import Spinner from "../../components/ui-components/spinner";

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const IFrame = styled.iframe`
  position: absolute;
  width: 100%;
  height: 100%;
  border: none;
`;

const Analytics = () => {
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Container>
      {isLoading ? (
        <Spinner />
      ) : (
        <IFrame
          className="i-frame"
          src={process.env.REACT_APP_SUPER_SET_URL}
          title="Embedded Website"
          width="100%"
          height="100%"
          frameBorder="0"
        />
      )}
    </Container>
  );
};

export default Analytics;
