import { Sidebar as Notification } from "primereact/sidebar";
import styled from "styled-components";
import IconsBox from "../../ui-components/iconbox";
import { useGetNotifications } from "../../../api/user-api";
import { bahasa, english } from "./text";

interface IStyles {
  isActive?: boolean;
}

const InnerWrapper = styled.div`
  width: max(49.9rem, 499px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .no-notification-text,
  .no-results-text {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: -0.02em;
    padding: 0 2.8rem;
  }
`;

const Heading = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.8rem;
  .wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    h1 {
      font-size: 1.8rem;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0em;
    }
    span {
      color: var(--blue_600);
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: -0.02em;
      margin: 0 0 0 1.8rem;
      :hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
  }
  .close-icon {
    :hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0rem 0;
`;

const Item = styled.div<IStyles>`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1.5rem 0 1.5rem 3rem;
  gap: 1rem;
  background-color: ${(props) => (props.isActive ? "var(--gray_050)" : "none")};
  .details {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;
    h3 {
      font-size: 1.4rem;
      font-weight: 600;
      line-height: 21px;
      letter-spacing: -0.02em;
      text-align: left;
    }
    p {
      color: var(--gray_600);
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: -0.02em;
      text-align: left;
    }
  }
`;

const styles = {
  minWidth: "fit-content",
  backgroundColor: "var(--white_900)",
  padding: "0rem",
  border: "1px solid var(--gray_100)",
  borderTopLeftRadius: "1.5rem",
  borderBottomLeftRadius: "1.5rem",
};

interface IProps {
  show: boolean;
  onHide: any;
  isEnglish: boolean;
}

const NotificationBar = ({ show, onHide, isEnglish }: IProps) => {
  const { notification, clearAll, noNotificationsText, noResults } = isEnglish
    ? english
    : bahasa;
  const { data: notificationList, isLoading, isError } = useGetNotifications();

  return (
    <Notification
      visible={show}
      position="right"
      onHide={onHide}
      style={styles}
      showCloseIcon={false}
      maskStyle={{
        backgroundColor: "var(--black_050)",
      }}
    >
      {isError ? (
        <InnerWrapper>
          <p className="no-results-text">{noResults}</p>
        </InnerWrapper>
      ) : (
        <InnerWrapper>
          <Heading>
            <div className="wrap">
              <h1>{notification}</h1>
              {notificationList?.length ? <span>{clearAll}</span> : null}
            </div>
            <div onClick={onHide} className="close-icon">
              <IconsBox name="close-icon" />
            </div>
          </Heading>
          {isLoading ? (
            <p className="">...</p>
          ) : (
            <Content>
              {notificationList?.length ? (
                notificationList.map((item: any, i: number) => (
                  <Item key={i} isActive={item.active}>
                    <IconsBox name="bell" fill={item.active ? "" : "#8B9AAA"} />
                    <div className="details">
                      <h3>{item.title}</h3>
                      <p>{item.date}</p>
                    </div>
                  </Item>
                ))
              ) : (
                <p className="no-notification-text">{noNotificationsText}</p>
              )}
            </Content>
          )}
        </InnerWrapper>
      )}
    </Notification>
  );
};

export default NotificationBar;
