import React from "react";
import ReactAudioPlayer from "react-audio-player";
import styled from "styled-components";

const AudioPlayerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: var(--gray_050);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.04);
  border-radius: 1.5rem;
  padding: 1.8rem 3.2rem;
  gap: 3rem;
  img {
    width: 12.9rem;
    height: 9.9rem;
  }
  audio {
    width: 100%;
  }
`;

const AudioPlayer = (src: any) => {
  return (
    <AudioPlayerWrapper>
      <img src="/images/audio-img.png" alt="audio thumbnail" />
      <ReactAudioPlayer src={src?.src} autoPlay={false} controls />
    </AudioPlayerWrapper>
  );
};

export default AudioPlayer;
