import React from "react";
import styled from "styled-components";
import MapComponent from "./sub-components/map";
import { LanguageContext } from "../../../store/language-context";
import ChatBot from "./sub-components/chat-bot";
import { UserContext } from "../../../store/user-context";
import { useFetchUploadedFiles } from "../../../api/user-api";

const Container = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1.2rem;
  margin: 0 0 1.2rem 0;
  @media screen and (max-width: 1198px) {
    width: 100%;
  }
`;

// none styles
interface IProps {
  latlon: any;
  placeName: any;
  mapLoading: boolean;
}

const RightCard = ({ latlon, placeName, mapLoading }: IProps) => {
  const languageCtx = React.useContext(LanguageContext);

  const {
    data: filesData,
    isLoading,
    refetch,
  } = useFetchUploadedFiles("", 5, 0, "image",placeName.s3);
  const {
    data: videoData,
    isLoading: isLoad,
    refetch: reFet,
  } = useFetchUploadedFiles("", 5, 0, "video",placeName.s3);
  const {
    data: audioData,
    isLoading: audioLoad,
    refetch: reFetAudio,
  } = useFetchUploadedFiles("", 5, 0, "audio",placeName.s3);
  return (
    <Container>
      <MapComponent
        isEnglish={languageCtx.isEnglish}
        latlon={{
          latitude: latlon?.lat || -2.3,
          longitude: latlon?.long || 119.6,
        }}
        placeName={placeName}
        mapLoading={mapLoading}
        files={filesData}
        video={videoData}
        audio={audioData}
      />
      <ChatBot isEnglish={languageCtx.isEnglish} village={placeName.s3} />
    </Container>
  );
};

export default RightCard;
