import React from "react";
import styled from "styled-components";
import { Input } from "../../common-styles";
import IconsBox from "../iconbox";
import { InputWrapper } from "../../../pages/user-login/components/login";

interface IStyles {
  isError?: string | null;
}
const LoginInput = styled(Input)<IStyles>`
  box-shadow: ${(props) =>
    props.isError != null
      ? `0 0 0 1px var(--red_600)`
      : `0 0 0 1px var(--gray_500)`};
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  margin: 0.7rem 0 0.5rem 0;
`;

interface IProps {
  isError?: string | null;
  isName?: string;
  isLabel?: string;
  isValue?: any;
  onChange?: any;
  isPlaceholder?: string;
}

const PasswordInput = ({
  isName,
  isError,
  isLabel,
  isValue,
  onChange,
  isPlaceholder,
}: IProps) => {
  const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);

  return (
    <InputWrapper>
      <label htmlFor="password">{isLabel}</label>
      <span className="password-input">
        <LoginInput
          type={isPasswordVisible ? "text" : "password"}
          name={isName ? isName : "password"}
          value={isValue}
          onChange={onChange}
          placeholder={isPlaceholder}
          isError={isError}
        />

        <span
          className="password-visible"
          onClick={() => setIsPasswordVisible(!isPasswordVisible)}
        >
          <IconsBox name={isPasswordVisible ? "visibility" : "invisibility"} />
        </span>
      </span>
      {isError && <span className="error-msg">{isError}</span>}
    </InputWrapper>
  );
};

export default PasswordInput;
