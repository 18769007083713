const english = {
  heading: "Welcome",
  subHeading: "Please enter your signup details below",
  inputAddressLabel: "Address",
  inputAddressPlaceHolder: "Enter address",
  input1Label: "Email Address",
  input1PlaceHolder: "Enter your email address",
  input2Label: "Password",
  input2PlaceHolder: "Enter your password",
  haveAccount: "Already have an account?",
  buttonText: "SignUp",
  errorText: "Entered Email ID or Password is incorrect",
  errorEmailText1: "Email is required",
  errorEmailText2: "Email is invalid",
  errorPasswordText3: "Please check the password format!",
  errorAddressText5: "Address is required",
  errorPasswordText1: "Password is required",
  passwordRules: "The entered password should have -",
  passwordHint1: "At least once uppercase",
  passwordHint2: "At least one Special character",
  passwordHint3: "At least one Number",
  passwordHint4: "Minimum 8 characters",
};
const bahasa = {
  heading: "Selamat datang",
  subHeading: "Silakan masukkan detail signup Anda di bawah ini",
  inputAddressLabel: "Alamat",
  inputAddressPlaceHolder: "Masukkan alamat",
  input1Label: "Alamat email",
  input1PlaceHolder: "Masukkan alamat email Anda",
  input2Label: "Kata sandi",
  input2PlaceHolder: "Masukkan kata sandi Anda",
  haveAccount: "Sudah memiliki akun?",
  loginText: "Mendaftar",
  buttonText: "Mendaftar",
  errorText: "ID Email atau Kata Sandi yang dimasukkan salah",
  errorEmailText1: "Email diperlukan",
  errorEmailText2: "email tidak valid",
  errorAddressText5: "alamat diperlukan",
  errorPasswordText3: "Silakan periksa format kata sandi!",
  errorPasswordText1: "katakunci dibutuhkan",
  passwordRules: "Kata sandi yang dimasukkan harus memiliki -",
  passwordHint1: "Setidaknya sekali huruf besar",
  passwordHint2: "Setidaknya satu karakter khusus",
  passwordHint3: "Setidaknya satu nomor",
  passwordHint4: "Minimal 8 karakter",
};

export { english, bahasa };
