import React, { useEffect } from "react";
import styled from "styled-components";
import { bahasa, english } from "./text";
import Spinner from "../../../components/ui-components/spinner";
import { useSocialList, useFetchPosts } from "../../../api/user-api";
import { AuthButton } from "../../user-login/components/login";
import Toaster from "../../../components/ui-components/toaster";

const Wrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  /* background-color: var(--white_900); */
`;

const Line = styled.div`
  width: 100%;
  border-top: 1px solid var(--gray_100);
`;
const CardWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  .child-card {
  }

  .key-trend {
    width: 100%;
  }
  .key-stats {
    width: 100%;
  }
`;
const FlexCol = styled.div`
  width: 48%;
  display: flex;
  // justify-content: space-between;
  flex-direction: column;
  gap: 0.7rem;
  .keyTrends-subheading {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: -0.02em;
    margin: 1rem 0;
  }
  .the-text-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.8rem;
    border-radius: 8px;
    padding: 1rem;
    background-color: var(--white_900);
    box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.11);
    .the-circle {
      min-width: 8px;
      min-height: 8px;
      border-radius: 5rem;
      background-color: var(--primary-500);
    }
    .orange {
      background-color: var(--orange-600);
    }
    .the-text {
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 15px;
      letter-spacing: -0.02em;
    }
  }
  .more-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .more-btn {
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 0.8rem;
    border: 1px solid var(--blue_800);
    background-color: var(--white_900);
    font-size: 1.4rem;
  }
`;
const Cards = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 1.2rem;
  box-shadow: 0px 4px 9px 0px rgba(221, 224, 227, 1);
  padding: 1.5rem;
  background-color: var(--white_900);
  margin-bottom: 20px;
  .card-heading {
    color: var(--blue_800);
    font-size: 1.8rem;
    font-weight: 600;
    letter-spacing: -0.0175rem;
    margin: 0 0 1.5rem 0;
  }
  .refreshImg {
    width: 17px;
    display: inline-block;
    margin-left: 10px;
    vertical-align: top;
    margin-top: 1px;
    transform: rotate(0deg);
    cursor: pointer;
    transition-duration: 400ms;
    &:hover {
      transform: rotate(180deg);
    }
  }
  .sub-title {
    font-size: 1.2rem;
    line-height: 1.5rem;
    letter-spacing: 0em;
    color: var(--blue_800);
    margin: 0 0 0.8rem 0;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 698px) {
    flex-direction: column;
  }
  .card-subs {
    padding: 2rem 0 0 0;
  }
  .v-line {
    width: 1px;
    height: 100%;
    background-color: var(--gray_100);
  }
`;

const Content = styled.div`
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.02em;
  margin: 1rem 0;
  ol,
  ul {
    padding-left: 15px;
    margin: 0px;
    line-height: 22px;
    span {
      font-weight: 600;
    }
  }
  .alphabet-list {
    list-style-type: lower-alpha;
  }
  img {
    max-width: 90%;
    margin: auto;
  }
  div {
    margin: 10px 0px;
  }
  h3 {
    margin: 10px 0px;
  }
  a {
    color: blue;
  }
`;
interface Iprops {
  isEnglish: boolean;
}
const TopHeader = styled.div`
  width: 100%;
  display: flex;
  gap: 2rem;
  justify-content: flex-end;
  align-items: center;
`;
const UploadButton = styled(AuthButton)`
  width: max(12.7rem, 170px);
  height: max(4.7rem, 47px);
  margin: 20px 0px;
`;
function FaqDetailsComponent({ isEnglish }: Iprops) {
  const [splitChallenges, setSplitChallenges] = React.useState(4000);
  const [splitOpportunities, setSplitOpportunities] = React.useState(4000);
  const [showToast, setShowToast] = React.useState({
    status: false,
    type: "",
    message: "",
    reason: "",
  });
  const [btnLoading, setBtnLoading] = React.useState(false);
  const [fetchTrigger, setFetchTrigger] = React.useState(false);
  React.useEffect(() => {
    setFetchTrigger(true);
    setTimeout(() => {
      setFetchTrigger(false);
    }, 500);
  }, []);

  const { heading, challengesText, fetchBtnText, fetching, opportunityText } =
    isEnglish ? english : bahasa;
  // const { data, isLoading } = useSocialList(isEnglish, fetchTrigger);

  const onSuccess = (res: any) => {
    setBtnLoading(false);
    setFetchTrigger(true);
    setTimeout(() => {
      setFetchTrigger(false);
    }, 500);
  };
  const onError = (res: any) => {
    setShowToast({
      status: true,
      type: "error",
      message: "",
      reason: res?.response?.data?.message,
    });
    setBtnLoading(false);
  };
  const handleFetch = async () => {
    setBtnLoading(true);
    await fetchpostsCall();
  };
  const { mutate: fetchpostsCall } = useFetchPosts(onSuccess, onError);
  return (
    <Wrapper>
      <Toaster
        showToast={showToast.status}
        severity={showToast.type}
        summary={showToast.message}
        detail={showToast.reason}
      />
      <Cards className="key-trend">
        <h1 className="card-heading">
          Apa saja permasalahan umum dalam proses musyawarah perencanaan
          pembangunan desa?
        </h1>
        <Content>
          <ol>
            <li>
              Musyawarah desa perlu ditingkatkan keterwakilan dari lapisan
              masyarakat, meliputi kelompok marjinal.{" "}
            </li>
            <li>
              Kurangnya kesempatan dan kepercayaan diri masyarakat desa untuk
              menyampaikan gagasan di forum musyawarah.
            </li>
            <li>Proses musyawarah desa monoton.</li>
            <li>
              Terdapat relasi kuasa di desa yang menyebabkan proses perencanaan
              desa menjadi tidak demokratis.
            </li>
            <li>
              Musyawarah desa belum di dukung oleh pengumpulan dan manajemen
              data atau informasi dan analisa data yang memadai.
            </li>
          </ol>
        </Content>
        <Line />
        <FlexWrapper>
          <FlexCol></FlexCol>
          <div className="v-line"></div>
        </FlexWrapper>
      </Cards>
      <Cards className="key-trend">
        <h1 className="card-heading">
          Apa itu Social Innovation Platform (SIP)?
        </h1>
        <Content>
          <div>
            Social Innovation Platform (SIP) adalah sebuah inisiatif yang
            diimplementasikan bersama oleh Kementerian Desa, Pembangunan Daerah
            Tertinggal, dan Transmigrasi Republik Indonesia serta United Nations
            Development Programme (UNDP). Program ini dirancang untuk
            mempercepat pencapaian Tujuan Pembangunan Berkelanjutan (Sustainable
            Development Goals atau SDGs). SIP menawarkan metode dan pendekatan
            untuk memperkuat kualitas musyawarah desa yang secara umum dilakukan
            melalui 6 (enam) tahapan sebagaimana digambarkan pada diagram
            berikut;
          </div>
          <img src="/images/faq/faq1.png" alt="faq" />
          <ol>
            <li>
              System mapping (Pemetaan Informasi : Aktor dan Inisiatif
              Masyarakat)
            </li>
            <li>
              Deep Listening (Menyimak suara masyarakat) atas kekuatiran,
              tantangan dan kecermataan atas masalah, kebutuhan, potensi dan
              peluang.
            </li>
            <li>
              Sense-making (Kesepahaman Bersama akan masalah, kebutuhan, potensi
              dan peluang)
            </li>
            <li>
              Co-creation : penyusunan usulan kegiatan dalam 4 tingkat kebutuhan
              (Proyek Berskala Kecil/Menengah, Proyek Berskala Besar, Layanan
              Baru, Peraturan Baru) menurut 5 bidang skala lokal desa.
            </li>
            <li>Prototyping : menetapkan usulan terpilih</li>
            <li>Co-Design : Mendetailkan usulan terpilih (Rencana Detail)</li>
            <li>
              Scaling: Dilakukan pasca implementasi seluruh usulan dan hasil
              evaluasi kinerja pembangunan desa
            </li>
          </ol>
        </Content>
        <Line />
        <FlexWrapper>
          <FlexCol></FlexCol>
          <div className="v-line"></div>
        </FlexWrapper>
      </Cards>
      <Cards className="key-trend">
        <h1 className="card-heading">
          Bagaimana SIP diintegrasikan dalam Proses Perencanaan di Desa? (done)
        </h1>
        <Content>
          <div>
            Lahirnya undang - undang no. 6 Tahun 2014 Tentang Desa memberikan
            kewenangan yang jelas kepada desa yaitu rekognisi, subsidiaritas dan
            penugasan dalam menjalankan tata kelola pemerintahan. Oleh karena
            itu, sangat penting bagi desa untuk memiliki perencanaan pembangunan
            desa mampu memperkuat hak dan kewenangan desa dalam mengoptimalkan
            sumber daya desa sebagai modal utama dalam pembangunan desa.
          </div>
          <div>
            Perencanaan pembangunan desa sebagaimana diatur dalam Permendes No.
            21 Tahun 2020 belum terlaksana secara efektif di tingkat desa,
            terutama dalam menciptakan proses yang inklusif dan partisipatif.
            Social Innovation Platform (SIP) yang diprakarsai UNDP merupakan
            pendekatan perencanaan pembangunan yang dapat menjawab kelemahan
            utama perencanaan pembangunan desa yang belum melibatkan semua
            pemangku kepentingan dalam penyusunan rencana desa. SIP melibatkan
            pemetaan pemangku kepentingan, menggali masalah dan gagasan di
            tingkat rumah tangga melalui mekanisme Deep Listening (mendengar
            lebih dalam), membangun saling pengertian dan membuat portfolio
            solusi.
          </div>
          <div>
            Pendekatan Social Innovation Platform diintegrasikan ke dalam
            perencanaan formal desa dalam penyusunan RPJMDes dan RKPDes. Dalam
            tahapan penyusunan RPJMDes pendekatan SIP dilaksanakan secara
            bersamaan dengan tahapan formal RPJMDes dan RKPDes, SIP tidak
            berdiri sebagai proses yang paralel melainkan terintegrasi langsung
            ke dalam proses perencanaan di Desa. Berikut tahapan integrasi SIP
            ke dalam RPJMDes dan RKPDes;
          </div>
          <h3>Integrasi SIP ke dalam RPJMDes</h3>
          <img src="/images/faq/faq2.png" alt="faq" />
          <h3>Integrasi SIP ke dalam RKPDes</h3>
          <img src="/images/faq/faq3.png" alt="faq" />
        </Content>
        <Line />
        <FlexWrapper>
          <FlexCol></FlexCol>
          <div className="v-line"></div>
        </FlexWrapper>
      </Cards>
      <Cards className="key-trend">
        <h1 className="card-heading">
          Apa itu Deep Listening (Menyimak Suara Masyarakat) dalam Social
          Innovation Platform?
        </h1>
        <Content>
          <div>
            Deep Listening atau menyimak suara masyarakat bertujuan untuk
            menggali persepsi dari masyarakat melalui wawancara terkait;
          </div>
          <ol className="alphabet-list">
            <li>Masalah</li>
            <li>Kebutuhan</li>
            <li>Potensi</li>
            <li>Peluang</li>
          </ol>
          <div>
            Proses Deep listening ini memiliki keluaran; 1) analisis narasi
            (masalah & kebutuhan, potensi dan peluang) dan Persona.
          </div>
        </Content>
        <Line />
        <FlexWrapper>
          <FlexCol></FlexCol>
          <div className="v-line"></div>
        </FlexWrapper>
      </Cards>
      <Cards className="key-trend">
        <h1 className="card-heading">Apa itu STRIVE?</h1>
        <Content>
          STRIVE adalah platform berbasis AI yang dikembangkan untuk membantu
          tahapan Pendengaran Mendalam (Deep Listening) di proses Social
          Innovation Platform. STRIVE digunakan untuk mengumpulkan mengolah dan
          menganalisis data untuk mengidentifikasi tantangan dan peluang yang
          ada di desa. STRIVE memungkikan masyarakat unutk berpartisipasi secara
          Inklusif dalam proses perencanaan pembangunan desa.
        </Content>
        <Line />
        <FlexWrapper>
          <FlexCol></FlexCol>
          <div className="v-line"></div>
        </FlexWrapper>
      </Cards>
      <Cards className="key-trend">
        <h1 className="card-heading">Apa yang dapat dilakukan oleh STRIVE?</h1>
        <Content>
          <ol>
            <li>Mendengarkan masalah, harapan dan aspirasi masyarakat desa.</li>
            <li>Menganalisis data yang dihimpun dari masyarakat desa.</li>
            <li>
              Menampilkan informasi terkait tantangan, peluang, rekomendasi
              pembangunan desa.
            </li>
          </ol>
        </Content>
        <Line />
        <FlexWrapper>
          <FlexCol></FlexCol>
          <div className="v-line"></div>
        </FlexWrapper>
      </Cards>
      <Cards className="key-trend">
        <h1 className="card-heading">Bagaimana cara STRIVE bekerja?</h1>
        <Content>
          <div>STRIVE bekerja dengan beberapa tahapan sebagai berikut</div>
          <ol className="alphabet-list">
            <li>
              Pengumpulan Data : STRIVE dapat menampung berbagai data dalam
              bentuk survey, foto, video, audio dan excel file.
            </li>
            <li>
              Agregasi Data : agregasi data dalam bentuk kualitatif dan
              kuantitatif.
            </li>
            <li>
              Pembersihan data : data yang telah di dapat dipilah pilah oleh
              STRIVE sesuai dengan kategori data.
            </li>
            <li>
              Analisis data : Analisis data STRIVE menggunakan berbagai Teknik
              analisis sebagai contoh Natural Language Processing (NLP),
              Analisis sentimen, Biterm Topic model, dst.
            </li>
            <li>
              Visualisasi Data : data hasil analisis ditampilkan di dashboard
              STIVE, dalam bentuk data demografis, key trends yang mencakup
              tantangan, peluang dan Digital profile.
            </li>
          </ol>
        </Content>
        <Line />
        <FlexWrapper>
          <FlexCol></FlexCol>
          <div className="v-line"></div>
        </FlexWrapper>
      </Cards>
      <Cards className="key-trend">
        <h1 className="card-heading">
          Bagaimana STRIVE dapat membantu proses perencanaan di Desa?
        </h1>
        <Content>
          <div>
            Dalam merancang perencanaan desa dengan pendekatan Social Innovation
            Platform, terdapat beberapa tahapan penting, seperti sense-making,
            co-creation, dan co-design, ditambah dengan satu proses tambahan
            yaitu deep listening & system mapping. STRIVE, sebagai platform yang
            dilengkapi dengan AI, akan mendukung dalam proses deep listening
            ini.
          </div>
          <div>
            Warga desa dapat menyampaikan aspirasi dan pendapat mereka melalui
            STRIVE dengan cara mengisi survei atau mengunggah foto, video, file
            terkait, maupun rekaman suara. Hal ini dapat menjangkau seluruh
            kalangan masyarakat, termasuk masyarakat yang mempunyai kesulitan
            dalam baca tulis.
          </div>
          <div>
            Data-data tersebut kemudian akan diolah oleh AI yang terintegrasi di
            STRIVE dan diubah menjadi tantangan, peluang, dan digital persona.
            Ini akan menjadi poin pertimbangan utama bagi pemerintah desa dalam
            menyusun dokumen perencanaan desa yang inklusif dan partisipatif.
          </div>
        </Content>
        <Line />
        <FlexWrapper>
          <FlexCol></FlexCol>
          <div className="v-line"></div>
        </FlexWrapper>
      </Cards>
      <Cards className="key-trend">
        <h1 className="card-heading">Apa saja sumber data STRIVE?</h1>
        <Content>
          <ol className="alphabet-list">
            <li>Survey</li>
            <li>Foto</li>
            <li>Video</li>
            <li>Audio</li>
            <li>Data pendukung lainnya.</li>
          </ol>
        </Content>
        <Line />
        <FlexWrapper>
          <FlexCol></FlexCol>
          <div className="v-line"></div>
        </FlexWrapper>
      </Cards>
      <Cards className="key-trend">
        <h1 className="card-heading">Apa sajakah isi survei STRIVE?</h1>
        <Content>
          <ol>
            <li>Jenis Kelamin</li>
            <li>Nama Desa</li>
            <li>Usia responden</li>
            <li>Pendapatan Tahunan</li>
            <li>Pendidikan Terakhir</li>
            <li>Status Pekerjaan</li>
            <li>Masyarakat Adat atau tidak?</li>
            <li>Apakah Responden memiliki Disabilitas atau tidak?</li>
            <li>Agama</li>
            <li>Apa saja tantangan yang ada di desa?</li>
            <li>Apa saja peluang yang ada di desa?</li>
            <li>
              Berdasarkan tantangan dan peluang yang disebutkan, apa saja
              gagasan atau inisiatif usulan kegiatan?
            </li>
            <li>Dimana lokasi usulan kegiatan tersebut akan dilakukan?</li>
            <li>
              Berapa perkiraan kebutuhan untuk melakukan kegiatan tersebut?
            </li>
            <li>
              Siapa saja yang akan menjadi penerima manfaat dari usulan kegiatan
              tersebut?
            </li>
            <li>Mengapa usulan kegiatan itu penting?</li>
          </ol>
        </Content>
        <Line />
        <FlexWrapper>
          <FlexCol></FlexCol>
          <div className="v-line"></div>
        </FlexWrapper>
      </Cards>
      <Cards className="key-trend">
        <h1 className="card-heading">Bagaimana tata kelola aplikasi STRIVE?</h1>
        <Content>
          <h3>Tugas dan Fungsi pengelolaan STRIVE tingkat Desa</h3>
          <h3>Kepala Desa</h3>
          <ol>
            <li>Menetapkan Admin Desa yang akan mengelola aplikasi STRIVE;</li>
            <li>Mendaftarkan akun Desa melalui Surat Pernyataan;</li>
            <li>
              Memantau dan mengawasi proses penggunaan aplikasi STRIVE dalam
              perencanaan pembangunan desa;
            </li>
            <li>
              Menindaklanjuti hasil analisis STRIVE ke dalam forum musyawarah
              dan ke dalam penyusunan RKP Desa atau RPJM Desa;
            </li>
          </ol>
          <h3>Admin Desa</h3>
          <ol>
            <li>Melakukan penginputan data ke dalam aplikasi STRIVE;</li>
            <li>
              Melakukan validasi atas kebenaran dan keabsahan data yang akan
              diinput;
            </li>
            <li>Melakukan pemutakhiran data hasil pengolahan data STRIVE;</li>
            <li>
              Melihat dan mengunduh hasil analisis STRIVE untuk kebutuhan
              perencanaan pembangunan
            </li>
          </ol>
          <h3>BPD</h3>
          <ol>
            <li>
              Mengumpulkan data baik primer maupun sekunder terkait kebutuhan,
              tantangan dan peluang dari masyarakat sehubungan dengan ide
              pembangunan desa;
            </li>
            <li>
              Memastikan keterwakilan unsur masyarakat dalam pengumpulan data
              STRIVE;
            </li>
            <li>
              Mengelola hasil analisis STRIVE agar digunakan dalam penerapan SIP
              pada proses perencanaan pembangunan desa;
            </li>
          </ol>
          <h3>Tim Penyusun RKPDes/RPJMDes</h3>
          <ol>
            <li>
              Mengumpulkan data baik primer maupun sekunder terkait kebutuhan,
              tantangan dan peluang dari masyarakat sehubungan dengan ide
              pembangunan desa;
            </li>
            <li>
              Memastikan keterwakilan unsur masyarakat dalam pengumpulan data
              STRIVE;
            </li>
            <li>
              Mengelola hasil analisis STRIVE agar digunakan dalam proses
              perencanaan pembangunan desa;
            </li>
            <li>
              Menindaklanjuti hasil analisis STRIVE ke dalam penyusunan RKP Desa
              atau RPJM Desa;
            </li>
          </ol>
          <h3>Kader Desa</h3>
          <ol>
            <li>
              Berkoordinasi dengan Tenaga Pendamping Profesional (TPP P3MD) dan
              Kader Pemberdayaan Masyarakat Desa dalam pengumpulan data
              kebutuhan, tantangan dan peluang pembangunan desa dari masyarakat;
            </li>
            <li>
              Berkoordinasi dengan Tim Penyusun RKP Desa atau RPJM Desa dan BPD
              terkait pembagian tugas pengumpulan data survey dan data pendukung
              lain yang mendukung penerapan SIP;
            </li>
            <li>
              Bertanggung jawab atas data yang diinput oleh Admin Desa agar
              sesuai dengan konteks yang diperlukan dan tidak mengandung unsur
              SARA;
            </li>
            <li>
              Mengkoordinir pada level desa dalam penggunaan aplikasi STRIVE;
            </li>
            <li>Menyiapkan pendata untuk mengumpulkan data lapangan;</li>
            <li>
              Melakukan pengecekan terhadap seluruh data input pada aplikasi
              STRIVE;
            </li>
            <li>
              Mencatat hasil analisis STRIVE dan tindak lanjutnya dalam
              penerapan SIP pada proses perencanaan pembangunan;
            </li>
            <li>
              Melaporkan penggunaan aplikasi STRIVE dan tindak lanjut hasil
              analisis STRIVE dalam penerapan SIP pada proses perencanaan
              pembangunan desa kepada Fasilitator Kecamatan TEKAD;
            </li>
            <li>Melaksanakan tugas dan fungsi sebagai Admin Desa;</li>
          </ol>
          <h3>Tugas dan Fungsi pengelolaan STRIVE tingkat Kecamatan</h3>
          <h3>Fasilitator Kecamatan</h3>
          <ol>
            <li>
              Melakukan monitoring terhadap penggunaan aplikasi STRIVE dalam
              penerapan SIP pada proses perencanaan pembangunan desa;
            </li>
            <li>
              Memberikan masukan dan penyelesaian masalah terhadap penggunaan
              aplikasi STRIVE dalam penerapan SIP pada proses perencanaan
              pembangunan desa;
            </li>
            <li>
              Melakukan rekapitulasi data hasil analisis STRIVE dan tindak
              lanjut hasil analisis STRIVE dalam penerapan SIP pada proses
              perencanaan pembangunan desa;
            </li>
            <li>
              Melaporkan penggunaan aplikasi STRIVE dan tindak lanjut hasil
              analisis STRIVE dalam penerapan SIP pada proses perencanaan
              pembangunan desa kepada Fasilitator Kabupaten TEKAD;
            </li>
          </ol>
          <h3>Tugas dan Fungsi pengelolaan STRIVE tingkat Kabupaten</h3>
          <h3>Pemerintah Kabupaten (DPMD)</h3>
          <ol>
            <li>
              Melakukan monitoring terhadap penggunaan aplikasi STRIVE dalam
              penerapan SIP pada proses perencanaan pembangunan desa;
            </li>
            <li>
              Melakukan monitoring terhadap rekapitulasi data hasil analisis
              STRIVE dan tindak lanjut hasil analisis STRIVE dalam penerapan SIP
              pada proses perencanaan pembangunan desa;
            </li>
          </ol>
          <h3>Fasilitator Kabupaten</h3>
          <ol>
            <li>
              Melakukan monitoring terhadap penggunaan aplikasi STRIVE dalam
              penerapan SIP pada proses perencanaan pembangunan desa;
            </li>
            <li>
              Melakukan rekapitulasi data hasil analisis STRIVE dan tindak
              lanjut hasil analisis STRIVE dalam penerapan SIP pada proses
              perencanaan pembangunan desa;
            </li>
            <li>
              Memberikan masukan dan penyelesaian masalah terhadap penggunaan
              aplikasi STRIVE dalam penerapan SIP pada proses perencanaan
              pembangunan desa;
            </li>
            <li>
              Melaporkan penggunaan aplikasi STRIVE dan tindak lanjut hasil
              analisis STRIVE dalam penerapan SIP pada proses perencanaan
              pembangunan desa kepada TPK dan NPMU;
            </li>
          </ol>
          <h3>Tugas dan Fungsi pengelolaan STRIVE tingkat Pusat</h3>
          <h3>Kementerian Desa</h3>
          <ol>
            <li>Menyediakan infrastruktur aplikasi STRIVE;</li>
            <li>
              Mendaftarkan dan mengelola akun admin STRIVE di tingkat Kabupaten
              dan tingkat Desa;
            </li>
            <li>
              Menyelesaikan kendala-kendala teknis yang terjadi terhadap
              aplikasi STRIVE;
            </li>
            <li>
              Memberikan pelatihan penggunaan STRIVE di tingkat Kabupaten dan
              tingkat Desa
            </li>
            <li>Mengelola data dan informasi penggunaan STRIVE.</li>
          </ol>
        </Content>
        <Line />
        <FlexWrapper>
          <FlexCol></FlexCol>
          <div className="v-line"></div>
        </FlexWrapper>
      </Cards>
      <Cards className="key-trend">
        <h1 className="card-heading">Bagaimana Manajemen pengguna STRIVE?</h1>
        <Content>
          <h3>Akun Desa</h3>
          <ol>
            <li>
              Akun Desa didaftarkan oleh pemerintah pusat dan masing-masing desa
              diberikan 1 (satu) akun; Akun Desa dapat digunakan secara
              bersamaan oleh 4 (empat) pengguna/admin yang ditunjuk oleh Kepala
              Desa;
            </li>
            <li>
              Akun Desa diberikan akses untuk mengelola akun tersebut, membuat
              manajemen kategori, dan mengelola data;
            </li>
            <li>
              Akun Desa dapat menginput data dan melihat dashboard hasil
              analisis untuk Desa yang bersangkutan;
            </li>
          </ol>
          <div>Definisi Admin Desa:</div>
          <div>
            Admin Desa meliputi Kader Desa Program TEKAD sebagai koordinator
            admin, dan personil lain seperti operator Desa, Tim Penyusun RKP
            Desa atau RPJM Desa, BPD, atau personil lainnya yang ditunjuk oleh
            Kepala Desa dan dinilai dapat mengoperasikan aplikasi, serta dapat
            berperan dalam penerapan Social Innovation Platform (SIP) pada
            proses perencanaan pembangunan desa.
          </div>
          <h3>Akun Kabupaten</h3>
          <ol>
            <li>
              Akun Kabupaten didaftarkan oleh pemerintah pusat dan masing-masing
              kabupaten diberikan 1 (satu) akun;
            </li>
            <li>
              Akun Kabupaten hanya dapat digunakan oleh 1 (satu) pengguna/admin
              yang ditunjuk oleh TPK;
            </li>
            <li>
              Akun Kabupaten hanya diberikan akses untuk melihat dashboard hasil
              analisis STRIVE di Desa yang masuk dalam Kabupaten tersebut;
            </li>
          </ol>
          <div>Definisi Admin Kabupaten</div>
          <div>
            Admin Kabupaten diwakili oleh TPK, Koordinator Komponen I Program
            TEKAD
          </div>
        </Content>
        <Line />
        <FlexWrapper>
          <FlexCol></FlexCol>
          <div className="v-line"></div>
        </FlexWrapper>
      </Cards>
      <Cards className="key-trend">
        <h1 className="card-heading">Bagaimana cara menggunakan STRIVE?</h1>
        <Content>
          <ol className="alphabet-list">
            <li>
              Manual book (Bahasa Indonesia) - pdf format which able to be
              downloaded
            </li>
            <li>Video tutorial of STRIVE: B4B to provide the video link. </li>
          </ol>
        </Content>
        <Line />
        <FlexWrapper>
          <FlexCol></FlexCol>
          <div className="v-line"></div>
        </FlexWrapper>
      </Cards>
      <Cards className="key-trend">
        <h1 className="card-heading">
          Apa saja kendala yang sering ditemui oleh pengguna STRIVE?
        </h1>
        <Content>
          <ul>
            <li>Pengguna tidak mengingat kata sandi akun STRIVE</li>
            <ol>
              <li>
                Halaman beranda akan memiliki opsi untuk mengklik tautan 'Lupa
                Kata Sandi' yang mengarahkan admin untuk login dengan kredensial
                baru
              </li>
              <li>
                Pengguna akan dapat memasukkan alamat email yang terdaftar di
                kotak teks
              </li>
              <li>
                Mengklik 'Kirim saya Reset Link' akan membuat sistem mengirim
                email pengguna dengan instruksi untuk mereset kata sandi ke
                alamat email yang terdaftar.
              </li>
            </ol>
            <li>
              Pengguna tidak dapat menemukan Desa yang diinginkan di STRIVE
            </li>
            <ol>
              <li>
                Kepala Desa mendaftarkan akun desa ke Kementerian Desa melalui
                Surat Pernyataan.
              </li>
              <li>
                Kementerian Desa mendaftarkan dan mengelola akun admin STRIVE di
                tingkat Kabupaten dan tingkat Desa;
              </li>
            </ol>
            <li>Pengguna tidak dapat mengisi survei STRIVE</li>
            <ol>
              <li>
                STRIVE menggunakan aplikasi Kobocollect sebagai alat input
                survei STRIVE. Applikasi handphone ini dapat diunduh di
                <div>
                  <a href="https://play.google.com/store/search?q=kobocollect&c=apps" target="_blank">
                    https://play.google.com/store/search?q=kobocollect&c=apps
                  </a>
                </div>
              </li>
            </ol>
            <li>Pengguna tidak dapat melihat hasil survey di dashboard</li>
            <ol>
              <li>
                STRIVE menggunakan Kobocollect sebagai alat input survey STRIVE,
                apabila survey tidak dapat tersambung ke STRIVE, user dapat
                mengkomunikasikan hal tersebut kepada admin untuk memastikan
                RESTServices pada form Kobocollect telah sesuai.{" "}
              </li>
              <li>
                Data input kobocollect di perangkat mobile dapat dilakukan
                dengan ketiadaan sinyal internet. Perhatian bagi admin desa
                untuk saat mengupload data input survei ke STRIVE membutuhkan
                sinyal internet.
              </li>
            </ol>
            <li>Pengguna tidak dapat mengunggah data atau berkas di STRIVE</li>
            <ol>
              <li>
                Saat proses unggah data atau berkas ke aplikasi STRIVE
                membutuhkan koneksi internet yang cukup.
              </li>
              <li>
                Perhatian jenis data atau berkas yang di unduh, STRIVE mampu
                memproses data dengan tipe sebagai berikut : PNG, JPG, WAV, MP3,
                MP4, XLS
              </li>
            </ol>
            <li>Chat Obrolan AI di STRIVE tidak memberikan response</li>
            <ol>
              <li>
                Chatbox AI di STRIVE dirancang dengan data yang sudah ada desa,
                sebagai data primer rujukan.
              </li>
              <li>
                Perhatikan jenis prompt atau pertanyaan yang digunakan di
                Chatbox. Prompt atau pertanyaan di Chatbox dibatasi untuk
                kategori terkait dengan pembangunan desa, permasalahan di desa,
                tantangan di desa, dan solusi pembangunan desa.
              </li>
            </ol>
            <li>
              Pengguna kesulitan untuk membuat keywords saat mengunggah data
              atau berkas.
            </li>
            <ol>
              <li>
                Setiap data atau berkas yang diunggah perlu ditambahkan
                keywords, sebagai cara STRIVE untuk melakukan kategorisasi
                terhadap data dan berkas.{" "}
              </li>
              <li>
                Perhatikan untuk selalu menekan tombol enter setelah selesai
                mengetik keywords saat mengunggah data atau berkas.
              </li>
            </ol>
            <li>Dashboard tidak dapat memunculkan data.</li>
            <ol>
              <li>
                Saat proses loading data di dashboard STRIVE membutuhkan koneksi
                internet yang memadai.
              </li>
              <li>
                Pastikan data atau berkas sudah terinput baik itu survey atau
                berkas lainnya.
              </li>
              <li>
                Pengguna bisa melakukan muat ulang/refresh pada halaman
                dashboard STRIVE.
              </li>
            </ol>
            <li>
              Bagaimana cara merubah data apabila terdapat kesalahan dalam input
              data STRIVE?
            </li>
            <ol>
              <li>Pengguna dapat membuka menu ‘Kelola File’.</li>
              <li>
                Berkas dalam tampilan grid, pengguna bisa memilih data yang
                salah kemudian mengklik tanda titik tiga dan memilih pilihan
                ‘hapus’.
              </li>
              <li>
                Berkas dalam tampilan list, pengguna bisa memilih data yang
                salah kemudian di kolom tindakan pengguna dapat mengklik tanda
                titik tiga dan memilih pilihan ‘hapus’.
              </li>
            </ol>
            <li>Apa yang harus dilakukan apabila website error?</li>
            <ol>
              <li>
                Apabila website error pada saat melakukan penginputan, dapat
                segera diinformasikan kepada Tim melalui
                <span>
                  yoshuaadolf@kemendesa.go.id pti.pusdatin@kemendesa.go.id
                </span>
              </li>
            </ol>
          </ul>
        </Content>
        <Line />
        <FlexWrapper>
          <FlexCol></FlexCol>
          <div className="v-line"></div>
        </FlexWrapper>
      </Cards>
    </Wrapper>
  );
}

export default FaqDetailsComponent;
