import { Dialog } from "primereact/dialog";
import styled from "styled-components";
import IconsBox from "../../../../components/ui-components/iconbox";
import AudioPlayer from "./audio-player";
import ExcelSheet from "./excel-sheet";
import VideoPlayer from "./video-player";
import React from "react";
import { bahasa, english } from "../text";
import { LanguageContext } from "../../../../store/language-context";

const InnerWrapper = styled.div`
  max-width: 83.2rem;
  width: 100%;
  max-height: 64.3rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 1rem 0;
  .file-image {
    object-fit: contain;
    width: 100%;
    max-width: 100%;
    max-height: 40rem;
  }
`;
const Content = styled.div`
  position: relative;
  width: 60vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2.3rem 0 0 0;
  .line {
    position: absolute;
    width: 1px;
    left: 50%;
    height: 100%;
    background-color: var(--gray_350);
  }
`;
const Box1 = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.8rem;
  .details-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: -0.5rem 0 0 0;
    .file--title {
      width: max(12rem, 80%);
      word-wrap: break-word;
      color: var(--black_900);
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.02em;
      text-align: left;
    }
    .file-size {
      color: var(--gray_500);
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: -0.02em;
      text-align: left;
      margin: 0.6rem 0 1.2rem 0;
    }
    .date {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0.2rem;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: left;
      color: var(--gray_800);
      span {
        color: var(--black_900);
        font-weight: 500;
        letter-spacing: -0.02em;
      }
    }
    .tag {
      gap: 0.2rem;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: left;
      color: var(--gray_800);
      margin-top: 10px;
      span {
        color: var(--black_900);
        font-weight: 500;
        letter-spacing: -0.02em;
        img {
          width: 15px;
          display: inline-block;
          vertical-align: top;
          margin-top: 1px;
          cursor: pointer;
        }
      }
      div {
        margin-top: 7px;
      }
    }
  }
`;
const Box2 = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 3rem;
  padding: 0 0 0 2rem;
  .cat-tag {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.6rem;
    p {
      color: var(--gray_800);
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 15px;
      letter-spacing: 0em;
      text-align: left;
    }
    h3 {
      color: var(--black_900);
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: -0.02em;
      text-align: left;
      overflow: auto;
      max-height: 100px;
    }
  }
`;

interface Iprops {
  showModal: boolean;
  onHideModal: any;
  type: string;
  data?: any;
}
const FileView = ({ showModal, onHideModal, data }: Iprops) => {
  const { isEnglish } = React.useContext(LanguageContext);
  const { dateCreatedText, categoryText, keywordsText, geoTag } = isEnglish
    ? english
    : bahasa;
  const headerTemplate = (
    <InnerWrapper>
      {data?.file_format === "image" ? (
        <img className="file-image" src={data?.file_url} alt="test" />
      ) : data?.file_format === "audio" ? (
        <AudioPlayer src={data?.file_url} />
      ) : data?.file_format === "excel" ? (
        <ExcelSheet src={data?.file_url} />
      ) : data?.file_format === "video" ? (
        <VideoPlayer src={data?.file_url} />
      ) : null}
    </InnerWrapper>
  );
  const footerTemplate = (
    <Content>
      <Box1>
        <IconsBox name={data?.file_format} />
        <div className="details-box">
          <h2 className="file--title">{data?.file_name}</h2>
          <span className="file-size">{data?.file_size}</span>
          <p className="date">
            {dateCreatedText}:<span>{data?.created_at}</span>
          </p>
          <div className="tag">
            <span>
              {geoTag}{" "}
              {!data?.latitude && !data?.longitude && (
                <img
                  src="/images/info.png"
                  alt="info"
                  title="Geotag information unavailable"
                />
              )}
            </span>
            {data?.latitude && (
              <div>
                Latitude:<span>{data?.latitude}</span>
              </div>
            )}
            {data?.longitude && (
              <div>
                Longitude:<span>{data?.longitude}</span>
              </div>
            )}
          </div>
        </div>
      </Box1>

      <div className="line"></div>

      <Box2>
        <div className="cat-tag">
          <p>{categoryText}</p>
          <h3>{data?.category_name || "NA"}</h3>
        </div>
        <div className="cat-tag">
          <p>{keywordsText}</p>
          <h3>{data?.keywords || "NA"}</h3>
        </div>
      </Box2>
    </Content>
  );

  return (
    <Dialog
      header={headerTemplate}
      visible={showModal}
      onHide={onHideModal}
      draggable={false}
      style={{
        backgroundColor: "var(--white_900)",
        padding: "0rem 4rem 6rem 4rem",
        borderRadius: "1rem",
        border: "1px solid var(--gray_100)",
      }}
      closeOnEscape={true}
      maskStyle={{
        backgroundColor: "var(--black_050)",
      }}
      footer={footerTemplate}
    ></Dialog>
  );
};

export default FileView;
