import React, { useEffect, useState } from "react";
import styled from "styled-components";
import RightCard from "./components/right-card";
import LeftCard from "./components/left-card";
import { useReactToPrint } from "react-to-print";
import { Tooltip } from "primereact/tooltip";
import { bahasa, english } from "./components/text";
import { LanguageContext } from "../../store/language-context";
import {
  useFetchKeyStats,
  useFetchLatLon,
} from "../../api/user-api/dashboard-api";
import IconsBox from "../../components/ui-components/iconbox";
import TopCard from "./components/top-card";
import { UserContext } from "../../store/user-context";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0rem;
`;
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 1.2rem;
  @media screen and (max-width: 1198px) {
    flex-wrap: wrap;
  }
`;
const PrintWrapper = styled.div`
  width: 92%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  .print-btn {
    cursor: pointer;
    border-radius: 5rem;
    padding: 0.5rem;
    background-color: var(--blue_500);
    transition: all 0.3s linear;
    :hover {
      background-color: var(--white_900);
      svg {
        fill: var(--blue_500);
      }
    }
  }
`;

const Dashboard = () => {
  const [showPrint, setShowPrint] = useState(false);
  const [middleLoad, setMiddleLoad] = useState(false);
  const [bottomLoad, setBottomLoad] = useState(false);

  const languageCtx = React.useContext(LanguageContext);
  const { setFormData, formData, setPrevState, prevState } =
    React.useContext(UserContext);
  const { printText } = languageCtx.isEnglish ? english : bahasa;

  const componentRef = React.useRef<any>();

  // -------------------- GET Lat lon -------------------------
  const { data: latAndlon, isFetching: mapLoading } = useFetchLatLon(
    formData.s3
  );

  // -------------------- GET Key stats Data -------------------------
  const { data: keyStatsData, isLoading: keyStatsIsLoading } = useFetchKeyStats(
    formData.s3
  );

  // Print component
  const handleToPrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Strive Dashboard",
    // onAfterPrint: () => alert("Printed"),
  });

  useEffect(() => {
    if (middleLoad && bottomLoad) {
      console.log("loaded");
      setShowPrint(true);
    } else {
      console.log("in progress");
      setShowPrint(false);
    }
  }, [bottomLoad, middleLoad]);

  return (
    <>
      {showPrint && (
        <PrintWrapper>
          <Tooltip target=".print-btn">{printText}</Tooltip>
          <span className="print-btn" onClick={handleToPrint}>
            <IconsBox name="print" />
          </span>
        </PrintWrapper>
      )}
      <Container ref={componentRef}>
        <TopCard
          formData={formData}
          onSelect={setFormData}
          prevState={prevState}
          onPrevState={setPrevState}
          isEnglish={languageCtx.isEnglish}
        />
        <Wrapper>
          <LeftCard
            formData={formData}
            keyStatsIsLoading={keyStatsIsLoading}
            keyStatsData={keyStatsData}
            loaded={(val: any) => {
              setMiddleLoad(val);
            }}
            loaded2={(val: any) => {
              setBottomLoad(val);
            }}
          />
          <RightCard
            latlon={latAndlon}
            placeName={formData}
            mapLoading={mapLoading}
          />
        </Wrapper>
      </Container>
    </>
  );
};

export default Dashboard;
