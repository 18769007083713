import { MANAGE_FILES_SUB_PATHS } from "../../all-routes/paths";
import styled from "styled-components";
import { Route, Routes } from "react-router-dom";
import React, { useState } from "react";
import { LanguageContext } from "../../store/language-context";
import DemoDetailsComponent from "./components/content";
import VideoDetailsComponent from "./components/video";

// Wrapper for the file management content
const ManageFilesWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

// Container for the entire Tabs component
const TabsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

// Style for each Tab button
const TabButton = styled.button<{ isActive: boolean }>`
    padding: 10px 20px;
    margin-right: 5px;
    border: none;
    cursor: pointer;
    background-color: ${({ isActive }) => (isActive ? "#ddd" : "#f1f1f1")};
    font-size: 16px;
    font-weight: ${({ isActive }) => (isActive ? "bold" : "normal")};

    &:hover {
        background-color: #ddd;
    }
`;

// Style for the Tab content
const TabContent = styled.div`
    margin-top: 20px;
`;

function Demo() {
    const languageCtx = React.useContext(LanguageContext);
    const [activeTab, setActiveTab] = useState("tab1");

    const handleTabClick = (tab: React.SetStateAction<string>) => {
        setActiveTab(tab);
    };

    return (
        <TabsContainer>
            <div>
                <TabButton
                    isActive={activeTab === "tab1"}
                    onClick={() => handleTabClick("tab1")}
                >
                    Manual
                </TabButton>
                <TabButton
                    isActive={activeTab === "tab2"}
                    onClick={() => handleTabClick("tab2")}
                >
                    Video
                </TabButton>
            </div>

            <TabContent>
                {activeTab === "tab1" && (
                    <ManageFilesWrapper>
                        <Routes>
                            <Route
                                path={MANAGE_FILES_SUB_PATHS.MANAGE_FILES_HOME}
                                element={
                                    <DemoDetailsComponent isEnglish={languageCtx.isEnglish} />
                                }
                            />
                        </Routes>
                    </ManageFilesWrapper>
                )}
                {activeTab === "tab2" && (
                    <ManageFilesWrapper>
                        <Routes>
                            <Route
                                path={MANAGE_FILES_SUB_PATHS.MANAGE_FILES_HOME}
                                element={
                                    <VideoDetailsComponent isEnglish={languageCtx.isEnglish} />
                                }
                            />
                        </Routes>
                    </ManageFilesWrapper>
                )}
            </TabContent>
        </TabsContainer>
    );
}

export default Demo;
