import React from "react";
import SetPasswordComponent from "../change-password/components/change-password";
import { bahasa, english } from "./components/text";
import { LanguageContext } from "../../store/language-context";

function SetPassword() {
  const { isEnglish } = React.useContext(LanguageContext);
  const { heading } = isEnglish ? english : bahasa;

  return <SetPasswordComponent heading={heading} isEnglish={isEnglish} />;
}

export default SetPassword;
