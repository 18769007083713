import React from "react";
import styled from "styled-components";
import Persona from "./sub-components/Perosna";
import { LanguageContext } from "../../../store/language-context";
import VillageCard from "./sub-components/village-card";
import Spinner from "../../../components/ui-components/spinner";

const Container = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  gap: 1.2rem;
  @media screen and (max-width: 1198px) {
    flex-wrap: wrap;
    width: 100%;
  }
`;
interface IProps {
  keyStatsData: any;
  keyStatsIsLoading: boolean;
  formData: any;
  loaded?: (val: any) => void;
  loaded2?: (val: any) => void;
}
const LeftCard = ({
  keyStatsData,
  keyStatsIsLoading,
  formData,
  loaded,
  loaded2,
}: IProps) => {
  const languageCtx = React.useContext(LanguageContext);

  return (
    <Container>
      {keyStatsIsLoading ? (
        <Spinner />
      ) : (
        <VillageCard
          isEnglish={languageCtx.isEnglish}
          keyStatisticsData={keyStatsData}
          formData={formData}
          loaded={loaded}
        />
      )}
      <Persona
        isEnglish={languageCtx.isEnglish}
        dashboardData={keyStatsData}
        placeName={formData.s3}
        loaded2={loaded2}
      />
    </Container>
  );
};

export default LeftCard;
