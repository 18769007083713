import apiCall from "..";
import { useQuery } from "react-query";
import { APP_ENV } from "../config";

// get provinces
const fetchPlaces = ({ queryKey }: any) => {
  return apiCall.get(`${APP_ENV.BE_AI_BASE_URL}/listData?type=province`);
};
const useFetchPlaces = (type: any) => {
  return useQuery(["list-places", type], fetchPlaces, {
    select: (data: any) => {
      return data?.data?.data?.map((items: any) => ({
        value: items,
        label: items.toUpperCase(),
      }));
    },
    refetchOnWindowFocus: false,
  });
};

// get district
const fetchDistrict = ({ queryKey }: any) => {
  if (!queryKey[1]) return;

  return apiCall.get(
    `${APP_ENV.BE_AI_BASE_URL}/listData?type=district&data=${queryKey[1]}`
  );
};
const useFetchDistrict = (district: any) => {
  return useQuery(["list-district", district], fetchDistrict, {
    select: (data: any) => {
      return data?.data?.data?.map((items: any) => ({
        value: items,
        label: items.toUpperCase(),
      }));
    },
    refetchOnWindowFocus: false,
  });
};

// get village
const fetchVillage = ({ queryKey }: any) => {
  if (!queryKey[1]) return;
  return apiCall.get(
    `${APP_ENV.BE_AI_BASE_URL}/listData?type=village&data=${queryKey[1]}`
  );
};
const useFetchVillage = (village: any) => {
  return useQuery(["list-village", village], fetchVillage, {
    select: (data: any) => {
      return data?.data?.data?.map((items: any) => ({
        value: items,
        label: items.toUpperCase(),
      }));
    },
    refetchOnWindowFocus: false,
  });
};

// get latitude & longitude
const fetchLatLon = ({ queryKey }: any) => {
  if (!queryKey[1]) return;
  return apiCall.get(
    `${APP_ENV.BE_AI_BASE_URL}/listData?type=latlong&data=${queryKey[1]}`
  );
};
const useFetchLatLon = (village: any) => {
  return useQuery(["lat-lon", village], fetchLatLon, {
    select: (data: any) => {
      return data?.data?.data;
    },
    refetchOnWindowFocus: false,
  });
};

// get key stats
const fetchKeyStats = ({ queryKey }: any) => {
  if (!queryKey[1]) return;
  return apiCall.get(
    `${APP_ENV.BASE_URL}/survey/forms_list?village=${queryKey[1]}`
  );
};
const useFetchKeyStats = (village: any) => {
  return useQuery(["key-stats", village], fetchKeyStats, {
    select: (data: any) => {
      return data?.data;
    },
    refetchOnWindowFocus: false,
  });
};

const translateDataToBahasa = async (data: any) => {
  if (Object.keys(data).length == 2 && data.opportunities && !data.opportunities.length && data.challenges && !data.challenges.length) {
    return []
  }
  if (Object.keys(data).length == 3 && data.opportunities && !data.opportunities.length && data.challenges && !data.challenges.length) {
    return []
  }
  const payload = {
    data,
  };
  try {
    const response = await apiCall.post(
      `${APP_ENV.BE_UPDATED_AI_BASE_URL}/translate`,
      payload
    );
    let jsonData;
    try {
      jsonData = JSON.parse(response?.data?.data);
    }
    catch (e) {
      jsonData = response?.data?.data;
    }
    return jsonData;
  } catch (error: any) { console.log(error) }
};

// Get persona data
const getPBDate = async ({ queryKey }: any) => {
  const { disability, gender, income, indigenous, work } = queryKey[1];
  const updatedincome = income.replace(/IDR /g, "").replace(/,/g, "").replace(/ to /g, "-");
  // const updatedAge = age.replace(/ to /g, "-");

  const payload = {
    village: queryKey[2],
    // age_group: updatedAge,
    disability: disability == 'yes' ? true : false,
    indigenous: indigenous == 'yes' ? true : false,
    gender: gender,
    income: updatedincome,
    employment_status: work,
    // age_min: 0,
    // age_max: 100,
    // income_min: 1000,
    // income_max: 1200000000,
  };

  const data: any = await apiCall.post(
    `${APP_ENV.BE_UPDATED_AI_BASE_URL}/query_updated`,
    payload
  );
  const response: any = data?.data?.data;
  if (queryKey[3]) {
    return {
      person_name: response?.person_name ?? "-",
      persona_bio: response?.persona_bio ?? "-",
      person_occupation: response?.person_occupation ?? "-",
      challenges: response?.challenges,
      opportunities: response?.opportunities,
      user_persona: response?.user_persona
    };
  } else {
    return await translateDataToBahasa(response);
  }
};
const usePersonBasedData = (
  persona: any,
  village: any,
  enabled: boolean,
  isEnglish: boolean
) => {
  return useQuery(
    ["person-based-data", persona, village, isEnglish],
    getPBDate,
    {
      enabled: enabled,
      refetchOnWindowFocus: false,
    }
  );
};
// Get KeyTrends
const getKeyTrends = async ({ queryKey }: any) => {
  if (!queryKey[1]) {
    return;
  }
  let payload: any = {
    village: queryKey[1],
  };
  if (queryKey[3]) {
    payload['type'] = queryKey[3];
  }
  const data: any = await apiCall.post(
    `${APP_ENV.BE_UPDATED_AI_BASE_URL}/query_updated`,
    payload
  );
  const response: any = data?.data?.data;
  if (queryKey[2]) {
    return data?.data?.data;
  } else {
    return await translateDataToBahasa(response);
  }
};
const useKeyTrendsData = (village: any, isEnglish?: boolean, type?: string) => {
  return useQuery(["key-trends", village, isEnglish, type], getKeyTrends, {
    refetchOnWindowFocus: false,
  });
};


const getKeyTrendsFiles = async ({ queryKey }: any) => {
  if (!queryKey[1]) {
    return;
  }
  let payload: any = {
    village: queryKey[1],
  };
  if (queryKey[3]) {
    payload['type'] = queryKey[3];
  }
  const data: any = await apiCall.post(
    `${APP_ENV.BE_UPDATED_AI_BASE_URL}/challenges_opportunities_managed_files`,
    payload
  );
  const response: any = data?.data?.data;
  if (queryKey[2]) {
    return data?.data?.data;
  } else {
    return await translateDataToBahasa(response);
  }
};

const useKeyTrendsDataFiles = (village: any, isEnglish?: boolean, type?: string, enabled?: boolean) => {
  return useQuery(["key-trends", village, isEnglish, type], getKeyTrendsFiles, {
    enabled: enabled,
    refetchOnWindowFocus: false,
  });
};

// Chat
const chatBot = ({ queryKey }: any) => {
  if (!queryKey[1]) {
    return;
  }
  let payload: any = {
    query: queryKey[1],
  };
  if (queryKey[2]) {
    payload['village'] = queryKey[2]

  }
  return apiCall.post(`${APP_ENV.BE_AI_BASE_URL}/query`, payload);
};
const useChatBot = (query: any, village: string) => {
  return useQuery(["chat-bot", query, village], chatBot, {
    select: (data: any) => {
      return { message: data?.data?.data, user: "bot" };
    },
    refetchOnWindowFocus: false,
  });
};

export {
  useFetchPlaces,
  useFetchDistrict,
  useFetchVillage,
  useFetchLatLon,
  useFetchKeyStats,
  useChatBot,
  usePersonBasedData,
  useKeyTrendsData,
  useKeyTrendsDataFiles
};
