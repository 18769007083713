import React from "react";
import styled from "styled-components";
import ReCAPTCHA from "react-google-recaptcha";

interface SimpleCaptchaProps {
  onVerify: (isVerified: boolean) => void;
}

const CaptchaWrapper = styled.div`
  margin-bottom: 20px;
  border-radius: 5px;
  label {
    font-size: 1.5rem;
  }
`;

const SimpleCaptcha: React.FC<SimpleCaptchaProps> = ({ onVerify }) => {
  const handleCaptchaChange = (value: string | null) => {
    if (value) {
      onVerify(true);
    } else {
      onVerify(false);
    }
  };

  return (
    <CaptchaWrapper>
      <ReCAPTCHA
        sitekey="6Ld9GSIqAAAAAMnnlRnFVIKWA4NW45HuGUvpj0JV"
        onChange={handleCaptchaChange}
      />
    </CaptchaWrapper>
  );
};

export default SimpleCaptcha;
