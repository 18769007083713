import { Dialog } from "primereact/dialog";
import styled from "styled-components";
import { ButtonOutlined } from "../../common-styles";
import { useNavigate } from "react-router-dom";

const InnerWrapper = styled.div`
  width: max(42.8rem, 428px);
  max-height: 36.7rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 1rem 0;
`;
const Heading = styled.h1`
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
`;
export const ModalContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2.8rem 0 0 0;
  img {
    width: 12.8rem;
    height: 9.6rem;
  }
  p {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    margin: 2.4rem 0 2rem 0;
  }
`;

const Button = styled(ButtonOutlined)`
  width: fit-content;
  padding: 2rem;
`;

interface Iprops {
  showModal: boolean;
  onHideModal: any;
  btnText: string;
  heading: string;
  content: string;
  path: string;
}

const SuccessModal = ({
  showModal,
  onHideModal,
  heading,
  content,
  btnText,
  path,
}: Iprops) => {
  const navigate = useNavigate();

  return (
    <Dialog
      visible={showModal}
      onHide={onHideModal}
      style={{
        backgroundColor: "var(--white_900)",
        padding: "4rem 4rem 6rem 4rem",
        borderRadius: "1rem",
        border: "1px solid var(--gray_100)",
      }}
      maskStyle={{
        backgroundColor: "var(--black_050)",
      }}
      header={<Heading>{heading}</Heading>}
    >
      <InnerWrapper>
        <ModalContent>
          <img src="/images/success-img.png" alt="success" />
          <p>{content}</p>
        </ModalContent>
        <Button onClick={() => navigate(path)}>{btnText}</Button>
      </InnerWrapper>
    </Dialog>
  );
};

export default SuccessModal;
