import React from "react";
import styled from "styled-components";
import { CancelButton, SaveButton, UserHeading } from "./edit-profile";
import { bahasa, english } from "./text";
import { useNavigate } from "react-router-dom";
import PasswordInput from "../../../components/ui-components/input/password-input";
import { OtherControls } from "../../change-password/components/change-password";
import IconsBox from "../../../components/ui-components/iconbox";
import SuccessModal from "../../../components/ui-components/success-modal";

import Toaster from "../../../components/ui-components/toaster";
import { LanguageContext } from "../../../store/language-context";
import { UserContext } from "../../../store/user-context";
import { useUpdatePassword } from "../../../api/user-api";
import { RegexPatterns } from "../../../variables/regex";
import { SETTINGS_SUB_PATHS } from "../../../all-routes/paths";

interface IStyles {
  isError?: string | null;
}

const ChangePasswordWrapper = styled.form`
  width: min(67.8rem, 100%);
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const InputsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
  border: 1px solid var(--gray_100);
  border-radius: 10px;
  padding: 3rem 0;
  .wrapper {
    width: 100%;
    max-width: 35.9rem;
  }
  label {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
  }
`;

const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.6rem;
  margin: 2rem 0;
`;

// none style
interface FormData {
  oldPassword: string;
  password1: string;
  password2: string;
}

interface FormErrors {
  oldPassword: string | null;
  password1: string | null;
  password2: string | null;
  isError: boolean;
}
interface PasswordRule {
  isUpperCase: boolean;
  isOneNumber: boolean;
  isSpecialChar: boolean;
  isMinLength: boolean;
}

const initialFormData: FormData = {
  oldPassword: "",
  password1: "",
  password2: "",
};

const initialFormErrors: FormErrors = {
  oldPassword: null,
  password1: null,
  password2: null,
  isError: false,
};

const initialPasswordRules: PasswordRule = {
  isUpperCase: false,
  isOneNumber: false,
  isSpecialChar: false,
  isMinLength: false,
};

function ChangePassword() {
  const languageCtx = React.useContext(LanguageContext);
  const {
    cancelBtn,
    setPasswordBtn,
    errorPasswordText1,
    errorPasswordText2,
    errorPasswordText3,
    errorPasswordText4,
    input3Label,
    input4Label,
    input5Label,
    inputPlaceHolder,
    passwordRules,
    passwordHint1,
    passwordHint2,
    passwordHint3,
    passwordHint4,
    modalHeader2,
    modalContent2,
    modalBtnText,
    passwordUpdatedText,
    passwordUpdateFailedText,
    pleaseLoginText,
  } = languageCtx.isEnglish ? english : bahasa;

  const { userData, logoutFn } = React.useContext(UserContext);
  const navigate = useNavigate();

  const onSuccess = (res: any) => {
    setShowToast({
      status: true,
      type: "success",
      message: passwordUpdatedText,
      reason: pleaseLoginText,
    });
    // setShowSuccessModal(true);
    setFormData(initialFormData);
    setFormErrors(initialFormErrors);
    setTimeout(() => logoutFn(), 1500);
  };
  const onError = (res: any) => {
    setShowToast({
      status: true,
      type: "error",
      message: passwordUpdateFailedText,
      reason: res?.response?.data?.message,
    });
  };
  const { mutate: updatePassword, isLoading } = useUpdatePassword(
    onSuccess,
    onError
  );
  const [formData, setFormData] = React.useState<FormData>(initialFormData);
  const [formErrors, setFormErrors] =
    React.useState<FormErrors>(initialFormErrors);
  const [rules, setRules] = React.useState<PasswordRule>(initialPasswordRules);
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);
  const [showToast, setShowToast] = React.useState({
    status: false,
    type: "",
    message: "",
    reason: "",
  });

  React.useEffect(() => {
    handlePasswordValidity(formData.password1);
  }, [formData.password1]);

  function handlePasswordValidity(password: string) {
    const pattern = RegexPatterns.password;
    const isValidPassword = pattern.test(password);

    const rules = {
      isUpperCase: RegexPatterns.uppercase.test(password),
      isOneNumber: RegexPatterns.number.test(password),
      isSpecialChar: RegexPatterns.spacialChar.test(password),
      isMinLength: RegexPatterns.minLength8.test(password),
    };

    setRules(rules);
    if (isValidPassword) {
      setRules({
        isUpperCase: true,
        isOneNumber: true,
        isSpecialChar: true,
        isMinLength: true,
      });
    }
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target;
    let isValue = value.slice(0, 15);
    setFormData((prevState) => ({ ...prevState, [name]: isValue }));
    setFormErrors((prevState) => ({ ...prevState, [name]: null }));
  }

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const errors: FormErrors = {
      oldPassword: null,
      password1: null,
      password2: null,
      isError: false,
    };
    let isValid = true;

    // Validate password
    if (!formData.oldPassword) {
      errors.oldPassword = errorPasswordText1;
      isValid = false;
    }
    if (!formData.password1) {
      errors.password1 = errorPasswordText1;
      isValid = false;
    }
    if (!formData.password2) {
      errors.password2 = errorPasswordText2;
      isValid = false;
    }

    if (!isValid) {
      setFormErrors(errors);
    } else {
      // Submit the form data here
      let key: keyof typeof rules;
      for (key in rules) {
        if (!rules[key]) {
          errors.password1 = errorPasswordText4;
          setFormErrors(errors);
          return;
        }
      }
      if (formData.password1 !== formData.password2) {
        errors.password2 = errorPasswordText3;
        setFormErrors(errors);
        return;
      }
      await updatePassword({
        user: userData.um_user_id,
        current_password: formData.oldPassword,
        new_password: formData.password1,
      });
      setShowToast({
        status: false,
        type: "",
        message: "",
        reason: "",
      });
    }
  }

  return (
    <>
      <Toaster
        showToast={showToast.status}
        severity={showToast.type}
        summary={showToast.message}
        detail={showToast.reason}
      />
      <ChangePasswordWrapper onSubmit={handleSubmit}>
        <UserHeading>
          <h2>{userData.name || ""}</h2>
          <p>{userData.email || ""}</p>
        </UserHeading>

        <InputsWrapper>
          <div className="wrapper">
            <PasswordInput
              isName="oldPassword"
              isLabel={input3Label}
              isValue={formData.oldPassword}
              onChange={handleInputChange}
              isPlaceholder={inputPlaceHolder}
              isError={formErrors.oldPassword}
            />
            <PasswordInput
              isName="password1"
              isLabel={input4Label}
              isValue={formData.password1}
              onChange={handleInputChange}
              isPlaceholder={inputPlaceHolder}
              isError={formErrors.password1}
            />
            <PasswordInput
              isName="password2"
              isLabel={input5Label}
              isValue={formData.password2}
              onChange={handleInputChange}
              isPlaceholder={inputPlaceHolder}
              isError={formErrors.password2}
            />
            <OtherControls>
              <p>{passwordRules}</p>
              {[
                {
                  iconName: rules.isUpperCase,
                  text: passwordHint1,
                },
                {
                  iconName: rules.isSpecialChar,
                  text: passwordHint2,
                },
                {
                  iconName: rules.isOneNumber,
                  text: passwordHint3,
                },
                {
                  iconName: rules.isMinLength,
                  text: passwordHint4,
                },
              ].map((item, i) => (
                <span key={i}>
                  {<IconsBox name={item.iconName ? "tick" : "round"} />}
                  <p className="p-1">{item.text}</p>
                </span>
              ))}
            </OtherControls>
          </div>
        </InputsWrapper>

        <ButtonBox>
          <CancelButton
            onClick={() => navigate(`${SETTINGS_SUB_PATHS.SETTINGS_BASE}`)}
          >
            {cancelBtn}
          </CancelButton>
          <SaveButton disabled={isLoading} type="submit">
            {setPasswordBtn}
          </SaveButton>
        </ButtonBox>
      </ChangePasswordWrapper>
      <SuccessModal
        showModal={showSuccessModal}
        onHideModal={() => setShowSuccessModal(false)}
        heading={modalHeader2}
        content={modalContent2}
        btnText={modalBtnText}
        path={SETTINGS_SUB_PATHS.SETTINGS_BASE}
      />
    </>
  );
}

export default ChangePassword;
