const english = {
  heading: "Oops! Something went wrong.",
  subHeading: "Please try again later.",
  btnText: "Back to",
};
const bahasa = {
  heading: "Oops! Something went wrong.",
  subHeading: "Please try again later.",
  btnText: "Back to",
};

export { english, bahasa };
