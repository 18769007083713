const english = {
  heading: "Strive",
  subHeading: "Platform",
  logoSideText:
    "Ministry of Village, Development of Disadvantaged Regions & Transmigration",
};
const bahasa = {
  heading: "Strive",
  subHeading: "Platform",
  logoSideText:
    "Kementerian Desa, Pembangunan Daerah Tertinggal, dan Transmigrasi Republik Indonesia",
};

export { english, bahasa };
