import React from "react";
import styled from "styled-components";
import {
  AuthFormWrapper,
  AuthWrapper,
  ErrorWrapper,
} from "../../../components/common-styles";
import { bahasa, english } from "./text";
import IconsBox from "../../../components/ui-components/iconbox";
import PasswordInput from "../../../components/ui-components/input/password-input";
import { AuthButton } from "../../user-login/components/login";
import { RegexPatterns } from "../../../variables/regex";
import { useLocation, useNavigate } from "react-router-dom";
import { AUTH_PATHS } from "../../../all-routes/paths";
import { useResetPassword } from "../../../api/user-api";
import Toaster from "../../../components/ui-components/toaster";

interface IStyles {
  isError?: string | null;
}

export const OtherControls = styled.div`
  width: 100%;
  display: flex;
  gap: 0.6rem;
  flex-direction: column;
  margin: 2rem 0 0 0;
  p {
    color: var(--bluish_gray);
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
  }
  span {
    display: flex;
    gap: 0.6rem;
    align-items: center;
    gap: 1rem;
    .p-2 {
      color: var(--bluish_gray);
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 0em;
    }
  }
`;

// none style
interface FormData {
  password1: string;
  password2: string;
}

interface FormErrors {
  password1: string | null;
  password2: string | null;
  isError: boolean;
  errorMessage: any;
}
interface PasswordRule {
  isUpperCase: boolean;
  isOneNumber: boolean;
  isSpecialChar: boolean;
  isMinLength: boolean;
}

const initialFormData: FormData = {
  password1: "",
  password2: "",
};

const initialFormErrors: FormErrors = {
  password1: null,
  password2: null,
  isError: false,
  errorMessage: "",
};

const initialPasswordRules: PasswordRule = {
  isUpperCase: false,
  isOneNumber: false,
  isSpecialChar: false,
  isMinLength: false,
};

interface IProps {
  heading: string;
  isEnglish:boolean;
}

function ChangePasswordComponent({ heading, isEnglish }: IProps) {
  const {
    subHeading,
    errorText,
    errorPasswordText1,
    errorPasswordText2,
    errorPasswordText3,
    input1Label,
    inputPlaceHolder,
    input2Label,
    buttonText,
    passwordRules,
    passwordHint1,
    passwordHint2,
    passwordHint3,
    passwordHint4,
    successPasswordText1,
    errorPasswordText4,
    successPasswordSubText1
  } = isEnglish ? english : bahasa;

  const { search } = useLocation();
  const navigate = useNavigate();

  const [formData, setFormData] = React.useState<FormData>(initialFormData);
  const [formErrors, setFormErrors] =
    React.useState<FormErrors>(initialFormErrors);
  const [rules, setRules] = React.useState<PasswordRule>(initialPasswordRules);
  const [showToast, setShowToast] = React.useState({
    status: false,
    type: "",
    message: "",
    reason: "",
  });

  const onSuccess = async (res: any) => {
    setShowToast({
      status: true,
      type: "success",
      message: successPasswordText1,
      reason: successPasswordSubText1,
    });
    setFormData(initialFormData);
    setFormErrors(initialFormErrors);
    setTimeout(() => navigate(AUTH_PATHS.LOGIN), 1500);
  };
  const onError = (res: any) => {
    setShowToast({
      status: true,
      type: "error",
      message: errorPasswordText4,
      reason: res?.response?.data?.message,
    });
  };
  const { mutate: resetPassword } = useResetPassword(onSuccess, onError);

  React.useEffect(() => {
    handlePasswordValidity(formData.password1);
  }, [formData.password1]);

  function handlePasswordValidity(password: string) {
    const pattern = RegexPatterns.password;
    const isValidPassword = pattern.test(password);

    const rules = {
      isUpperCase: RegexPatterns.uppercase.test(password),
      isOneNumber: RegexPatterns.number.test(password),
      isSpecialChar: RegexPatterns.spacialChar.test(password),
      isMinLength: RegexPatterns.minLength8.test(password),
    };

    setRules(rules);
    if (isValidPassword) {
      setRules({
        isUpperCase: true,
        isOneNumber: true,
        isSpecialChar: true,
        isMinLength: true,
      });
    }
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    setFormErrors((prevState) => ({
      ...prevState,
      isError: false,
      errorMessage: "",
      [name]: null,
    }));
  }

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const errors: FormErrors = {
      password1: null,
      password2: null,
      isError: false,
      errorMessage: "",
    };
    let isValid = true;

    // Validate password
    if (!formData.password1) {
      errors.password1 = errorPasswordText1;
      isValid = false;
    }
    if (!formData.password2) {
      errors.password2 = errorPasswordText2;
      isValid = false;
    }

    if (!isValid) {
      setFormErrors(errors);
    } else {
      // check password meeting the rules
      let key: keyof typeof rules;
      for (key in rules) {
        if (!rules[key]) {
          errors.isError = true;
          errors.errorMessage = errorPasswordText3;
          setFormErrors(errors);
          return;
        }
      }
      if (formData.password1 !== formData.password2) {
        errors.isError = true;
        errors.errorMessage = errorText;
        setFormErrors(errors);
        return;
      }

      // Submit the form data here
      const reset_code = search.split("code=")[1];
      await resetPassword({ reset_code, password: formData.password1 });
    }
    setShowToast({
      status: false,
      type: "",
      message: "",
      reason: "",
    });
  }

  return (
    <AuthWrapper>
      <Toaster
        showToast={showToast.status}
        severity={showToast.type}
        summary={showToast.message}
        detail={showToast.reason}
      />
      <h1 className="heading">{heading}</h1>
      <p className="sub-heading">{subHeading}</p>
      {formErrors.isError ? (
        <ErrorWrapper>
          <IconsBox name="doubt" />
          <p className="error-text error-text-css">{formErrors.errorMessage}</p>
        </ErrorWrapper>
      ) : (
        <></>
      )}

      <AuthFormWrapper onSubmit={handleSubmit}>
        <PasswordInput
          isName="password1"
          isLabel={input1Label}
          isValue={formData.password1}
          onChange={handleInputChange}
          isPlaceholder={inputPlaceHolder}
          isError={formErrors.password1}
        />
        <PasswordInput
          isName="password2"
          isLabel={input2Label}
          isValue={formData.password2}
          onChange={handleInputChange}
          isPlaceholder={inputPlaceHolder}
          isError={formErrors.password2}
        />
        <AuthButton type="submit">{buttonText}</AuthButton>
      </AuthFormWrapper>
      <OtherControls>
        <p>{passwordRules}</p>
        {[
          {
            iconName: rules.isUpperCase,
            text: passwordHint1,
          },
          {
            iconName: rules.isSpecialChar,
            text: passwordHint2,
          },
          {
            iconName: rules.isOneNumber,
            text: passwordHint3,
          },
          {
            iconName: rules.isMinLength,
            text: passwordHint4,
          },
        ].map((item, i) => (
          <span key={i}>
            {<IconsBox name={item.iconName ? "tick" : "round"} />}
            <p className="p-1">{item.text}</p>
          </span>
        ))}
      </OtherControls>
    </AuthWrapper>
  );
}

export default ChangePasswordComponent;
