const myPaths: any = {
  Settings: "Pengaturan",

  "Manage files": "Kelola File",
};

const mySubPaths: any = {
  "Edit profile": "Sunting profil",

  "Change password": "Ganti kata sandi",

  "Upload file": "Unggah data",
};

export { myPaths, mySubPaths };
