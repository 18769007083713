import React from "react";
import { useChatBot } from "../../../../api/user-api/dashboard-api";
import ChatRoom from "./sub-comp/chat-room";

// none style
interface IProps {
  isEnglish: boolean;
  village:string
}

const ChatBot = ({ isEnglish, village }: IProps) => {
  const [chat, setChat] = React.useState<any>("");
  const [isApiCall, setIsApiCall] = React.useState(false);

  const {
    data,
    isError,
    isLoading,
    refetch: chatWithBox,
  } = useChatBot(chat, village);

  const handleChat = (chatText: any) => {
    setChat(
      `${chatText} ${
        !isEnglish
          ? ".Give the response in Bahasa language"
          : ".Give the response in English language"
      } `
    );
    setIsApiCall(true);
    chatWithBox();
  };

  return (
    <ChatRoom
      isEnglish={isEnglish}
      onChat={handleChat}
      isChatLoading={isLoading}
      apiData={data}
    />
  );
};

export default ChatBot;
