import React from "react";

interface ITypes {
  width?: any;
  height?: any;
}
export default function useWindowSize() {
  const [windowSize, setWindowSize] = React.useState<ITypes>({
    width: undefined,
    height: undefined,
  });

  React.useEffect(() => {
    function changeWindowSize() {
      setWindowSize({ width: window?.innerWidth, height: window?.innerHeight });
    }
    changeWindowSize();
    window.addEventListener("resize", changeWindowSize);

    return () => {
      window.removeEventListener("resize", changeWindowSize);
    };
  }, []);

  return windowSize;
}
