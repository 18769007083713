const english = {
  heading: "Profile",
  subHeading: "User can view the profile and edit details",
  searchInputPlaceholder: "Search your file here",
  uploadBtnText: "Upload File",

  // upload file
  backbtnText: "Back",
  fileDesc: "Drop your file here, or",
  browse: " Browse",
  fileTypes: "Supports: PNG, JPEG, WAV, MP3, MP4, XLS Files type",
  uploadingText: "Uploading file",
  uploaded: "Uploaded",
  cancelText: "Cancel",
  selectTag1Label: "Select Category",
  selectTag1Error: "Please select category!!",
  selectTag2Label: "Add Keywords",
  selectTag2Error: "At least one keyword is required!",
  selectTag3Label: "Select Village",
  selectTag3Error: "Please select village!",
  cancelBtn: "Cancel",
  removeBtn: "Remove",
  saveBtn1: "Upload File",
  chipInputPH: "Type and press Enter",

  // Modal text
  modalHeader: "File Uploaded",
  modalContent: "Your file has been uploaded successfully",
  modalBtnText: "Okay, Go To Manage File",
  // Modal 2 Text
  deleteModalHeading: "Delete File",
  deleteConfirmText: "Are you sure to delete this file?",
  noText: "No",
  fileSizeText: "File Size",
  fileTypeErrorText: "Please check file type",

  // more
  viewTxt: "View",
  deleteTxt: "Delete",

  // no files
  noFilesText: "No files uploaded",
  // options
  options: [
    {
      name: "Images",
      id: 1,
      isSelected: false,
      key: "image",
    },
    {
      name: "Videos",
      id: 2,
      isSelected: false,
      key: "video",
    },
    {
      name: "Audio",
      id: 3,
      isSelected: false,
      key: "audio",
    },
    {
      name: "Excel Sheet",
      id: 4,
      isSelected: false,
      key: "excel",
    },
  ],
  // view file
  dateCreatedText: "Date Created",
  categoryText: "Category",
  selectCategoryText: "Select Category",
  selectVillageText: 'Select Village',
  keywordsText: "Keywords",
  geoTag: 'Geotag Info'
};
const bahasa = {
  heading: "Profile",
  subHeading: "User can view the profile and edit details",
  searchInputPlaceholder: "Cari file Anda di sini",
  uploadBtnText: "Unggah data",

  // upload file
  backbtnText: "Kembali",
  fileDesc: "Taruh file anda di sini, atau",
  browse: " Telusuri",
  fileTypes: "Jenis file yang didukung: PNG, JPG, WAV, MP3, MP4, XLS",
  uploadingText: "Mengunggah file ",
  cancelText: "Batal",
  uploaded: "Diunggah",
  selectTag1Label: "Pilih Kategori",
  selectTag1Error: "Silakan pilih kategori",
  selectTag2Label: "Tambahkan Kata Kunci",
  selectTag2Error: "Setidaknya diperlukan satu kata kunci",
  selectTag3Label: "Pilih Desa",
  selectTag3Error: "Silakan pilih desa",
  cancelBtn: "Membatalkan",
  removeBtn: "Menghapus",
  saveBtn1: "Unggah data",
  chipInputPH: "Ketik dan tekan Enter",

  // Modal text
  modalHeader: "Berkas Diunggah",
  modalContent: "File Anda telah berhasil diunggah",
  modalBtnText: "Oke, Buka Kelola File",
  // Modal 2 Text
  deleteModalHeading: "Menghapus berkas",
  deleteConfirmText: "Apakah Anda yakin akan menghapus file ini?",
  noText: "TIDAK",
  fileSizeText: "Ukuran file",
  fileTypeErrorText: "Silakan periksa jenis file",
  // more
  viewTxt: "Lihat",
  deleteTxt: "Hapus",

  // no files
  noFilesText: "Tidak ada file yang diunggah",
  // options
  options: [
    {
      name: "Gambar",
      id: 1,
      isSelected: false,
      key: "image",
    },
    {
      name: "Videos",
      id: 2,
      isSelected: false,
      key: "video",
    },
    {
      name: "Audio",
      id: 3,
      isSelected: false,
      key: "audio",
    },
    {
      name: "lembar Excel",
      id: 4,
      isSelected: false,
      key: "excel",
    },
  ],
  // view file
  dateCreatedText: "Tanggal dibuat",
  categoryText: "Kategori",
  selectCategoryText: "Pilih Kategori",
  selectVillageText: 'Pilih Desa',
  keywordsText: "Kata kunci",
  geoTag: 'Informasi Geotag'
};

export { english, bahasa };
