import React from "react";
import { Toast } from "primereact/toast";
import styled from "styled-components";

const ToastWrapper = styled.div`
  .toaster {
    font-size: 1.4rem;
  }
`;

interface ToasterProps {
  showToast: boolean;
  severity: string | any;
  summary: string;
  detail: string;
}

const Toaster: React.FC<ToasterProps> = ({
  showToast,
  severity,
  summary,
  detail,
}) => {
  const toastRef = React.useRef<Toast>(null);

  React.useEffect(() => {
    if (showToast && toastRef.current) {
      toastRef.current.show({
        severity,
        summary,
        detail,
        className: "toaster",
        life: 3000,
      });
    }
  }, [showToast, severity, summary, detail]);

  return (
    <ToastWrapper>
      <Toast ref={toastRef} />
    </ToastWrapper>
  );
};

export default Toaster;
