import FaqDetailsComponent from "./components/faq";
import { MANAGE_FILES_SUB_PATHS } from "../../all-routes/paths";
import styled from "styled-components";
import { Route, Routes } from "react-router-dom";
import React from "react";
import { LanguageContext } from "../../store/language-context";

const ManageFilesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

function FAQ() {
  const languageCtx = React.useContext(LanguageContext);
  return (
    <ManageFilesWrapper>
      <Routes>
        <Route
          path={MANAGE_FILES_SUB_PATHS.MANAGE_FILES_HOME}
          element={<FaqDetailsComponent isEnglish={languageCtx.isEnglish} />}
        />
      </Routes>
    </ManageFilesWrapper>
  );
}

export default FAQ;
