import React from "react";
import styled from "styled-components";
import { bahasa, english } from "../text";
import PieChart from "./sub-comp/pie-char";
import IconsBox from "../../../../components/ui-components/iconbox";
import {
  useKeyTrendsData,
  useKeyTrendsDataFiles,
} from "../../../../api/user-api/dashboard-api";
import Spinner from "../../../../components/ui-components/spinner";
import { Link } from "react-router-dom";
import { MENU_PATHS } from "../../../../all-routes/paths";

const Wrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  /* background-color: var(--white_900); */
`;

const Line = styled.div`
  width: 100%;
  border-top: 1px solid var(--gray_100);
`;
const CardWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  .child-card {
  }

  .key-trend {
    width: 100%;
  }
  .key-stats {
    width: 100%;
  }
`;
const FlexCol = styled.div`
  width: 48%;
  display: flex;
  // justify-content: space-between;
  flex-direction: column;
  gap: 0.7rem;
  .keyTrends-subheading {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: -0.02em;
    margin: 1rem 0;
  }
  .the-text-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.8rem;
    border-radius: 8px;
    padding: 1rem;
    background-color: var(--white_900);
    box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.11);
    .the-circle {
      min-width: 8px;
      min-height: 8px;
      border-radius: 5rem;
      background-color: var(--primary-500);
    }
    .orange {
      background-color: var(--orange-600);
    }
    .the-text {
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 15px;
      letter-spacing: -0.02em;
    }
  }
  .more-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .more-btn {
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 0.8rem;
    border: 1px solid var(--blue_800);
    background-color: var(--white_900);
    font-size: 1.4rem;
  }
`;
const Cards = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 1.2rem;
  box-shadow: 0px 4px 9px 0px rgba(221, 224, 227, 1);
  padding: 1.5rem;
  background-color: var(--white_900);
  .card-heading {
    color: var(--blue_800);
    font-size: 1.8rem;
    font-weight: 600;
    letter-spacing: -0.0175rem;
    margin: 0 0 1.5rem 0;
  }
  .refreshImg {
    width: 17px;
    display: inline-block;
    margin-left: 10px;
    vertical-align: top;
    margin-top: 1px;
    transform: rotate(0deg);
    cursor: pointer;
    transition-duration: 400ms;
    &:hover {
      transform: rotate(180deg);
    }
  }
  .sub-title {
    font-size: 1.2rem;
    line-height: 1.5rem;
    letter-spacing: 0em;
    color: var(--blue_800);
    margin: 0 0 0.8rem 0;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 698px) {
    flex-direction: column;
  }
  .card-subs {
    padding: 2rem 0 0 0;
  }
  .v-line {
    width: 1px;
    height: 100%;
    background-color: var(--gray_100);
  }
`;
const ResultWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 2rem;
  border-right: 1px solid var(--gray_100);
  padding: 2rem 0 0 0;
  @media screen and (max-width: 698px) {
    width: 100%;
  }
`;

const Box2 = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  .data-count {
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0em;
    color: var(--blue_800);
  }
  .yes_no-text {
    font-size: 1rem;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    margin: -10px 0 0 0;
    color: var(--bluish_gray);
  }
  .sub-text {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    color: var(--bluish_gray);
  }
`;
const Box = styled.div`
  display: flex;
  gap: 2rem;
`;
const GenderBox = styled.div`
  display: flex;
  width: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  .full-colan {
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    color: var(--gray_800);
  }
  .green-box {
    background-color: var(--green_400);
  }
  .purple-box {
    background-color: var(--purple_500);
  }
  .green-box,
  .purple-box {
    width: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    border-radius: 1rem;
    gap: 0.5rem;
    .gender-count {
      font-size: 2rem;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      color: var(--white_900);
    }
  }
`;
const ChartWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 2rem 0 0 2rem;
  .sub-head {
    width: 100%;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    color: var(--bluish_gray);
  }
  .image_box {
    width: 100%;
    a {
      display: flex;
      margin: 0 0 0 0;
      gap: 1rem;
      justify-content: start;
      align-items: center;
    }
  }
`;

interface IProps {
  isEnglish: boolean;
  keyStatisticsData: any;
  formData: any;
  loaded?: (val: any) => void;
}
const VillageCard = ({
  isEnglish,
  keyStatisticsData,
  formData,
  loaded,
}: IProps) => {
  const {
    keyStatsText,
    keyTrendsText,
    keyTrendsImagesText,
    keyTrendsVideosText,
    keyTrendsAudiosText,
    ageGroupText,
    opportunityText,
    challengesText,
    totalResText,
    genderRatioText,
    moreText,
    disabilityText,
    indigenousText,
    yesText,
    noText,
    photoText,
    videoText,
    audioText,
  } = isEnglish ? english : bahasa;

  const [splitChallenges, setSplitChallenges] = React.useState(4);
  const [splitOpportunities, setSplitOpportunities] = React.useState(4);

  const [splitChallengesImage, setSplitChallengesImage] = React.useState(4);
  const [splitOpportunitiesImage, setSplitOpportunitiesImage] =
    React.useState(4);
  const [fetchImage, setFetchImage] = React.useState(false);

  const [splitChallengesVideo, setSplitChallengesVideo] = React.useState(4);
  const [splitOpportunitiesVideo, setSplitOpportunitiesVideo] =
    React.useState(4);
  const [fetchVideo, setFetchVideo] = React.useState(false);

  const [splitChallengesAudio, setSplitChallengesAudio] = React.useState(4);
  const [splitOpportunitiesAudio, setSplitOpportunitiesAudio] =
    React.useState(4);
  const [fetchAudio, setFetchAudio] = React.useState(false);

  const { data, isLoading }: { data: any; isLoading: boolean } =
    useKeyTrendsData(formData.s3, isEnglish);

  const {
    data: imageData,
    isLoading: isLoadingImage,
  }: { data: any; isLoading: boolean } = useKeyTrendsDataFiles(
    formData.s3,
    isEnglish,
    "image",
    fetchImage
  );
  const {
    data: videoData,
    isLoading: isLoadingVideo,
  }: { data: any; isLoading: boolean } = useKeyTrendsDataFiles(
    formData.s3,
    isEnglish,
    "video",
    fetchVideo
  );
  const {
    data: audioData,
    isLoading: isLoadingAudio,
  }: { data: any; isLoading: boolean } = useKeyTrendsDataFiles(
    formData.s3,
    isEnglish,
    "audio",
    fetchAudio
  );

  React.useEffect(() => {
    fetchImages();
    fetchVideos();
    fetchAudios();
  }, []);

  React.useEffect(() => {
    if (!isLoading && !isLoadingAudio && !isLoadingImage && !isLoadingVideo) {
      if (loaded) {
        loaded(true);
      }
    } else {
      if (loaded) {
        loaded(false);
      }
    }
  }, [isLoading, isLoadingAudio, isLoadingImage, isLoadingVideo]);

  const fetchImages = () => {
    setFetchImage(true);
    setTimeout(() => {
      setFetchImage(false);
    }, 100);
  };
  const fetchVideos = () => {
    setFetchVideo(true);
    setTimeout(() => {
      setFetchVideo(false);
    }, 100);
  };
  const fetchAudios = () => {
    setFetchAudio(true);
    setTimeout(() => {
      setFetchAudio(false);
    }, 100);
  };
  return (
    <Wrapper className="child-components">
      <CardWrapper>
        <Cards className="key-stats">
          <h1 className="card-heading">{keyStatsText}</h1>
          <Line />
          {keyStatisticsData && (
            <FlexWrapper>
              <ResultWrapper>
                <Box>
                  <IconsBox name="people" />
                  <Box2>
                    <h2 className="data-count">
                      {keyStatisticsData?.total_villagers || "-"}
                    </h2>
                    <h3 className="sub-text">{totalResText}</h3>
                  </Box2>
                </Box>
                <Box>
                  <IconsBox name="smiley" />
                  <Box2>
                    <GenderBox>
                      <div className="green-box">
                        <IconsBox name={"male"} />
                        <h3 className="gender-count">
                          {keyStatisticsData?.gender_counts["Male"] || "-"}
                        </h3>
                      </div>
                      <h5 className="full-colan">{":"}</h5>
                      <div className="purple-box">
                        <h3 className="gender-count">
                          {keyStatisticsData?.gender_counts["Female"] || "-"}
                        </h3>
                        <IconsBox name={"female"} />
                      </div>
                    </GenderBox>
                    <h3 className="sub-text">{genderRatioText}</h3>
                  </Box2>
                </Box>
                <Box>
                  <img src="/svg/disability.svg" alt="disability" />
                  {/* <IconsBox name="disability" /> */}
                  <Box2>
                    <h2 className="data-count">
                      {keyStatisticsData?.disability !==
                      "No disabled people in data"
                        ? keyStatisticsData?.disability
                        : "-"}
                    </h2>
                    {keyStatisticsData?.disability !==
                    "No disabled people in data" ? (
                      <p className="yes_no-text">{`${yesText} : ${noText}`}</p>
                    ) : null}
                    {keyStatisticsData?.disability !==
                    "No disabled people in data" ? (
                      <h3 className="sub-text">{disabilityText}</h3>
                    ) : null}
                  </Box2>
                </Box>
                <Box>
                  <img src="/svg/indegenous.svg" alt="indigenous" />
                  {/* <IconsBox name="people" /> */}
                  <Box2>
                    <h2 className="data-count">
                      {keyStatisticsData?.indigenous !==
                      "No disabled people in data"
                        ? keyStatisticsData?.indigenous
                        : "-"}
                    </h2>
                    {keyStatisticsData?.indigenous !==
                    "No disabled people in data" ? (
                      <p className="yes_no-text">{`${yesText} : ${noText}`}</p>
                    ) : null}
                    {keyStatisticsData?.indigenous !==
                    "No disabled people in data" ? (
                      <h3 className="sub-text">{indigenousText}</h3>
                    ) : null}
                  </Box2>
                </Box>
              </ResultWrapper>
              <ChartWrapper>
                <h1 className="sub-head">{ageGroupText}</h1>
                <PieChart keyStatsData={keyStatisticsData} />
                <Box className="image_box">
                  <Link
                    to={`${MENU_PATHS.MANAGE_FILES}?type=image&village=${formData.s3}`}
                  >
                    <IconsBox name="image" />
                    <Box2>
                      <h2 className="data-count">
                        {keyStatisticsData?.image_count || "0"}
                      </h2>
                      <h3 className="sub-text">{photoText}</h3>
                    </Box2>
                  </Link>
                  <Link
                    to={`${MENU_PATHS.MANAGE_FILES}?type=video&village=${formData.s3}`}
                  >
                    <IconsBox name="video" />
                    <Box2>
                      <h2 className="data-count">
                        {keyStatisticsData?.video_count || "0"}
                      </h2>
                      <h3 className="sub-text">{videoText}</h3>
                    </Box2>
                  </Link>
                  <Link
                    to={`${MENU_PATHS.MANAGE_FILES}?type=audio&village=${formData.s3}`}
                  >
                    <IconsBox name="audio" />
                    <Box2>
                      <h2 className="data-count">
                        {keyStatisticsData?.audio_count || "0"}
                      </h2>
                      <h3 className="sub-text">{audioText}</h3>
                    </Box2>
                  </Link>
                </Box>
              </ChartWrapper>
            </FlexWrapper>
          )}
        </Cards>

        <Cards className="key-trend">
          <h1 className="card-heading">{keyTrendsText}</h1>
          <Line />
          <FlexWrapper>
            <FlexCol>
              {data && (
                <h2 className="keyTrends-subheading">{challengesText}</h2>
              )}
              {!isLoading && data ? (
                data?.challenges?.length ? (
                  data?.challenges
                    ?.slice(0, splitChallenges)
                    .map((challenge: any) => (
                      <div className="the-text-box" key={challenge}>
                        <span className="the-circle"></span>
                        <p className="the-text">{challenge}</p>
                      </div>
                    ))
                ) : (
                  "No data to show"
                )
              ) : isLoading ? (
                <Spinner />
              ) : null}
              {data?.challenges?.length >= 5 && splitChallenges <= 5 ? (
                <div className="more-wrapper" key={"more-btn"}>
                  {" "}
                  <span
                    className="more-btn"
                    onClick={() => setSplitChallenges(8)}
                  >
                    {moreText}
                  </span>
                </div>
              ) : null}
            </FlexCol>
            <div className="v-line"></div>
            <FlexCol>
              {data && (
                <h2 className="keyTrends-subheading">{opportunityText}</h2>
              )}
              {!isLoading && data ? (
                data?.opportunities?.length ? (
                  data?.opportunities
                    ?.slice(0, splitOpportunities)
                    .map((opportunity: any) => (
                      <div className="the-text-box" key={opportunity}>
                        <span className="the-circle orange"></span>
                        <p className="the-text">{opportunity}</p>
                      </div>
                    ))
                ) : (
                  "No data to show"
                )
              ) : isLoading ? (
                <Spinner />
              ) : null}
              {data?.opportunities?.length >= 5 && splitOpportunities <= 5 ? (
                <div className="more-wrapper" key={"more-btn"}>
                  {" "}
                  <span
                    className="more-btn"
                    onClick={() => setSplitOpportunities(8)}
                  >
                    {moreText}
                  </span>
                </div>
              ) : null}
            </FlexCol>
          </FlexWrapper>
        </Cards>

        <Cards className="key-trend">
          <h1 className="card-heading">
            {keyTrendsImagesText}{" "}
            <img
              onClick={fetchImages}
              className="refreshImg"
              src="/images/refresh.png"
              alt="refresh"
            />
          </h1>
          <Line />
          <FlexWrapper>
            <FlexCol>
              {imageData && (
                <h2 className="keyTrends-subheading">{challengesText}</h2>
              )}
              {!isLoadingImage && imageData ? (
                imageData?.challenges?.length ? (
                  imageData?.challenges
                    ?.slice(0, splitChallengesImage)
                    .map((challenge: any) => (
                      <div className="the-text-box" key={challenge}>
                        <span className="the-circle"></span>
                        <p className="the-text">{challenge}</p>
                      </div>
                    ))
                ) : (
                  "No data to show"
                )
              ) : isLoadingImage ? (
                <Spinner />
              ) : null}
              {imageData?.challenges?.length >= 5 &&
              splitChallengesImage <= 5 ? (
                <div className="more-wrapper" key={"more-btn"}>
                  {" "}
                  <span
                    className="more-btn"
                    onClick={() => setSplitChallengesImage(8)}
                  >
                    {moreText}
                  </span>
                </div>
              ) : null}
            </FlexCol>
            <div className="v-line"></div>
            <FlexCol>
              {imageData && (
                <h2 className="keyTrends-subheading">{opportunityText}</h2>
              )}
              {!isLoadingImage && imageData ? (
                imageData?.opportunities?.length ? (
                  imageData?.opportunities
                    ?.slice(0, splitOpportunitiesImage)
                    .map((opportunity: any) => (
                      <div className="the-text-box" key={opportunity}>
                        <span className="the-circle orange"></span>
                        <p className="the-text">{opportunity}</p>
                      </div>
                    ))
                ) : (
                  "No data to show"
                )
              ) : isLoadingVideo ? (
                <Spinner />
              ) : null}
              {imageData?.opportunities?.length >= 5 &&
              splitOpportunitiesImage <= 5 ? (
                <div className="more-wrapper" key={"more-btn"}>
                  {" "}
                  <span
                    className="more-btn"
                    onClick={() => setSplitOpportunitiesImage(8)}
                  >
                    {moreText}
                  </span>
                </div>
              ) : null}
            </FlexCol>
          </FlexWrapper>
        </Cards>

        <Cards className="key-trend">
          <h1 className="card-heading">
            {keyTrendsVideosText}{" "}
            <img
              onClick={fetchVideos}
              className="refreshImg"
              src="/images/refresh.png"
              alt="refresh"
            />
          </h1>
          <Line />
          <FlexWrapper>
            <FlexCol>
              {videoData && (
                <h2 className="keyTrends-subheading">{challengesText}</h2>
              )}
              {!isLoadingVideo && videoData ? (
                videoData?.challenges?.length ? (
                  videoData?.challenges
                    ?.slice(0, splitChallengesVideo)
                    .map((challenge: any) => (
                      <div className="the-text-box" key={challenge}>
                        <span className="the-circle"></span>
                        <p className="the-text">{challenge}</p>
                      </div>
                    ))
                ) : (
                  "No data to show"
                )
              ) : isLoadingVideo ? (
                <Spinner />
              ) : null}
              {videoData?.challenges?.length >= 5 &&
              splitChallengesVideo <= 5 ? (
                <div className="more-wrapper" key={"more-btn"}>
                  {" "}
                  <span
                    className="more-btn"
                    onClick={() => setSplitChallengesVideo(8)}
                  >
                    {moreText}
                  </span>
                </div>
              ) : null}
            </FlexCol>
            <div className="v-line"></div>
            <FlexCol>
              {videoData && (
                <h2 className="keyTrends-subheading">{opportunityText}</h2>
              )}
              {!isLoadingVideo && videoData ? (
                videoData?.opportunities?.length ? (
                  videoData?.opportunities
                    ?.slice(0, splitOpportunitiesVideo)
                    .map((opportunity: any) => (
                      <div className="the-text-box" key={opportunity}>
                        <span className="the-circle orange"></span>
                        <p className="the-text">{opportunity}</p>
                      </div>
                    ))
                ) : (
                  "No data to show"
                )
              ) : isLoadingVideo ? (
                <Spinner />
              ) : null}
              {videoData?.opportunities?.length >= 5 &&
              splitOpportunitiesVideo <= 5 ? (
                <div className="more-wrapper" key={"more-btn"}>
                  {" "}
                  <span
                    className="more-btn"
                    onClick={() => setSplitOpportunitiesVideo(8)}
                  >
                    {moreText}
                  </span>
                </div>
              ) : null}
            </FlexCol>
          </FlexWrapper>
        </Cards>
        <Cards className="key-trend">
          <h1 className="card-heading">
            {keyTrendsAudiosText}{" "}
            <img
              onClick={fetchAudios}
              className="refreshImg"
              src="/images/refresh.png"
              alt="refresh"
            />
          </h1>
          <Line />
          <FlexWrapper>
            <FlexCol>
              {audioData && (
                <h2 className="keyTrends-subheading">{challengesText}</h2>
              )}
              {!isLoadingAudio && audioData ? (
                audioData?.challenges?.length ? (
                  audioData?.challenges
                    ?.slice(0, splitChallengesAudio)
                    .map((challenge: any) => (
                      <div className="the-text-box" key={challenge}>
                        <span className="the-circle"></span>
                        <p className="the-text">{challenge}</p>
                      </div>
                    ))
                ) : (
                  "No data to show"
                )
              ) : isLoadingAudio ? (
                <Spinner />
              ) : null}
              {audioData?.challenges?.length >= 5 &&
              splitChallengesAudio <= 5 ? (
                <div className="more-wrapper" key={"more-btn"}>
                  {" "}
                  <span
                    className="more-btn"
                    onClick={() => setSplitChallengesAudio(8)}
                  >
                    {moreText}
                  </span>
                </div>
              ) : null}
            </FlexCol>
            <div className="v-line"></div>
            <FlexCol>
              {audioData && (
                <h2 className="keyTrends-subheading">{opportunityText}</h2>
              )}
              {!isLoadingAudio && audioData ? (
                audioData?.opportunities?.length ? (
                  audioData?.opportunities
                    ?.slice(0, splitOpportunitiesAudio)
                    .map((opportunity: any) => (
                      <div className="the-text-box" key={opportunity}>
                        <span className="the-circle orange"></span>
                        <p className="the-text">{opportunity}</p>
                      </div>
                    ))
                ) : (
                  "No data to show"
                )
              ) : isLoadingAudio ? (
                <Spinner />
              ) : null}
              {audioData?.opportunities?.length >= 5 &&
              splitOpportunitiesAudio <= 5 ? (
                <div className="more-wrapper" key={"more-btn"}>
                  {" "}
                  <span
                    className="more-btn"
                    onClick={() => setSplitOpportunitiesAudio(8)}
                  >
                    {moreText}
                  </span>
                </div>
              ) : null}
            </FlexCol>
          </FlexWrapper>
        </Cards>
      </CardWrapper>
    </Wrapper>
  );
};

export default VillageCard;
