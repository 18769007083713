import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MENU_PATHS } from "../../all-routes/paths";

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 85vh;
  .i-frame {
    position: absolute;
  }
`;

const SurveyManagement = () => {
  const navigate = useNavigate();
  const [delay, setDelay] = React.useState(1);
  const interval: any = React.useRef();

  React.useEffect(() => {
    interval.current = setInterval(() => {
      setDelay((prev) => prev - 1);
    }, 0);

    return () => clearInterval(interval.current);
  }, []);

  React.useEffect(() => {
    if (delay === 0) {
      clearInterval(interval.current);
      window.open(
        process.env.REACT_APP_KOBO_URL,
        "_blank",
        "noopener,noreferrer"
      );
      navigate(MENU_PATHS.DASHBOARD);
    }
  }, [delay]);


  return (
    <>
      {/* <h2>
        <a
          style={{ color: "var(--blue_500)" }}
          href="https://kf.deep-listening-kobotoolbox-dev.devtomaster.com/accounts/login"
          rel="noreferrer"
          target="_blank"
        >
          Vist epicollect
        </a> */}
      {/* </h2> */}
    </>
    // <Container>
    //   <iframe
    //     className="i-frame"
    //     src="https://kf.deep-listening-kobotoolbox-dev.devtomaster.com/accounts/login/"
    //     title="Embedded Website"
    //     width="100%"
    //     height="100%"
    //     frameBorder="0"
    //   />
    // </Container>
  );
};

export default SurveyManagement;
