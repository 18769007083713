import React from "react";
import styled from "styled-components";
import { bahasa, english } from "./text";
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "../../store/language-context";
import { MENU_PATHS } from "../../all-routes/paths";
import { ButtonOutlined } from "../../components/common-styles";

const ErrorWrapper = styled.div`
  width: 100%;
  background-color: var(--black_050);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const InlineBox = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--white_900);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.04);
  border-radius: 1.5rem;
  padding: 1.8rem 3.2rem;
  gap: 2rem;
  h1 {
    font-size: 3.2rem;
    font-weight: 600;
    letter-spacing: -0.06em;
  }
  p {
    font-size: 2.2rem;
    font-weight: 400;
    letter-spacing: -0.04em;
  }
  button {
    font-size: 1.6rem;
    font-weight: 500;
  }
`;

const ErrorPage: React.FC = () => {
  const languageCtx = React.useContext(LanguageContext);
  const navigate = useNavigate();

  const { heading, subHeading, btnText } = languageCtx.isEnglish
    ? english
    : bahasa;
  return (
    <ErrorWrapper>
      <InlineBox>
        <h1>{heading}</h1>
        <p>{subHeading}</p>
        <ButtonOutlined onClick={() => navigate(MENU_PATHS.DASHBOARD)}>
          {btnText}
        </ButtonOutlined>
      </InlineBox>
    </ErrorWrapper>
  );
};

export default ErrorPage;
