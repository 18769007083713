import React from "react";
import styled from "styled-components";
import IconsBox from "../../../../components/ui-components/iconbox";

const ExcelSheetWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: var(--gray_050);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.04);
  border-radius: 1.5rem;
  padding: 1.5rem;
  gap: 2.5rem;
  .excel-file-image {
    max-width: 10.1rem;
    max-height: 7.8rem;
    border-radius: 0.5rem;
  }
  .download-box {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    :hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
  .download-text {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: -0.02em;
    color: var(--blue_600);
  }
`;

const ExcelSheet = (src: any) => {
  return (
    <ExcelSheetWrapper>
      <img className="excel-file-image" src="/images/excel.png" alt="excel" />
      <div
        onClick={() => {
          window.location.href = src?.src;
        }}
        className="download-box"
      >
        <IconsBox name="download" />
        <p className="download-text">Download</p>
      </div>
    </ExcelSheetWrapper>
  );
};

export default ExcelSheet;
