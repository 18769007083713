const english = {
  printText: "Print Dashboard",
  //1st card
  villageText: "Village",
  provinceText: "Province",
  districtText: "District",
  keyTrendsText: "Key Trends",
  keyTrendsImagesText: "Key Trends (Image)",
  keyTrendsVideosText: "Key Trends (Video)",
  keyTrendsAudiosText: "Key Trends (Audio)",
  ageGroupText: "Age group distribution",
  keyStatsText: "Key Statistics",
  totalResText: "Total respondents",
  genderRatioText: "Gender ratio of all survey respondents",
  disabilityText: 'Disability',
  indigenousText: 'Indigenous',
  yesText: "Yes",
  noText: "No",
  photoText: "Images",
  videoText: 'Videos',
  audioText: 'Audios',

  listenToText: "Listen to what people say",
  ageText: "Age",
  genderText: "Gender",
  areaText: "Area",
  challengesText: "Challenges",
  opportunityText: "Opportunities",
  submitText: 'Submit',
  buttonText: "Result",
  defOptText: "Select",
  noDataText: "No Data!!",
  s1LabelText: "Age group",
  s2LabelText: "Gender",
  s3LabelText: "Annual Income",
  s4LabelText: "Disability",
  s5LabelText: "Indigenous",
  s6LabelText: "Employment Status",
  //   key statistics
  keyStatisticsText: "Key Statistics",
  keyStatisticsSubText: "The top three discuss topics within One week",

  //   social media feed
  socialFeedsText: "Social Media Feed",
  searchText: "Search Places",

  // chatBot
  chatBotHeading: "AI chatbot",
  inputPhText: "Enter message",
  startConvText: "Start a conversation",
  moreText: "More",
};
const bahasa = {
  printText: "Dasbor Cetak",
  //1st card
  villageText: "Desa",
  provinceText: "Provinsi",
  districtText: "Kecamatan / Distrik",
  keyTrendsText: "Tren Kunci",
  keyTrendsImagesText: "Tren Kunci (Gambar)",
  keyTrendsVideosText: "Tren Kunci (Video)",
  keyTrendsAudiosText: "Tren Kunci (Audio)",
  ageGroupText: "Distribusi kelompok usia",
  keyStatsText: "Statistik Kunci",
  totalResText: "Jumlah responden",
  genderRatioText: "Rasio jenis kelamin dari semua responden survei",
  disabilityText: 'Disabilitas',
  indigenousText: 'Adat/Suku',
  yesText: "Ya",
  noText: "TIDAK",
  photoText: "Gambar",
  videoText: 'Video',
  audioText: 'Audio',

  listenToText: "Dengarkan apa yang orang katakan",
  ageText: "Usia",
  genderText: "Jenis kelamin",
  areaText: "Daerah",
  challengesText: "Tantangan",
  opportunityText: "Peluang",
  submitText: 'kirimTeks',
  buttonText: "Hasil",
  defOptText: "Pilih",
  noDataText: "Tidak ada data!!",
  s1LabelText: "Kelompok usia",
  s2LabelText: "Jenis kelamin",
  s3LabelText: "Pendapatan tahunan",
  s4LabelText: "Disabilitas",
  s5LabelText: "Adat/Suku",
  s6LabelText: "Status Pekerjaan",

  //   key statistics
  keyStatisticsText: "Statistik Kunci",
  keyStatisticsSubText: "Top tiga membahas topik dalam Satu minggu",

  //   social media feed
  socialFeedsText: "Umpan Media Sosial",
  searchText: "Cari Tempat",

  // chatBot
  chatBotHeading: "Bot obrolan AI",
  inputPhText: "Masukkan pesan",
  startConvText: "Mulai percakapan",
  moreText: "Lagi",
};

export { english, bahasa };
