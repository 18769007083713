const english = {
  heading: "Reset Password",
  subHeading: "Please enter new password and then confirm password",
  input1Label: "New Password",
  input2Label: "Confirm Password",
  inputPlaceHolder: "Enter your password",
  buttonText: "Reset Password",
  errorText: "Entered passwords do not match, please re-enter.",
  errorPasswordText1: "Password is required",
  errorPasswordText2: "Password is required",
  errorPasswordText3: "Please check the password format!",
  errorPasswordText4: "Failed to add new password!",
  successPasswordText1: "New Password added",
  successPasswordSubText1: "Please login",
  passwordRules: "The entered password should have -",
  passwordHint1: "At least once uppercase",
  passwordHint2: "At least one Special character",
  passwordHint3: "At least one Number",
  passwordHint4: "Minimum 8 characters",
};
const bahasa = {
  heading: "Atur Ulang Kata Sandi",
  subHeading:
    "Silakan masukkan kata sandi baru dan kemudian konfirmasi kata sandi",
  input1Label: "kata sandi baru",
  input2Label: "konfirmasi sandi",
  inputPlaceHolder: "Masukkan kata sandi Anda",
  buttonText: "Atur Ulang Kata Sandi",
  errorText: "Kata sandi yang dimasukkan tidak cocok, harap masukkan kembali.",
  errorPasswordText1: "katakunci dibutuhkan",
  errorPasswordText2: "katakunci dibutuhkan",
  errorPasswordText3: "Silakan periksa format kata sandi!",
  errorPasswordText4: "Gagal menambahkan kata sandi baru!",
  successPasswordText1: "Kata Sandi Baru ditambahkan",
  successPasswordSubText1: "Silahkan masuk",
  passwordRules: "Kata sandi yang dimasukkan harus memiliki -",
  passwordHint1: "Setidaknya sekali huruf besar",
  passwordHint2: "Setidaknya satu karakter khusus",
  passwordHint3: "Setidaknya satu nomor",
  passwordHint4: "Minimal 8 karakter",
};

export { english, bahasa };
