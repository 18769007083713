import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  .dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #232121;
    margin: 0 4px;
    transition: all 05s;
  }
  .one {
    visibility: none;
    animation: loadingAnimation 1.5s infinite;
  }
  .two {
    visibility: none;
    animation: loadingAnimation 2s infinite;
  }
  .three {
    visibility: none;
    animation: loadingAnimation 1.5s infinite;
  }

  @keyframes loadingAnimation {
    0% {
      width: 10px;
      height: 10px;
      opacity: 0.2;
    }
    50% {
      width: 8px;
      height: 8px;
      opacity: 0.5;
    }
    100% {
      width: 10px;
      height: 10px;
      opacity: 1;
    }
  }
`;

const LoadingDots = () => {
  return (
    <Wrapper>
      <span className="dot one"></span>
      <span className="dot two"></span>
      <span className="dot three"></span>
    </Wrapper>
  );
};

export default LoadingDots;
