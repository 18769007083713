import React from "react";
import {
  Paginator,
  PaginatorCurrentPageReportOptions,
  PaginatorRowsPerPageDropdownOptions,
} from "primereact/paginator";
import { Dropdown } from "primereact/dropdown";
import { LanguageContext } from "../../../store/language-context";
import { bahasa, english } from "./text";

interface IProps {
  onPageChange: any;
  row: number;
  page: number;
  totalItems: number;
}

function PaginationManger({ onPageChange, row, page, totalItems }: IProps) {
  const languageCtx = React.useContext(LanguageContext);
  const { ofText, itemsPPText } = languageCtx.isEnglish ? english : bahasa;
  const template = {
    layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
    RowsPerPageDropdown: (options: PaginatorRowsPerPageDropdownOptions) => {
      const dropdownOptions = [
        { label: 5, value: 5 },
        { label: 10, value: 10 },
        { label: 15, value: 15 },
        { label: 20, value: 20 },
      ];

      return (
        <React.Fragment>
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            {itemsPPText}:{" "}
          </span>
          <Dropdown
            className="dropdown"
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
          />
        </React.Fragment>
      );
    },
    CurrentPageReport: (options: PaginatorCurrentPageReportOptions) => {
      return (
        <span
          style={{
            color: "var(--gray_600)",
            userSelect: "none",
            width: "12rem",
            textAlign: "center",
            display: "flex",
            margin: "0 0 0 2rem",
            gap: "0.2rem",
          }}
        >
          <p
            style={{
              color: "var(--black_900)",
            }}
          >
            {`${options.first}`}
          </p>
          {"-"} {options.last} {ofText} {options.totalRecords}
        </span>
      );
    },
  };

  return (
    <Paginator
      template={template}
      first={page}
      rows={row}
      totalRecords={totalItems}
      onPageChange={(e) => onPageChange(e, 1)}
      className="pagination"
    />
  );
}

export default PaginationManger;
