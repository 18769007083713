export const API_END_POINTS = {
  USER_LOGIN: "/users/login",
  USER_REGISTER: "/users/register_from_invite",
  USER_FORGOT_PASSWORD: "/users/forgot_password",
  USER_RESET_PASSWORD: "/users/reset_password",
  SURVEY_UPLOAD_DOC: "/survey/upload_document",
  USER_VIEW_PROFILE: "/users/view_profile",
  GET_USER_DETAILS: "/admin_panel/user_details/",
  GET_NOTIFICATIONS: "/",
  USER_LOGOUT: "/users/logout",
  USER_UPDATE_PROFILE: "/admin_panel/update_user_profile",
  USER_UPDATE_PASSWORD: "/users/profile_password_update",
  UPLOAD_FILE: "/survey/upload_file",
  FETCH_UPLOADED_FILES: "/survey/list/file",
  DELETE_FILE: "/survey/delete/file",
  // dashboard
  GET_DASHBOARD_DATA: "/showData",
  //social media
  GET_SOCIAL_MEDIA: '/survey/get_challenges_opportunities',
  GET_POSTS: '/survey/get_posts'
};
