import styled from "styled-components";
import styles from "./styles.module.css";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface IProps {
  isOn?: boolean;
  handleToggle?: any;
}
const Switch = ({ isOn, handleToggle }: IProps) => {

  return (
    <Wrapper>
      <input
        checked={isOn}
        onChange={handleToggle}
        className={styles["react-switch-checkbox"]}
        type="checkbox"
      />
      <label
        className={styles["react-switch-label"]}
        htmlFor={`react-switch-new`}
      >
        <span className={styles[`react-switch-button`]} />
      </label>
    </Wrapper>
  );
};

export default Switch;
