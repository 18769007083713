import React from "react";
import styled from "styled-components";
import { LanguageContext } from "../../../store/language-context";
import { bahasa, english } from "./text";
import { Heading } from "./settings-home";
import {
  AuthTextArea,
  PhoneInput,
  PhoneInputWrapper,
} from "../../profile-details/components/profile-details";
import CountrySelector from "../../../components/ui-components/select/country-code";
import { ButtonOutlined } from "../../../components/common-styles";
import { AuthButton, AuthInput } from "../../user-login/components/login";
import { useNavigate } from "react-router-dom";
import { SETTINGS_SUB_PATHS } from "../../../all-routes/paths";
import UploadProfile from "./upload-profile";
import SuccessModal from "../../../components/ui-components/success-modal";
import { UserContext } from "../../../store/user-context";
import { useCurrentUser, useUpdateProfile } from "../../../api/user-api";
import Spinner from "../../../components/ui-components/spinner";
import Toaster from "../../../components/ui-components/toaster";

interface IStyles {
  isError?: string | null;
}

const EditProfileWrapper = styled.form`
  width: min(67.8rem, 100%);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  .line {
    height: 1px;
    background-color: var(--gray_450);
    margin: 2.2rem 0 3rem 0;
  }
`;

export const UserHeading = styled(Heading)`
  padding: 2rem 2.4rem;
  background: var(--gray_450);
  border: 1px solid var(--gray_100);
  border-radius: 10px;
  h2 {
    color: var(--black_900);
    font-size: 1.8rem;
    font-weight: 500;
  }
  p {
    font-size: 1.4rem;
    font-weight: 400;
    margin: 0.8rem 0 0rem 0;
  }
`;

const UserBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin: 2rem 0;
  label {
    margin: 1rem 0 0 0 !important;
  }
  @media screen and (max-width: 998px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const UserDetailsBox = styled.div`
  width: max(42.9rem, 429px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  @media screen and (max-width: 998px) {
    align-items: center;
  }
`;

export const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.6rem;
  margin: 2rem 0;
`;

export const CancelButton = styled(ButtonOutlined)`
  width: max(9.9rem, 99px);
  height: max(4.7rem, 47px);
`;
export const SaveButton = styled(AuthButton)`
  width: max(14.8rem, 148px);
  height: max(4.7rem, 47px);
`;

// none style
interface FormData {
  name: string;
  phone: string;
  address: string;
  country_code: string;
}

interface FormErrors {
  name: string | null;
  phone: string | null;
  address: string | null;
  country_code: string | null;
  isError: boolean;
}

const initialFormErrors: FormErrors = {
  name: null,
  phone: null,
  address: null,
  country_code: null,
  isError: false,
};

function EditProfile() {
  const languageCtx = React.useContext(LanguageContext);
  const {
    upload,
    uploadTypes,
    input0Label,
    input1Label,
    input2Label,
    input0PlaceHolder,
    input1PlaceHolder,
    input2PlaceHolder,
    cancelBtn,
    saveBtn,
    saveBtn2,
    modalHeader,
    modalContent,
    modalBtnText,
  } = languageCtx.isEnglish ? english : bahasa;

  const { userData, setUserFn } = React.useContext(UserContext);
  const navigate = useNavigate();

  const onSuccess = async (res: any) => {
    await setUserFn(userData?.um_user_id);
    refetch();
    setShowSuccessModal(true);
    setFormErrors(initialFormErrors);
  };
  const onError = (res: any) => {
    setShowToast({
      status: true,
      type: "error",
      message: "Profile update Failed!",
      reason: res?.response?.data?.message,
    });
  };
  const { mutate: updateUser } = useUpdateProfile(onSuccess, onError);
  const {
    data: fetchedUserData,
    isLoading,
    isFetching,
    refetch,
  } = useCurrentUser();

  const initialFormData: FormData = {
    name: "",
    phone: "",
    address: "",
    country_code: "",
  };
  const [formData, setFormData] = React.useState<FormData>(initialFormData);
  const [formErrors, setFormErrors] =
    React.useState<FormErrors>(initialFormErrors);
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);
  const [updatedProfileImg, setUpdatedProfileImg] = React.useState("");
  const [currentProfileImg, setCurrentProfileImg] = React.useState(
    "/images/test-user.jpg"
  );
  const [showToast, setShowToast] = React.useState({
    status: false,
    type: "",
    message: "",
    reason: "",
  });

  React.useEffect(() => {
    if (!isLoading) {
      setFormData((prevState) => ({
        ...prevState,
        name: fetchedUserData?.name || "",
        phone: fetchedUserData?.phone || "",
        address: fetchedUserData?.address || "",
        country_code: fetchedUserData?.country_code || "",
      }));
      setCurrentProfileImg(fetchedUserData?.user_image);
    }
    return () => {};
  }, [isLoading, isFetching]);

  function handleInputChange(e: any) {
    const { name, value } = e.target;
    if (name === "name") {
      let isValue = value.slice(0, 30);
      setFormData((prevState) => ({ ...prevState, name: isValue }));
    }
    if (name === "address") {
      let isValue = value.slice(0, 150);
      setFormData((prevState) => ({ ...prevState, address: isValue }));
    }
    if (name === "phone") {
      let isValue = value.slice(0, 15);
      setFormData((prevState) => ({ ...prevState, phone: isValue }));
    }
    setFormErrors((prevState) => ({ ...prevState, [name]: null }));
  }

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const errors: FormErrors = {
      name: null,
      phone: null,
      address: null,
      country_code: null,
      isError: false,
    };
    let isValid = true;
    // Validate name
    if (!formData.name) {
      errors.name = "error";
      isValid = false;
    }
    if (!(/^[a-zA-ZÀ-ÖØ-öø-ÿ\s'-]+$/.test(formData?.name))) {
      errors.name = "error";
      isValid = false;
    }
    // Validate phone number
    if (!formData.phone || !formData.country_code) {
      errors.phone = "error";
      isValid = false;
    }
    // Validate address
    if (!formData.address.trim()) {
      errors.address = "error";
      isValid = false;
    }

    if (!isValid) {
      setFormErrors(errors);
    } else {
      // Submit the form data here
      await updateUser({
        email: userData.email,
        userId: userData.um_user_id,
        profile_pic: updatedProfileImg,
        ...formData,
      });
      setShowToast({
        status: false,
        type: "",
        message: "",
        reason: "",
      });
    }
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <Toaster
        showToast={showToast?.status}
        severity={showToast?.type}
        summary={showToast?.message}
        detail={showToast?.reason}
      />
      <EditProfileWrapper onSubmit={handleSubmit}>
        <UserHeading>
          <h2>{fetchedUserData?.name || userData?.name || ""}</h2>
          <p>{fetchedUserData?.email || userData?.email || ""}</p>
        </UserHeading>

        <UserBox>
          <UploadProfile
            title={upload}
            subTitle={uploadTypes}
            btnText={saveBtn2}
            currentImg={
              currentProfileImg ||
              userData?.user_image ||
              "/images/test-user.jpg"
            }
            onUpdateImage={(image: any) => setUpdatedProfileImg(image)}
            fileTypeText={uploadTypes}
          />
          <UserDetailsBox>
            <label htmlFor="name">{input0Label}*</label>
            <AuthInput
              type="text"
              name="name"
              value={formData?.name}
              onChange={handleInputChange}
              placeholder={input0PlaceHolder}
              isError={formErrors?.name}
            />
            <label htmlFor="phone">{input1Label}</label>
            <PhoneInputWrapper
              style={{
                margin: "0",
              }}
            >
              <CountrySelector
                onChange={(val: any) =>
                  setFormData({ ...formData, country_code: val?.value })
                }
                isCode={formData.country_code}
                isError={formErrors?.phone}
              />
              <PhoneInput
                name={"phone"}
                value={formData?.phone}
                onChange={handleInputChange}
                type="number"
                placeholder={input1PlaceHolder}
                isError={formErrors?.phone}
              />
            </PhoneInputWrapper>
            <label htmlFor="address">{input2Label}</label>
            <AuthTextArea
              name={"address"}
              value={formData?.address}
              onChange={handleInputChange}
              placeholder={input2PlaceHolder}
              isError={formErrors?.address}
            ></AuthTextArea>
          </UserDetailsBox>
        </UserBox>

        <div className="line"></div>

        <ButtonBox>
          <CancelButton
            onClick={() => navigate(`${SETTINGS_SUB_PATHS.SETTINGS_BASE}`)}
          >
            {cancelBtn}
          </CancelButton>
          <SaveButton type="submit">{saveBtn}</SaveButton>
        </ButtonBox>
      </EditProfileWrapper>
      <SuccessModal
        showModal={showSuccessModal}
        onHideModal={() => setShowSuccessModal(false)}
        heading={modalHeader}
        content={modalContent}
        btnText={modalBtnText}
        path={SETTINGS_SUB_PATHS.SETTINGS_BASE}
      />
    </>
  );
}

export default EditProfile;
