import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { bahasa, english } from "./text";
import IconsBox from "../../../components/ui-components/iconbox";
import CountrySelector from "../../../components/ui-components/select/country-code";
import {
  AuthButton,
  AuthInput,
  CheckInputBox,
} from "../../user-login/components/login";
import {
  AuthFormWrapper,
  AuthWrapper,
  ErrorWrapper,
  TextArea,
} from "../../../components/common-styles";
import { useSetProfile } from "../../../api/user-api";
import { OTHER_PATHS } from "../../../all-routes/paths";

interface IStyles {
  isError?: string | null;
}

const UserBox = styled.div`
  width: 100%;
  display: flex;
  gap: 0.6rem;
  flex-direction: column;
  margin: 0.4rem 0 2rem 0;
  h2 {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: -0.02em;
  }
  p {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: -0.02em;
    color: var(--bluish_gray);
  }
  .line {
    width: 100%;
    height: 1px;
    background-color: var(--gray_100);
    margin: 0 0 1rem 0;
  }
`;

export const PhoneInputWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  margin: 0 0 2.4rem 0;
`;

export const PhoneInput = styled(AuthInput)`
  width: 262px;
`;

export const AuthTextArea = styled(TextArea)<IStyles>`
  box-shadow: ${(props) =>
    props.isError != null
      ? `0 0 0 1px var(--red_600)`
      : `0 0 0 0.8px var(--gray_500)`};
  width: 100%;
  height: 8.8rem;
  resize: vertical;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: -0.02em;
  margin: 0.7rem 0 0 0;
`;
const OtherControls = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1.2rem 0 2.4rem 0;
  gap: 1rem;
  span {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--black_900);
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    gap: 4px;
    p {
      color: var(--blue_600);
      font-weight: 400;
    }
  }
`;

// none style
interface FormData {
  phone: string;
  address: string;
}

interface FormErrors {
  phone: string | null;
  address: string | null;
  isError: boolean;
}

const initialFormData: FormData = {
  phone: "",
  address: "",
};

const initialFormErrors: FormErrors = {
  phone: null,
  address: null,
  isError: false,
};

function ProfileDetailsComponent() {
  const [isEnglishLanguage] = React.useState(true);
  const onSuccess = () => {};
  const onError = () => {};
  const { mutate: setProfile } = useSetProfile(onSuccess, onError);

  const [formData, setFormData] = React.useState<FormData>(initialFormData);
  const [formErrors, setFormErrors] =
    React.useState<FormErrors>(initialFormErrors);
  const [isTermsConditionsChecked, setIsTermsConditionsChecked] =
    React.useState(false);

  const {
    heading,
    subHeading,
    input1Label,
    input2Label,
    input1PlaceHolder,
    input2PlaceHolder,
    errorText,
    buttonText,
    accept,
    tc,
  } = isEnglishLanguage ? english : bahasa;

  function handleInputChange(e: any) {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    setFormErrors((prevState) => ({ ...prevState, [name]: null }));
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const errors: FormErrors = {
      phone: null,
      address: null,
      isError: false,
    };
    let isValid = true;

    // Validate phone number
    if (!formData.phone) {
      errors.phone = "error";
      isValid = false;
    }
    // Validate address
    if (!formData.address.trim()) {
      errors.address = "error";
      isValid = false;
    }

    if (!isValid) {
      setFormErrors(errors);
    } else {
      // Submit the form data here
      setProfile({
        name: "AnkTest",
        password: "Test@123",
        invitation_code: "000",
        email: "myaccount@mailinator.com",
        ...formData,
      });
      setFormData(initialFormData);
      setFormErrors(initialFormErrors);
    }
  }

  return (
    <AuthWrapper>
      <h1 className="heading">{heading}</h1>
      <p className="sub-heading">{subHeading}</p>

      <UserBox>
        <h2>{"test userName"}</h2>
        <p>{"test@email.com"}</p>
        <div className="line"></div>
      </UserBox>

      {formErrors.isError ? (
        <ErrorWrapper>
          <IconsBox name="doubt" />
          <p className="error-text error-text-css">{errorText}</p>
        </ErrorWrapper>
      ) : (
        <></>
      )}

      <AuthFormWrapper onSubmit={handleSubmit}>
        <label htmlFor="phone">{input1Label}</label>
        <PhoneInputWrapper>
          <CountrySelector
            onChange={(val: any) => console.log(val)}
            isCode={false}
          />
          <PhoneInput
            name={"phone"}
            value={formData.phone}
            onChange={handleInputChange}
            type="number"
            placeholder={input1PlaceHolder}
            isError={formErrors.phone}
          />
        </PhoneInputWrapper>
        <label htmlFor="address">{input2Label}</label>
        <AuthTextArea
          name={"address"}
          value={formData.address}
          onChange={handleInputChange}
          placeholder={input2PlaceHolder}
          isError={formErrors.address}
        ></AuthTextArea>
        <OtherControls>
          <CheckInputBox
            onChange={(e:any) => setIsTermsConditionsChecked(e.target.checked)}
            type="checkbox"
          />
          <span>
            {accept}
            <Link to={OTHER_PATHS.TERMS_CONDITIONS}>
              <p className="p-1">{tc}</p>
            </Link>
          </span>
        </OtherControls>
        <AuthButton type="submit">{buttonText}</AuthButton>
      </AuthFormWrapper>
    </AuthWrapper>
  );
}

export default ProfileDetailsComponent;
