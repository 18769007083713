const english = {
  heading: "Profile details",
  challengesText: "Challenges",
  moreText: "More",
  opportunityText: "Opportunities",
  fetchBtnText: "Fetch recenet posts",
  fetching: 'Fetching...'
};
const bahasa = {
  heading: "Reset Password",
  challengesText: "Tantangan",
  moreText: "Lagi",
  opportunityText: "Peluang",
  fetchBtnText: "Ambil postingan terbaru",
  fetching: 'Mengambil...'
};

export { english, bahasa };
