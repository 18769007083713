import React, { useState, useEffect } from "react";
import styled from "styled-components";
import IconsBox from "../../../components/ui-components/iconbox";
import { OverlayPanel } from "primereact/overlaypanel";
import { bahasa, english } from "./text";
import { AuthButton } from "../../user-login/components/login";
import { useNavigate, useLocation } from "react-router-dom";
import DataList from "./data-list";
import { useDebounce } from "primereact/hooks";
import { PaginatorPageChangeEvent } from "primereact/paginator";
import Spinner from "../../../components/ui-components/spinner";
import useWindowSize from "../../../components/hooks/useWindowSize";
import { ButtonOutlined, Input } from "../../../components/common-styles";
import { useFetchUploadedFiles } from "../../../api/user-api";
import { MANAGE_FILES_SUB_PATHS } from "../../../all-routes/paths";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const TopHeader = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap-reverse;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
`;

const CommonBox = styled.div`
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ViewSelectBox = styled.div`
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.06);
  border-radius: 0.7rem;
  .view-type-grid,
  .view-type-list,
  .active {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background: var(--gray_050);
    transition: all var(--normal-time);
    :hover {
      cursor: pointer;
    }
  }
  .active {
    background-color: var(--white_900);
    svg {
      path {
        stroke: var(--blue_600);
      }
    }
  }
  .view-type-grid {
    border-bottom-left-radius: 0.7rem;
    border-top-left-radius: 0.7rem;
  }
  .view-type-list {
    border-bottom-right-radius: 0.7rem;
    border-top-right-radius: 0.7rem;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const FilterBox = styled.span`
  width: fit-content;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.06);
  border-radius: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  margin: 0 0 0 1.3rem;
  background: var(--white_900);
  transition: all var(--normal-time);
  :hover {
    cursor: pointer;
    background-color: var(--gray_050);
    svg {
      path {
        stroke: var(--blue_600);
      }
    }
  }
`;
const SelectFilter = styled.div`
  position: relative;
  width: fit-content;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.8rem;
  border-radius: 0.5rem;

  ::before {
    content: "";
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    top: -1.8rem;
    left: -0.8rem;
    border-radius: 0.2rem;
    transform: rotate(-45deg);
    background-color: var(--white_900);
    box-shadow: 1px -2px 1px -1px rgba(0, 0, 0, 0.1);
    z-index: -1;
  }
  .option {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.6rem 1rem;
    gap: 1rem;
    cursor: pointer;
    :hover {
      background-color: var(--gray_100);
    }
    h3 {
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 15px;
      letter-spacing: -0.02em;
    }
  }
`;
const CheckInputBox = styled.input`
  width: 1.4rem;
  height: 1.4rem;
  cursor: pointer;
  border-radius: 3px;
`;

const SearchBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.4rem;
  @media screen and (max-width: 998px) {
    flex-wrap: wrap;
  }
`;

const InputWrapper = styled.div`
  position: relative;
  width: max(27.1rem, 271px);
  height: max(4.6rem, 46px);
  .search-input {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0 0 0 45px;
  }
  .search-icon {
    position: absolute;
    width: 15%;
    height: 100%;
    left: 0.2rem;
    top: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
`;

export const SearchInput = styled(Input)`
  box-shadow: 0 0 0 1px var(--gray_500);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  margin: 0;
`;

const UploadButton = styled(AuthButton)`
  width: max(12.7rem, 127px);
  height: max(4.7rem, 47px);
`;

const FilteredItems = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1.5rem;
  margin: 2rem 0;
`;
const FilteredItemsBtn = styled(ButtonOutlined)`
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.4rem;
  gap: 0.4rem;
  svg {
    width: 1.8rem;
  }
`;

interface Iprops {
  isEnglish: boolean;
}

function HomeViewFiles({ isEnglish }: Iprops) {
  const {
    searchInputPlaceholder,
    uploadBtnText,
    viewTxt,
    deleteTxt,
    noFilesText,
    options,
  } = isEnglish ? english : bahasa;

  const { width } = useWindowSize();
  const navigate = useNavigate();
  const filterRef = React.useRef<any>(null);
  const [optionsArray, setOptionsArray] = useState(
    JSON.parse(JSON.stringify(options))
  );
  const [viewType, setViewType] = React.useState("grid");
  const [searchValue, debouncedValue, setSearchValue] = useDebounce("", 800);
  const [rows, setRows] = React.useState(20);
  const [offSet, setOffSet] = React.useState<number>(0);
  const [filterBy, setFilterBy] = React.useState<any>("");
  const [firsttime, setFirstTime] = React.useState<any>(false);
  const [village, setVillage] = React.useState<any>("");

  const location = useLocation();

  // useEffect(() => {
  //   const searchParams = new URLSearchParams(location.search);
  //   const typeParam = searchParams.get("type");
  //   setType(typeParam);
  //   console.log(type);
  // }, [location.search]);
  const {
    data: filesData,
    isLoading,
    refetch,
  } = useFetchUploadedFiles(
    debouncedValue,
    rows,
    offSet,
    filterBy,
    village ? village : ""
  );

  React.useEffect(() => {
    if (width <= 768) setViewType("grid");
    return () => {};
  }, [width]);

  React.useEffect(() => {
    if (!isEnglish || isEnglish)
      setOptionsArray(JSON.parse(JSON.stringify(options)));
    return () => {};
  }, [isEnglish]);

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const typeParam = searchParams.get("type");
    const villageParam = searchParams.get("village");
    if (!villageParam) {
      try {
        const user = localStorage.getItem("userDetails");
        if (user !== null) {
          const userDetails = JSON.parse(user);
          if (userDetails.role_name == "user" && userDetails.village_details) {
            const village = userDetails.village_details.name;
            setVillage(village);
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
    if (typeParam && !firsttime) {
      setFirstTime(true);
      setOptionsArray((prevState: any) => {
        return prevState.map((option: any) => {
          return option.key === typeParam
            ? Object.assign({}, option, { isSelected: true })
            : option;
        });
      });
      setVillage(villageParam);
    }
    handleFilterValues();
    return () => {};
  }, [optionsArray]);

  const handleCheck = (value: any, id: any) => {
    setOptionsArray((prevState: any) => {
      return prevState.map((option: any) => {
        return option.id === id
          ? Object.assign({}, option, { isSelected: value })
          : option;
      });
    });
    filterRef.current.toggle(false);
  };

  const handleFilterValues = () => {
    let filterValues: any = optionsArray
      .filter((option: any) => option.isSelected)
      .map((option: any) => {
        return `${option.key}`;
      });
    filterValues = filterValues.join(",");
    setFilterBy(() => filterValues);
  };

  const handleRemoveCheck = (id: any) => {
    setOptionsArray((prevState: any) => {
      return prevState.map((option: any) => {
        return option.id === id
          ? Object.assign({}, option, { isSelected: false })
          : option;
      });
    });
  };

  // Pagination
  const handlePageChange = (e: PaginatorPageChangeEvent, index: number) => {
    setOffSet(() => e.first);
    setRows(() => e.rows);
  };

  return (
    <Container>
      <TopHeader>
        <CommonBox>
          <ViewSelectBox>
            <span
              onClick={() => setViewType("grid")}
              className={
                viewType == "grid" ? "view-type-grid active" : "view-type-grid"
              }
            >
              <IconsBox name="grid" />
            </span>
            <span
              onClick={() => setViewType("list")}
              className={
                viewType == "list" ? "view-type-list active" : "view-type-list"
              }
            >
              <IconsBox name="list" />
            </span>
          </ViewSelectBox>

          <FilterBox onClick={(e) => filterRef.current.toggle(e)}>
            <IconsBox name="filter" />
          </FilterBox>

          <OverlayPanel ref={filterRef}>
            <SelectFilter>
              {optionsArray.map((option: any) => (
                <div
                  onClick={() => handleCheck(!option.isSelected, option.id)}
                  className="option"
                  key={option.id}
                >
                  <CheckInputBox
                    type="checkbox"
                    defaultChecked={option.isSelected}
                  />
                  <h3>{option.name}</h3>
                </div>
              ))}
            </SelectFilter>
          </OverlayPanel>
        </CommonBox>
        <SearchBox>
          <InputWrapper>
            <SearchInput
              className="search-input"
              type="text"
              placeholder={searchInputPlaceholder}
              value={searchValue}
              onChange={(event: any) => setSearchValue(event.target.value)}
            />
            <span className="search-icon">
              <IconsBox name="search" />
            </span>
          </InputWrapper>
          <UploadButton
            onClick={() =>
              navigate(
                `${MANAGE_FILES_SUB_PATHS.MANAGE_FILES_BASE}${MANAGE_FILES_SUB_PATHS.UPLOAD_FILE}`
              )
            }
          >
            {uploadBtnText}
          </UploadButton>
        </SearchBox>
      </TopHeader>
      <FilteredItems>
        {optionsArray
          .filter((option: any) => option.isSelected)
          .map((option: any) => (
            <FilteredItemsBtn
              key={option.id}
              onClick={() => handleRemoveCheck(option.id)}
            >
              {option.name}
              <IconsBox name="close-icon" fill="var(--blue_600)" />
            </FilteredItemsBtn>
          ))}
      </FilteredItems>

      {isLoading ? (
        <Spinner />
      ) : (
        <DataList
          text1={viewTxt}
          text2={deleteTxt}
          text3={noFilesText}
          viewType={viewType}
          files={filesData?.fileList}
          onRefetch={refetch}
          onHandlePagination={handlePageChange}
          row={rows}
          first={offSet}
          totalItems={filesData?.total_count}
        />
      )}
    </Container>
  );
}

export default HomeViewFiles;
