import React from "react";
import ChangePasswordComponent from "./components/change-password";
import { bahasa, english } from "./components/text";
import { LanguageContext } from "../../store/language-context";

function ChangePassword() {
  const { isEnglish } = React.useContext(LanguageContext);
  const { heading } = isEnglish ? english : bahasa;

  return <ChangePasswordComponent heading={heading} isEnglish={isEnglish}/>;
}

export default ChangePassword;
