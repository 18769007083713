const english = {
  heading: "Welcome",
  subHeading: "Please enter your login details below",
  input1Label: "Email Address",
  input1PlaceHolder: "Enter your email address",
  input2Label: "Password",
  input2PlaceHolder: "Enter your password",
  rememberMe: "Remember me",
  forgotPass: "Forgot password?",
  buttonText: "Login",
  errorText: "Entered Email ID or Password is incorrect",
  errorEmailText1: "Email is required",
  errorEmailText2: "Email is invalid",
  errorPasswordText1: "Password is required",
};
const bahasa = {
  heading: "Selamat datang",
  subHeading: "Silakan masukkan detail login Anda di bawah ini",
  input1Label: "Alamat email",
  input1PlaceHolder: "Masukkan alamat email Anda",
  input2Label: "Kata sandi",
  input2PlaceHolder: "Masukkan kata sandi Anda",
  rememberMe: "Ingat saya",
  forgotPass: "Tidak ingat kata sandi?",
  buttonText: "Masuk",
  errorText: "ID Email atau Kata Sandi yang dimasukkan salah",
  errorEmailText1: "Email diperlukan",
  errorEmailText2: "email tidak valid",
  errorPasswordText1: "katakunci dibutuhkan",
};

export { english, bahasa };
