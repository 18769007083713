import React from "react";
import myStorage from "./my-storage";

export const LanguageContext = React.createContext({
  isEnglish: false,
  trackChangesHandler: () => {},
});

function LanguageContextProvider(props: any) {
  const storage = myStorage();
  const checkLang = storage.get("bahasa");

  const [trackChanges, setTrackChanges] = React.useState(false);
  const [isEnglishLanguage, setIsEnglishLanguage] = React.useState(
    checkLang == "true" ? false : true
  );

  React.useEffect(() => {
    setIsEnglishLanguage(checkLang == "true" ? false : true);
  }, [trackChanges]);

  const context = {
    isEnglish: isEnglishLanguage,
    trackChangesHandler: () => {
      setTrackChanges(!trackChanges);
      setTimeout(() => {
        window.location.reload();
      }, 200);
    },
  };
  return (
    <LanguageContext.Provider value={context}>
      {props.children}
    </LanguageContext.Provider>
  );
}

export default LanguageContextProvider;
