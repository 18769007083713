import React from "react";
import styled from "styled-components";
import { OverlayPanel } from "primereact/overlaypanel";
import IconsBox from "../../../../components/ui-components/iconbox";
import DeleteFile from "../sub-components/delete-file";
import { LanguageContext } from "../../../../store/language-context";

const GridContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20.8rem, 1fr));
  gap: 2.2rem;
`;
const CardWrapper = styled.div`
  width: max(20.8rem);
  height: max(20.6rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: all 0.4s;
  :hover {
    /* cursor: pointer; */
    .content {
      transition: all 0.4s;
      background-color: var(--blue_600);
      .box {
        .title {
          color: var(--white_900);
        }
      }
    }
    svg {
      path {
        fill: #ffffff !important;
      }
    }
    .more-icon {
      svg {
        path {
          stroke: var(--white_900) !important;
        }
      }
    }
  }
`;
const ContentBox = styled.div`
  position: relative;
  width: 100%;
  height: 75%;
  border-radius: 1rem 1rem 0 0;
  background: rgba(0, 0, 0, 0.75);
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.04));
  transition: all 0.4s;
  .image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1rem 1rem 0 0;
  }
`;
const ControlBox = styled.div`
  width: 100%;
  height: 25%;
  padding: 1.3rem;
  background-color: var(--white_900);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0px 0px 1rem 1rem;
  transition: all 0.4s;
  .box {
    display: flex;
    justify-content: center;
    align-items: center;
    .title {
      max-width: 12rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 1.1rem;
      font-weight: 500;
      line-height: 13px;
      letter-spacing: 0em;
      color: var(--gray_800);
      margin: 0 0 0 1rem;
    }
  }
  .more-icon {
    width: fit-content;
    :hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
`;
const MoreMenu = styled.div`
  position: relative;
  width: fit-content;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.8rem;
  border-radius: 0.5rem;
  ::before {
    content: "";
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    top: -1.7rem;
    left: -0.9rem;
    border-radius: 0.2rem;
    transform: rotate(-45deg);
    background-color: var(--white_900);
    box-shadow: 1px -2px 1px -1px rgba(0, 0, 0, 0.1);
  }
  .more-item {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.4rem;
    padding: 0.5rem;
    .view-text,
    .trash-text {
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 15px;
      letter-spacing: 0.5px;
      margin: 0 0 0 1rem;
    }
    .trash-text {
      color: var(--red_600);
    }
    :hover {
      cursor: pointer;
      background-color: var(--light_gray);
    }
  }
`;

interface IProps {
  data: any;
  text1?: string;
  text2?: string;
  onShowFile: any;
  onDeleteFile: any;
}
const GridView = ({ data, text1, text2, onShowFile, onDeleteFile }: IProps) => {
  const languageCtx = React.useContext(LanguageContext);
  return (
    <GridContainer>
      {data.map((item: any, index: number) => (
        <Card
          key={index}
          id={item.file_id}
          {...item}
          url={item.file_url}
          title={
            item.file_name_original ? item.file_name_original : item.file_name
          }
          description={item.description}
          text1={text1}
          text2={text2}
          file_format={item.file_format}
          onShowFile={onShowFile}
          isEnglish={languageCtx.isEnglish}
          onDeleteFile={onDeleteFile}
          fileSize={item.file_size}
        />
      ))}
    </GridContainer>
  );
};

interface IProps2 {
  id: string;
  title?: string;
  file_format?: string;
  description?: string;
  text1?: string;
  text2?: string;
  onShowFile: any;
  isEnglish: boolean;
  url?: string;
  onDeleteFile: (arg0: string) => void;
  fileSize?: string;
}
function Card({
  id,
  title,
  description,
  url,
  text1,
  text2,
  file_format,
  onShowFile,
  isEnglish,
  onDeleteFile,
  fileSize,
}: IProps2) {
  const moreRef = React.useRef<any>(null);
  const [userDeleteModal, setDeleteUserModal] = React.useState(false);

  return (
    <>
      <CardWrapper key={id}>
        <ContentBox>
          {file_format === "image" ? (
            <img className="image" src={url} alt="pic" />
          ) : file_format === "audio" ? (
            <img className="image" src="/images/audio-img.png" alt="audio" />
          ) : file_format === "video" ? (
            <img className="image" src="/images/video-1.png" alt="video" />
          ) : (
            <img className="image" src="/images/excel.png" alt="excel" />
          )}
        </ContentBox>
        <ControlBox className="content">
          <span className="box">
            <IconsBox name={file_format} />
            <h2 className="title">{title}</h2>
          </span>
          <span
            className="more-icon"
            onClick={(e) => moreRef.current.toggle(e)}
          >
            <IconsBox name="three-dots-v" />
          </span>
        </ControlBox>
        <OverlayPanel ref={moreRef}>
          <MoreMenu>
            <div
              className="more-item"
              onClick={() => {
                moreRef.current.toggle(false);
                onShowFile(id);
              }}
            >
              <IconsBox name="visibility" />
              <p className="view-text">{text1}</p>
            </div>
            <div className="more-item" onClick={() => setDeleteUserModal(true)}>
              <IconsBox name="trash" />
              <p className="trash-text">{text2}</p>
            </div>
          </MoreMenu>
        </OverlayPanel>
      </CardWrapper>
      <DeleteFile
        showModal={userDeleteModal}
        onHideModal={() => setDeleteUserModal(false)}
        isEnglish={isEnglish}
        onDeleteFile={async (id: any) => {
          await onDeleteFile(id);
          setDeleteUserModal(false);
        }}
        fileId={id}
        fileType={file_format}
        fileName={title}
        fileURL={url}
        fileSize={fileSize}
      />
    </>
  );
}

export default GridView;
