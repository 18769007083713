import React from "react";
import styled from "styled-components";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem;
`;

// none style
interface IProps {
  isEnglish?: boolean;
  coords: any;
  display_name?: any;
}
const Map = ({ isEnglish, coords, display_name }: IProps) => {
  const { latitude, longitude } = coords;

  const customIcon = new L.Icon({
    //creating a custom icon to use in Marker
    iconUrl: "/images/map-point.png",
    iconSize: [35, 35],
    iconAnchor: [5, 30],
  });

  function MapView() {
    let map = useMap();
    map.setView([latitude, longitude], map.getZoom());
    //Sets geographical center and zoom for the view of the map
    return null;
  }

  return (
    <Wrapper>
      <MapContainer
        style={{
          width: "100%",
          height: "100%",
          borderRadius: "1rem",
          zIndex: 2,
        }}
        center={[latitude, longitude]}
        zoom={5}
      >
        <TileLayer
          //   attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>
          // contributors'
          // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {coords ? (
          <Marker icon={customIcon} position={[latitude, longitude]}>
            <Popup>{display_name}</Popup>
          </Marker>
        ) : null}
        <MapView />
      </MapContainer>
    </Wrapper>
  );
};

export default Map;
