import { MENU_PATHS } from "../../../all-routes/paths";

const english = {
  menu: [
    { name: "Dashboard", iconName: "dashboard", path: MENU_PATHS.DASHBOARD },
    {
      name: "Manage Files",
      iconName: "manage-files",
      path: MENU_PATHS.MANAGE_FILES,
    },
    { name: "Survey Management", iconName: "survey", path: MENU_PATHS.SURVEY },
    { name: "Analytics", iconName: "analytics", path: MENU_PATHS.ANALYTICS },
    {
      name: "Social Media",
      iconName: "analytics",
      path: MENU_PATHS.SOCIAL_MEDIA,
    },
    {
      name: "FAQ",
      iconName: "analytics",
      path: MENU_PATHS.FAQ,
    },
    {
      name: "Demo",
      iconName: "analytics",
      path: MENU_PATHS.DEMO,
    },
    { name: "Settings", iconName: "settings", path: MENU_PATHS.SETTINGS },
  ],
  logout: "Logout",
  lang: "Indonesian",
  notification: 'Notifications',
  clearAll: 'Clear All',
  noNotificationsText: "No notifications!",
  noResults: "No results!",
  logoutText: "Are you sure want to logout?",
  logoutBtnConfirmText: "Confirm",
  logoutBtnCancelText: "Cancel",

};
const bahasa = {
  menu: [
    { name: "Dasbor", iconName: "dashboard", path: MENU_PATHS.DASHBOARD },
    {
      name: "Kelola File",
      iconName: "manage-files",
      path: MENU_PATHS.MANAGE_FILES,
    },
    { name: "Manajemen Survei", iconName: "survey", path: MENU_PATHS.SURVEY },
    { name: "Analitik", iconName: "analytics", path: MENU_PATHS.ANALYTICS },
    {
      name: "Media sosial",
      iconName: "analytics",
      path: MENU_PATHS.SOCIAL_MEDIA,
    },
    {
      name: "FAQ",
      iconName: "analytics",
      path: MENU_PATHS.FAQ,
    },
    {
      name: "Demo",
      iconName: "analytics",
      path: MENU_PATHS.DEMO,
    },
    { name: "Pengaturan", iconName: "settings", path: MENU_PATHS.SETTINGS },
  ],
  logout: "Keluar",
  lang: "Indonesian",
  notification: "Notifikasi",
  clearAll: "Bersihkan semua",
  noNotificationsText: "Tidak ada pemberitahuan!",
  noResults: "Tidak ada hasil!",
  logoutText: "Anda yakin ingin logout?",
  logoutBtnConfirmText: "Mengonfirmasi",
  logoutBtnCancelText: "Membatalkan",
};

export { english, bahasa };
